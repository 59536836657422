<template>
     <v-container
        class="elevation-4 rounded-lg px-8 mb-5"
        style="background-color: #fff"
     >
        <v-row justify="center">
            <v-col cols="12" sm="12">
                <v-btn
                    color="primary"
                    icon
                    size="20"
                    class="text-center"
                    @click="$router.go(-1)"
                >
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" sm="4" class="text-center">
                <v-avatar size="100">
                    <v-img :src="profileImage()"/>
                </v-avatar>
                <p class="secondary--text pt-2 mb-0">{{ requestExercise.user.firstName }} {{ requestExercise.user.lastName }}</p>
                <p class="secondary--text">{{ requestExercise.userEmail }}</p>
            </v-col>
            <v-col cols="12" sm="8">
                <v-row justify="center">
                    <v-col cols="12" sm="12">
                        <p class="primary--text font-weight-black mb-0 text-h6">{{ requestExercise.name }}</p>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <v-row>
                            <v-col cols="6" sm="6">
                                <div class="d-flex">
                                    <p class="secondary--text mb-0 mr-2">By Sets</p>
                                    <template>
                                        <v-icon v-if="requestExercise.bySets" color="primary">mdi-checkbox-marked-circle</v-icon>
                                        <v-icon v-else color="secondary">mdi-close-circle</v-icon>
                                    </template>
                                </div>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <div class="d-flex">
                                    <p class="secondary--text mb-0 mr-2">By Time</p>
                                    <template>
                                        <v-icon v-if="requestExercise.byTime" color="primary">mdi-checkbox-marked-circle</v-icon>
                                        <v-icon v-else color="secondary">mdi-close-circle</v-icon>
                                    </template>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <v-row>
                            <v-col cols="6" sm="6">
                                <div class="d-flex">
                                    <p class="secondary--text mb-0 mr-2">Circuit</p>
                                    <template>
                                        <v-icon v-if="requestExercise.circuit" color="primary">mdi-checkbox-marked-circle</v-icon>
                                        <v-icon v-else color="secondary">mdi-close-circle</v-icon>
                                    </template>
                                </div>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <div class="d-flex">
                                    <p class="secondary--text mb-0 mr-2">Suspension</p>
                                    <template>
                                        <v-icon v-if="requestExercise.suspension" color="primary">mdi-checkbox-marked-circle</v-icon>
                                        <v-icon v-else color="secondary">mdi-close-circle</v-icon>
                                    </template>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <div class="d-flex">
                            <p class="secondary--text mb-0 mr-2">Rest Time</p>
                            <p class="primary--text mb-0 font-weight-bold">{{ requestExercise.restTime }}s</p>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <div class="d-flex">
                            <p class="secondary--text mb-0 mr-2">Circuit Reps</p>
                            <p class="primary--text mb-0 font-weight-bold">{{ requestExercise.circuitReps }}</p>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <template v-if="!loadingStatus">
                            <template v-if="!editStatus">
                                <v-chip
                                v-if="requestExercise.request.status"
                                :color="getColorStatus(requestExercise.request.status)"
                                text-color="white"
                                >
                                {{ requestExercise.request.status }}
                                </v-chip>
                            <template v-else>
                                <p class="secondary--text mb-0">
                                    No setted status
                                </p>
                            </template>
                            <v-btn
                                v-if="!requestExercise.request.savedOnDB"
                                icon
                                color="secondary"
                                size="20"
                                small
                                @click="editStatus = true"
                            ><v-icon>mdi-pencil</v-icon></v-btn>
                            </template>
                            <template v-else>
                                <div class="d-flex" style="width:175px;">
                                    <v-select
                                        v-if="!requestExercise.request.savedOnDB"
                                        :items="statusList"
                                        :value="requestExercise.request.status"
                                        solo
                                        flat
                                        background-color="#efefef"
                                        width="50%"
                                        @change="changeStatus($event)"
                                    />
                                    <v-btn
                                        class="mt-2"
                                        icon
                                        color="secondary"
                                        size="20"
                                        small
                                        @click="editStatus = false"
                                    ><v-icon>mdi-close</v-icon></v-btn>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="d-flex justify-center align-center w-100">
                                <v-progress-circular
                                    indeterminate
                                    :size="30"
                                    :width="4"
                                    color="primary"
                                ></v-progress-circular>                   
                            </div>
                        </template>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <p class="secondary--text mb-0">Requested {{ parseToRelativeDate(requestExercise.request.createdAt) }}</p>
                    </v-col>
                    <v-col cols="12" sm="12" class="text-right">
                        <v-btn
                            small
                            color="primary"
                            :loading="loadingSaveExercise"
                            :disabled="requestExercise.request.savedOnDB"
                            @click="saveExercise()"
                            >
                            <v-icon left>
                                mdi-content-save
                            </v-icon>
                            Add Exercise to App
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" sm="12">
                <v-data-table
                    v-if="!loading"
                    :headers="headers"
                    :items="requestExercise.exercises"
                    item-key="id"
                    :items-per-page="-1"
                    fixed-header
                    class="elevation-4 mb-5"
                >
                    <template v-slot:[`item.imagePreview`]="{ item }">
                        <v-tooltip top color="secondary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar class="my-2">
                            <img
                                v-if="getPreviewImage(item.exercise)" 
                                v-bind="attrs" 
                                v-on="on" 
                                style="cursor:pointer;"
                                @click="openDialogPreviewImage(getPreviewImage(item.exercise))"
                                :src="getPreviewImage(item.exercise)" 
                                />
                            </v-avatar> 
                        </template>
                        <span>Show Image</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.videoPreview`]="{ item }">
                        <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            icon 
                            color="primary"
                            @click="openDialogPreviewVideo(item.exercise)"
                            >
                            <v-icon>mdi-message-video</v-icon>
                        </v-btn>
                        </template>
                        <span>Show Video</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                        <span v-if="item.exercise.name && item.exercise.name['ENG']">
                            {{ item.exercise.name["ENG"] ? item.exercise.name["ENG"] : ''  }}
                        </span>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <span
                            v-if="item.exercise.description && item.exercise.description['ENG']"
                            class="d-block  text-truncate mx-auto"
                            style="max-width: 300px !important"
                        >
                        {{ item.exercise.description["ENG"] ? item.exercise.description["ENG"] : '' }}
                        </span>
                    </template>
                    <template v-slot:[`item.time`]="{ item }">
                        {{ item.time.minutes }} Minutes {{ item.time.seconds }} seconds
                    </template>
                </v-data-table>

            </v-col>
        </v-row>

        <v-dialog
            v-model="dialogPreviewVideo.show"
            transition="dialog-bottom-transition"
            max-width="700"
        >
            <v-card class="text-center">
            <v-card-actions class="pa-0">
                <v-btn icon color="secondary" class="ml-3 mt-2" @click="closeDialogVideoPreview()">
                <v-icon size="20">mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
            <img src="@/assets/logo_x.png" style="vertical-align:middle;" class="mx-2 d-inline" width="20">
            <p class="font-weight-bold secondary--text d-inline">{{ dialogPreviewVideo.name }}</p>
            <v-card-text class="pb-8 mt-3">
                <video
                v-if="dialogPreviewVideo.video"
                ref="listProTrainVideoPreview"
                width="100%"
                height="350px"
                controls
                class="mx-auto"
                :src="dialogPreviewVideo.video ? dialogPreviewVideo.video : ''"
                ></video>
                <!-- <v-btn @click="closeDialogVideoPreview()">
                Close
                </v-btn> -->
            </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogPreviewImage.show" 
            max-width="800"
        >
            <v-card style="line-height: 0; !important;">
            <v-btn fab x-small color="red" style="position:absolute; top:5px; left: 5px;" @click="closeDialogImagePreview()">
                <v-icon size="15" color="white">mdi-close</v-icon>
            </v-btn>
                <img :src="dialogPreviewImage.img" style="width:100%; height:100%;">
            </v-card>
        </v-dialog>
            <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
                snackbar.text
            }}</v-snackbar>
     </v-container>
</template>
<script>
import moment from "moment";
export default {
    name:"RequestExerciseShow",
    data: () => ({
        requestExercise:{
            bySets: false,
            byTime: false,
            circuit: false,
            circuitReps: 0,
            name: "",
            restTime: 0,
            suspension: false,
            user: null,
            userEmail: "",
            id: "",
            exercises: [],
            request: {
                savedOnDB: false,
                status: "",
                createdAt: null,
                updatedAt: null,
                message: ""
            }
        },
        statusList:[
            "PENDING",
            "APPROVED",
            "REJECTED"
        ],
        headers: [
            { text: "", align: "center", value: "imagePreview", sortable: false },
            { text: "Preview", align: "center", value: "videoPreview", sortable: false },
            { text: 'Name', align:'center', value:'name', sortable:false },
            { text: 'Description', align:'center', value:'description', sortable:false },
            { text: 'Reps', align:'center', value:'reps', sortable:false },
            { text: 'Sets', align:'center', value:'sets', sortable:false },
            { text: 'Time', align:'center', value:'time', sortable:false },
        ],
        dialogPreviewVideo:{
            show:false,
            video:'',
            name:''
        },
        dialogPreviewImage:{
            show:false,
            video:'',
        },
        loading: true,
        editStatus: false,
        loadingStatus: false,
        loadingSaveExercise: false,
        snackbar: {
            snackbar: false,
            timeout: 2000,
            text: null,
            color: "",
        },
    }),
    created() {
        const vm = this
        vm.requestExercise = vm.$route.params
        vm.getUserAndExercises()
    },
    methods: {
        async getUserAndExercises(){
            const vm = this
            try {
                vm.loading = true
                vm.$emit("showOverlay", true);
                const user = await vm.requestExercise.user.get()
                vm.$set(vm.requestExercise, 'user', user.data())

                for (let i = 0; i < vm.requestExercise.exercises.length; i++) {
                    const exerciseToGet = vm.requestExercise.exercises[i];
                    const exercise = await vm.$store.dispatch('GetEspecificExercise', exerciseToGet.exercise)
                    vm.$set(exerciseToGet, 'exercise', exercise)   
                }
                vm.loading = false
                vm.$emit("showOverlay", false);
            } catch (error) {
                vm.loading = false
                vm.$emit("showOverlay", false);
                
            }

        },
        profileImage(){
            const vm = this
            return vm.requestExercise.user.photoURL ? vm.requestExercise.user.photoURL : vm.requestExercise.user.photo ? vm.requestExercise.user.photo : require('@/assets/default-avatar.png')
        },
        changeRouteUser(item){
            const vm = this
            vm.$router.push({ name: 'User Profile', params: vm.requestExercise.user })
            .catch((err) => {
                vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                vm.snackbar.color = "red darken-2";
                vm.snackbar.snackbar = true;
            })
        },
        parseToRelativeDate(date){
            return moment(date.toDate()).fromNow()
        },
        getColorStatus(status){
            if(status === 'PENDING'){
                return 'amber'
            }else if (status === 'APPROVED'){
                return 'success'
            }else if(status === 'REJECTED'){
                return 'danger'
            }
        },
        getPreviewImage(excercise){
            const images = [
                "imageShaunaLink",
                "imageAlexLink",
                "imageJustinLink",
                "imageVeonLink",
            ];
            let image = ''
            for (let i = 0; i < images.length; i++) {
                const trainer = images[i];
                if(excercise[trainer]){
                    image = excercise[trainer]
                    break
                }
            }
            if(image === '' && excercise.trainersList && excercise.trainersList.length > 0){
                for (let i = 0; i < excercise.trainersList.length; i++) {
                    const trainer = excercise.trainersList[i];
                    if(trainer.exImage && trainer.exImage.link){
                        image = trainer.exImage.link
                        break
                    }
                }
            }
            return image
        },
        getPreviewVideo(excercise){
            const videos = [
                "videoShaunaLink",
                "videoAlexLink",
                "videoJustinLink",
                "videoVeonLink",
            ];
            let video = ''
            for (let i = 0; i < videos.length; i++) {
            const trainer = videos[i];
                if(excercise[trainer]){
                    video = excercise[trainer]
                    break
                }
            }
            if(video === '' && excercise.trainersList && excercise.trainersList.length > 0){
                for (let i = 0; i < excercise.trainersList.length; i++) {
                    const trainer = excercise.trainersList[i];
                    if(trainer.exVideo && trainer.exVideo.link){
                        video = trainer.exVideo.link
                        break
                    }
                }
            }
            return video
        },
        openDialogPreviewVideo(excercise){
            const vm = this
            const video = vm.getPreviewVideo(excercise)
            vm.dialogPreviewVideo = {
                show: true,
                video: video ? video : '',
                name: excercise.name['ENG']
            }
        },
        closeDialogVideoPreview(){
            const vm = this
            vm.dialogPreviewVideo = {
                show: false,
                video: '',
                name: ''
            }
        },
        openDialogPreviewImage(img){
            const vm = this
            vm.dialogPreviewImage = {
                show: true,
                img: img ? img : ''
            }
        },
        closeDialogImagePreview(){
            const vm = this
            vm.dialogPreviewImage.show = false
        },
        async changeStatus(e){
            const vm = this
            try {
                vm.loadingStatus = true
                const payload = { 
                    idRequest: vm.requestExercise.idRequest,
                    'request.status': e
                }

                await vm.$store.dispatch("UpdateRequestExercise", payload)

                await vm.$store.dispatch("GenerateLog", {
                    id: vm.requestExercise.idRequest,
                    exerciseName: vm.requestExercise.name,
                    status: e,
                    userEmail: vm.requestExercise.userEmail,
                    name:e.name,
                    type: "statusRequestExerciseChanged",
                });

                vm.$set(vm.requestExercise.request, 'status', e)
                vm.loadingStatus = false
                vm.editStatus = false

                let emitData = {
                    snackbar: true,
                    btn: "",
                    y: "bottom",
                    x: null,
                    mode: "",
                    timeout: 2000,
                    text: "Request exercise status changed!",
                    color: "dark",
                };
                vm.$emit("showSnackBar", emitData);

            } catch (error) {
                console.log(error)
                vm.loadingStatus = false
                vm.editStatus = false
                let emitData = {
                    snackbar: true,
                    btn: "",
                    y: "bottom",
                    x: null,
                    mode: "",
                    timeout: 2000,
                    text: "An error occurred changing the status",
                    color: "danger",
                };
                vm.$emit("showSnackBar", emitData);
                    
            }
        },
        async saveExercise(){
            const vm = this
            try {
                 vm.loading = true
                 vm.$emit("showOverlay", true);
                //  const payload = { ...vm.requestExercise }
                 await vm.$store.dispatch("SaveInAppExerciseRequest", vm.requestExercise)
                 vm.getUserAndExercises()
                 let emitData = {
                    snackbar: true,
                    btn: "",
                    y: "bottom",
                    x: null,
                    mode: "",
                    timeout: 2000,
                    text: "Exercise added to App successfully",
                    color: "success",
                 };
                 vm.loading = false
                 vm.$emit("showSnackBar", emitData);
                 vm.$emit("showOverlay", false);
            } catch (error) {
                vm.loading = false
                vm.$emit("showOverlay", false);
                console.error(error)
                let emitData = {
                    snackbar: true,
                    btn: "",
                    y: "bottom",
                    x: null,
                    mode: "",
                    timeout: 2000,
                    text: "An error occurred adding exercise to App",
                    color: "danger",
                };
                vm.$emit("showSnackBar", emitData);
            }

        }
    },
}
</script>