<template>
  <v-container class="elevation-4 rounded-lg px-8 mb-5" style="background-color: #fff">
    <v-form ref="form" v-model="valid">
      <v-row justify="space-between">
        <v-col cols="12" sm="12" md="6" align-self="center">
          <v-btn icon color="primary" @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p class="text-h6 text--secondary font-weight-bold d-inline">Create Push Notification</p>
        </v-col>
        <v-col cols="12" sm="12" md="3" align-self="center">
          <p class="secondary--text mb-0">Notification with image?</p>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-switch color="primary" v-model="notification.withImage" :input-value="notification.withImage"
            flat></v-switch>
        </v-col>
      </v-row>
      <v-row justify="left">
        <v-col cols="12" sm="12" md="6">
          <v-text-field v-model="notification.title" :rules="rules.title" solo flat placeholder="Title"
            background-color="#efefef" counter></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field v-model="notification.link" :rules="rules.link" solo flat
            placeholder="Link (For Example: https://maxprofitness.com/)" background-color="#efefef"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-textarea v-model="notification.message" :rules="rules.message" counter="250" maxlength="250" solo flat
            no-resize height="250" placeholder="Message" background-color="#efefef">
          </v-textarea>
        </v-col>
        <v-col v-if="notification.withImage" cols="12" sm="12" md="6">
          <p class="secondary--text mb-0">Image (Must be a link with image extension at the end)</p>
          <v-text-field v-model="notification.image" :rules="rules.image" solo flat
            placeholder="Link (For Example: https://maxprofitness.com/image.jpg)"
            background-color="#efefef"></v-text-field>
          <!-- <v-card v-if="notification.withImage" color="#efefef"
            class="rounded-lg elevation-0 d-flex justify-center align-center" min-width="250" min-height="250"
            @click="$refs.notificationImage.$refs.input.click()">
            <v-img :src="notificationImageSelected.length > 0 ? notificationImageSelected[0].img : ''" cover width="250"
              class="rounded-lg" height="250">
            </v-img>

            <v-icon large color="primary"
              style="position: absolute; transform: translate(50%,-50%); top: 50%; right: 50%;"> mdi-upload </v-icon>

          </v-card> -->
        </v-col>
        <v-col cols="12" sm="12" md="12" class="text-center">
          <v-btn color="primary" :loading="loading"
            :disabled="!valid || loading || (notification.withImage && notification.image.length === 0)"
            @click="uploadImageNotification()">Create notification</v-btn>
        </v-col>
      </v-row>
      <!-- <v-file-input ref="notificationImage" class="d-none" color="white" accept="image/png, image/jpeg" dark outlined
        :show-size="1000" @change="getNotificationImage" /> -->
    </v-form>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
      snackbar.text
    }}</v-snackbar>
  </v-container>
</template>
<script>
export default {
  name: "PushNotificationsCreate",
  data: () => ({
    valid: false,
    loading: false,
    notification: {
      title: "",
      message: "",
      link: "",
      withImage: false,
      image: ""
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    rules: {
      title: [v => !!v || 'Title is required',  v => (v && v.length >= 4) || 'Title must be greater than 4'],
      message: [v => !!v || 'Message is required'],
      link: [v => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(^$)/.test(v) || 'Insert a valid url, like: https://maxprofitness.com/'],
      image: [v => !!v || 'Insert a valid url, like: https://maxprofitness.com/image.jpg']
    },
    notificationImageSelected: []
  }),
  methods: {
    getNotificationImage(file) {
      const vm = this;
      if (file) {
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          const notificationImg = vm.notificationImageSelected;
          const fr = new FileReader();
          fr.onload = (el) => {
            notificationImg.splice(0, 1, {
              img: el.target.result,
              filetype: file.type.split("/")[1],
            });
          };
          fr.readAsDataURL(file);
        } else {
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Only .png and .jpeg files are accepted",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        }
      }
    },
    uploadImageNotification() {
      let vm = this;
      vm.loading = true;
      // if (this.notification.withImage) {
      //   if (vm.notificationImageSelected.length > 0) {
      //     vm.$store.dispatch('UploadImage', vm.notificationImageSelected[0].img)
      //       .then((link) => {
      //         vm.notification.image = link
      //         vm.createNotification();
      //       }).catch(err => {
      //         vm.loading = false
      //         vm.messageSnack(`Image upload error`, 'error')
      //       })
      //   } else {
      //     vm.loading = false
      //     vm.messageSnack(`Please select an image or disable notifications with images`, 'error')
      //   }
      // } else {
      //   vm.notification.image = ''

      // }

      vm.createNotification();
    },
    createNotification() {
      let vm = this
      vm.$store.dispatch('CreateNotification', vm.notification)
        .then((id) => {
          vm.$store.dispatch("GenerateLog", { id: id, title: vm.notification.title, type: 'createdPushNotification' })
          vm.loading = false
          vm.notification = {
            title: "",
            message: "",
            link: "",
            withImage: false,
            image: ""
          }
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: 'Notification created succesfully!',
            color: "success"
          }
          vm.$emit('showSnackBar', emitData)
          vm.$router.push("/admin/push-notifications");
        })
        .catch(err => {
          vm.loading = false
          vm.messageSnack(`Error creating notification`, 'error')
        })
    },
    messageSnack(text, type) {
      let vm = this
      vm.snackbar.text = text;
      vm.snackbar.color = type === 'error' ? 'red darken-2' : 'success';
      vm.snackbar.snackbar = true;
    }
  }
}
</script>