<template>
    <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="12">
        <v-btn color="primary" icon size="20" @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <p class="text-h6 text--secondary d-inline font-weight-bold">Settings Feedbacks</p>
      </v-col>
      <v-col cols="6" class="d-inline">
        <div class="d-flex align-center" v-if="!emailFromDefaultFeedback.editMode">
        <p class="font-weight-light mb-0">Email default FROM address: {{ fromEmailData.email }}</p>
        
        <v-btn
          v-if="!emailFromDefaultFeedback.editMode"
          text
          icon
          color="primary"
          @click="emailFromDefaultFeedback.editMode = true"
        >
        <v-icon left dark color="primary"> mdi-pencil </v-icon>
        </v-btn>


        </div>
        <template v-else>
          <div class="d-flex">
          <v-text-field
              v-model="emailFromDefaultFeedback.email"
              :rules="emailFromDefaultFeedback.emailRules"
              :disabled="emailFromDefaultFeedback.loading"
              type="email"
              placeholder="Email"
              solo
              flat
              class="button-email-from-feedback mr-1"
              background-color="#fff"
            ></v-text-field>
          <v-text-field
              v-model="emailFromDefaultFeedback.password"
              type="password"
              placeholder="Password"
              :disabled="emailFromDefaultFeedback.loading"
              solo
              flat
              class="button-email-from-feedback"
              background-color="#fff"
            ></v-text-field>
            <v-btn
              text
              color="secondary"
              class="mr-1"
              :disabled="emailFromDefaultFeedback.loading"
              @click="emailFromDefaultFeedback.editMode = false"
            >
            <v-icon left dark color="primary"> mdi-close </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              :disabled="emailFromDefaultFeedback.loading"
              :loading="emailFromDefaultFeedback.loading"
              @click="goUpdateFromEmail()"
            >
            <v-icon left dark color="white"> mdi-check </v-icon>
            </v-btn>

          </div>
          </template>

      </v-col>
      <v-row justify="center">
      <v-col cols="6">
           <v-btn
            text
            color="secondary"
            class="mb-3"
            @click="openDialogCreate('categorie')"
            >
            <v-icon left dark color="primary"> mdi-plus </v-icon>
            Add new categorie
            </v-btn>
            <v-data-table
              :headers="headersCategories"
              :items-per-page="5"
              :items="getFeedbackCategoriesList"
              :loading="loadingCategories"
              item-key="id"
              fixed-header
              class="elevation-4"
            >
              <template v-slot:[`item.color`]="{ item }">
                <v-avatar
                  :color="item.color"
                  size="36"
                >
                </v-avatar>
              </template>
               <template v-slot:[`item.enabled`]="{ item }">
                <v-switch
                    color="primary"
                    class="center-switch"
                    :input-value="item.enabled"
                    readonly
                    flat
                  ></v-switch>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <!-- <v-btn
                  v-if="item"
                  text
                  icon
                  color="primary"
                  @click="openEditDialog('categorie', item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn> -->
                  <v-btn
                    v-if="item"
                    text
                    icon
                    color="secondary"
                    @click="deleteItem('categorie', item)"
                  >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
           </v-data-table>
      </v-col>
      <v-col cols="6">
          <v-btn
            text
            color="secondary"
            class="mb-3"
            @click="openDialogCreate('status')"
            >
            <v-icon left dark color="primary"> mdi-plus </v-icon>
            Add new status
            </v-btn>
           <v-data-table
            :headers="headersCategories"
            :items-per-page="5"
            :items="getFeedbackStatusList"
            item-key="id"
            fixed-header
            class="elevation-4"
          >
              <template v-slot:[`item.color`]="{ item }">
                <v-avatar
                  :color="item.color"
                  size="36"
                >
                </v-avatar>
              </template>
               <template v-slot:[`item.enabled`]="{ item }">
                <v-switch
                    color="primary"
                    class="center-switch"
                    :input-value="item.enabled"
                    readonly
                    flat
                  ></v-switch>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <!-- <v-btn
                  v-if="item"
                  text
                  icon
                  color="primary"
                  @click="openEditDialog('status', item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn> -->
                  <v-btn
                    v-if="item"
                    text
                    icon
                    color="secondary"
                    @click="deleteItem('status', item)"
                  >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
          </v-data-table>
      </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="6">
            <v-btn
              text
              color="secondary"
              class="mb-3"
              @click="dialogEmailCustomerService.show = true"
              >
              <v-icon left dark color="primary"> mdi-plus </v-icon>
               Add new email for Recieve Feedbacks
              </v-btn>
              <v-data-table
              :headers="headersEmail"
              :items-per-page="5"
              :items="getFeedbackEmailList"
              :loading="loadingEmail"
              item-key="id"
              fixed-header
              class="elevation-4"
            >
               <template v-slot:[`item.enabled`]="{ item }">
                <v-switch
                    color="primary"
                    class="center-switch"
                    :input-value="item.enabled"
                    readonly
                    flat
                  ></v-switch>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    v-if="item"
                    text
                    icon
                    color="secondary"
                    @click="deleteEmail(item)"
                  >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
           </v-data-table>
        </v-col>
      </v-row>
    </v-row>

    <v-dialog
      v-model="dialogCreate.show"
      persistent
      max-width="500"
    >
      <v-card v-if="dialogCreate.type === 'categorie'" class="text-center">
        <v-form ref="formCreateSettingsFeedback" v-model="validCreate">
          <v-card-title class="secondary--text">
            Create {{ dialogCreate.type }}
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="11">
                 <v-text-field
                    v-model="dialogCreate.data.name"
                    :rules="[v => !!v || 'Name is required', v => (v && v.length <= 20) || 'Max 20 characters']"
                    placeholder="Name"
                    solo
                    flat
                    background-color="#efefef"
                  ></v-text-field>
              </v-col>
              <v-col cols="11" class="text-center">
                <v-color-picker
                  v-model="dialogCreate.data.color"
                  class="mx-auto"
                  :mode="'hexa'"
                  flat
              ></v-color-picker>
              </v-col>
              <v-col cols="2" class="text-center">
                <v-switch
                  color="primary"
                  class="d-flex justify-center center-switch"
                  v-model="dialogCreate.data.enabled"
                  :input-value="dialogCreate.data.name"
                  flat
                  >
                  <template v-slot:label>
                      <p class="secondary--text d-inline mb-0">{{ dialogCreate.data.enabled ? 'Enabled' : 'Disabled' }}</p>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
            
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              small
              @click="closeDialogCreate()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              small
              :disabled="dialogCreate.loading || !validCreate"
              :loading="dialogCreate.loading"
              @click="saveNewCategorie()"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-card v-if="dialogCreate.type === 'status'" class="text-center">
        <v-form ref="formCreateSettingsFeedback" v-model="validCreate">
          <v-card-title class="secondary--text">
            Create {{ dialogCreate.type }}
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="11">
                 <v-text-field
                    v-model="dialogCreate.data.name"
                    :rules="[v => !!v || 'Name is required', v => (v && v.length <= 20) || 'Max 20 characters']"
                    placeholder="Name"
                    solo
                    flat
                    background-color="#efefef"
                  ></v-text-field>
              </v-col>
              <v-col cols="11" class="text-center">
                <v-color-picker
                  v-model="dialogCreate.data.color"
                  class="mx-auto"
                  :mode="'hexa'"
                  flat
              ></v-color-picker>
              </v-col>
              <v-col cols="2" class="text-center">
                <v-switch
                  color="primary"
                  class="d-flex justify-center center-switch"
                  v-model="dialogCreate.data.enabled"
                  :input-value="dialogCreate.data.name"
                  flat
                  >
                  <template v-slot:label>
                      <p class="secondary--text d-inline mb-0">{{ dialogCreate.data.enabled ? 'Enabled' : 'Disabled' }}</p>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
            
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              small
              @click="closeDialogCreate()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              small
              :disabled="dialogCreate.loading || !validCreate"
              :loading="dialogCreate.loading"
              @click="saveNewStatus()"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEmailCustomerService.show"
      persistent
      max-width="500"
    >
      <v-card class="text-center">
        <v-form ref="formEmailCustomerService" v-model="dialogEmailCustomerService.valid">
          <v-card-title class="secondary--text">
            Add Email to List
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="11">
                 <v-text-field
                    v-model="dialogEmailCustomerService.email"
                    :rules="dialogEmailCustomerService.emailRules"
                    placeholder="For example: admin@maxprofitness.com"
                    solo
                    flat
                    background-color="#efefef"
                  ></v-text-field>
              </v-col>
              <v-col cols="2" class="text-center">
                <v-switch
                  color="primary"
                  class="d-flex justify-center center-switch"
                  v-model="dialogEmailCustomerService.enabled"
                  flat
                  >
                  <template v-slot:label>
                      <p class="secondary--text d-inline mb-0">{{ dialogEmailCustomerService.enabled ? 'Enabled' : 'Disabled' }}</p>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              small
              @click="closeDialogAddEmail()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              small
              :disabled="dialogEmailCustomerService.loading || !dialogEmailCustomerService.valid"
              :loading="dialogEmailCustomerService.loading"
              @click="saveNewEmail()"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "FeedbackSettings",
  data: () => ({
      headersCategories: [
        { text: "Color", align: "center", value: "color", sortable: false },
        { text: "Name", align: "start", value: "name",sortable: true, },
        { text: "Enabled", align: "center", value: "enabled", sortable: false },
        { text: "Action", align: "center", value: "action", sortable: false },
     ],
      headersEmail: [
        { text: "Email", align: "center", value: "email",sortable: true, },
        { text: "Enabled", align: "center", value: "enabled", sortable: false },
        { text: "Action", align: "center", value: "action", sortable: false },
     ],
     categories:[],
     validCreate:false,
     validEdit:false,
     loadingCategories:false,
     loadingStatus:false,
     loadingEmail:false,
      dialogCreate:{
        show:false,
        loading:false,
        type:'',
        data:{
          name:'',
          color:'',
          enabled:true
        }
      },
      dialogEdit:{
        show:false,
        loading:false,
        type:'',
        data:{
          name:'',
          color:'',
          enabled:true
        }
      },
      dialogEmailCustomerService:{
        valid:false,
        show:false,
        loading:false,
        enabled:true,
        email:'',
        emailRules: [v => !!v || "E-mail is required", v => /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(v) || "Email invalid"]
      },
      emailFromDefaultFeedback:{
        email:'',
        password:'',
        emailRules: [v => !!v || "E-mail is required", v => /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(v) || "Email invalid"],
        loading:false,
        editMode:false
      },
      fromEmailData:{
        email:'',
        password:'',
      }
  }),
  created(){
    let vm = this
    vm.obtainCategoriesList()
    vm.obtainStatusList()
    vm.obtainEmailList()
    vm.obtainFromDefaultEmail()
  },
  methods:{
    obtainCategoriesList(){
      let vm = this
      vm.loadingCategories = true
      vm.$store.dispatch('GetFeedbackCategoriesList')
      .then((res)=>{
        vm.loadingCategories = false
      }).catch((err)=>{
        console.error(err)
        vm.loadingCategories = false
      })
    },
    obtainStatusList(){
      let vm = this
      vm.loadingStatus = true
      vm.$store.dispatch('GetFeedbackStatusList')
      .then((res)=>{
        vm.loadingStatus = false
      }).catch((err)=>{
        console.error(err)
        vm.loadingStatus = false
      })
    },
    obtainEmailList(){
      let vm = this
      vm.loadingEmail = true
      vm.$store.dispatch('GetFeedbackEmailList')
      .then((res)=>{
        vm.loadingEmail = false
      }).catch((err)=>{
        console.error(err)
        vm.loadingEmail = false
      })
    },
    obtainFromDefaultEmail(){
      let vm = this
      vm.loadingEmail = true
      vm.$store.dispatch('GetFromEmailDefaultFeedback')
      .then((dataFrom)=>{
        vm.fromEmailData = dataFrom
        vm.$set(vm.emailFromDefaultFeedback, 'email', dataFrom.email)
        vm.$set(vm.emailFromDefaultFeedback, 'password', dataFrom.password)
        vm.loadingEmail = false
      }).catch((err)=>{
        console.error(err)
        vm.loadingEmail = false
      })
    },
    openDialogCreate(type){
      let vm = this
      vm.dialogCreate.type = type
      vm.dialogCreate.show = true
    },
    goUpdateFromEmail(){
      let vm = this
      vm.emailFromDefaultFeedback.loading = true
      let payload = {
        email:vm.emailFromDefaultFeedback.email,
        password:vm.emailFromDefaultFeedback.password
      }
      vm.$store.dispatch('UpdateFromDefaultEmailFeedback', payload)
      .then((res)=>{
        vm.$set(vm.emailFromDefaultFeedback, 'email', res.email)
        vm.$set(vm.emailFromDefaultFeedback, 'password', res.password)
        vm.$set(vm.fromEmailData, 'email', res.email)
        vm.$set(vm.fromEmailData, 'password', res.password)
        vm.$store.dispatch("GenerateLog", {
          email:res.email,
          id:vm.fromEmailData.id,
          type: "updatedFromDefaultEmailFeedback",
        });
        let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 3000,
            text: "Feedback default email address FROM updated!",
            color: "success",
        };
        vm.$emit("showSnackBar", emitData);
        vm.emailFromDefaultFeedback = false
      }).catch((err)=>{
        console.log(err)
        let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "The email and / or password are wrong, check your GSuite credentials",
            color: "red",
        };
        vm.$emit("showSnackBar", emitData);
        vm.emailFromDefaultFeedback.loading = false
      })
    },
    saveNewCategorie(){
      let vm = this
      vm.dialogCreate.loading = true
      vm.$store.dispatch('CreateFeedbackCategorie', vm.dialogCreate.data)
      .then((id)=>{
        vm.$store.dispatch("GenerateLog", {
          id: id,
          name:vm.dialogCreate.data.name,
          type: "createdFeedbackCategorie",
        });
        let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Feedback categorie created!",
            color: "success",
        };
        vm.$emit("showSnackBar", emitData);
        vm.closeDialogCreate()
      }).catch((err)=>{
        vm.dialogCreate.loading = false
        console.error(err)
      })
    },
    saveNewStatus(){
      let vm = this
      vm.dialogCreate.loading = true
      vm.$store.dispatch('CreateFeedbackStatus', vm.dialogCreate.data)
      .then((id)=>{
        vm.$store.dispatch("GenerateLog", {
          id: id,
          name:vm.dialogCreate.data.name,
          type: "createdFeedbackStatus",
        });
        let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Feedback status created!",
            color: "success",
        };
        vm.$emit("showSnackBar", emitData);
        vm.closeDialogCreate()
      }).catch((err)=>{
        vm.dialogCreate.loading = false
        console.error(err)
      })
    },
    closeDialogCreate(){
      let vm = this
      vm.dialogCreate = {
        show:false,
        loading:false,
        type:'',
        data:{
          name:'',
          color:'',
          enabled:true
        }
      }
    },
    saveNewEmail(){
      let vm = this
      vm.dialogEmailCustomerService.loading = true
      let data = {
        email: vm.dialogEmailCustomerService.email,
        enabled: vm.dialogEmailCustomerService.enabled
      }
      vm.$store.dispatch('AddFeedbackEmail', data)
      .then((id)=>{
        vm.$store.dispatch("GenerateLog", {
          id: id,
          email:vm.dialogEmailCustomerService.email,
          type: "addEmailToListFeedback",
        });
        let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Email added to list successfully!",
            color: "success",
        };
        vm.$emit("showSnackBar", emitData);
        vm.closeDialogAddEmail()
      }).catch((err)=>{
        vm.dialogEmailCustomerService.loading = false
        console.error(err)
      })
    },
    closeDialogAddEmail(){
      let vm = this
      vm.dialogEmailCustomerService = {
        valid:false,
        show:false,
        loading:false,
        email:'',
        enabled:true,
        emailRules: [v => !!v || "E-mail is required", v => /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(v) || "Email invalid"]
      }
      vm.$refs.formEmailCustomerService.resetValidation()
    },
    deleteEmail(data){
      let vm = this
      vm.$store.dispatch('DeleteEmailFromListFeedbacks', data)
        .then((id)=>{
          vm.$store.dispatch("GenerateLog", {
            id: id,
            email:data.email,
            type: 'deletedEmailToListFeedback',
          });
        })
        .catch((err)=>{
          console.error(err)
        })
    },
    openEditDialog(type, item){

    },
    closeDialogEdit(){
      let vm = this
      vm.dialogCreate = {
        show:false,
        type:'',
        data:{
          name:'',
          color:'',
          enabled:true
        }
      }
    },
    deleteItem(type, item){
      let vm = this
      let data
      if(type === 'categorie'){
        data = {
          id:item.id,
          name:item.name,
          collection:'feedbacksCategories'
        }
      }else if(type === 'status'){
        data = {
          id:item.id,
          name:item.name,
          collection:'feedbacksStatus'
        }
      }
      vm.$store.dispatch('DeleteCategorieOrStatus', data)
        .then((id)=>{
          vm.$store.dispatch("GenerateLog", {
            id: id,
            name:data.name,
            type: data.collection === 'feedbacksCategories' ? 'deletedFeedbackCategorie' : "deletedFeedbackStatus",
          });
        })
        .catch((err)=>{
          vm.dialogCreate.loading = false
          console.error(err)
        })
    }
  },
  computed:{
     ...mapGetters(["getFeedbackCategoriesList", "isSubscribedFeedbackCategoriesList", "getFeedbackStatusList", "isSubscribedFeedbackStatusList", "getFeedbackEmailList", "isSubscribedFeedbackEmailList"]),
  },
   destroyed(){
    let vm = this
    if(vm.isSubscribedFeedbackCategoriesList){
      vm.$store.dispatch('GoUnsuscribeGetFeedbackCategoriesList')
    }
    if(vm.isSubscribedFeedbackStatusList){
      vm.$store.dispatch('GoUnsuscribeGetFeedbackStatusList')
    }
  }
}
</script>
<style scoped>
  .center-switch > .v-input__control > .v-input__slot {
    justify-content: center !important;
  }
  .v-data-table /deep/ .sticky-header {
    position: sticky;
    top: var(--toolbarHeight);
  }

  .v-data-table /deep/ .v-data-table__wrapper {
    overflow: unset;
  }
  .button-email-from-feedback /deep/ .v-input__slot, .button-email-from-feedback /deep/ .v-input__control{
    width: 500px !important;
  }

</style>