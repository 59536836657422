import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { v4 as uuidv4 } from "uuid";
import { user } from "firebase-functions/lib/providers/auth";

Vue.use(VueAxios, Axios);

const db = firebase.firestore();


Vue.use(VueAxios, Axios);

const storageImages = firebase.storage().ref("subscriptionsImages/");

export default {
    state:{
        subscriptionsList: [],
        subscriptionsGettedByUsers:[],
        analyticsSubscription: [],
        loadingStatusSubscriptionImage:0,
        actualPageSubscriptionsGetted:1
    },
    mutations:{
        SET_SUBSCRIPTIONS_LIST(state, subscriptionsList){
            state.subscriptionsList = subscriptionsList
        },
        SET_VALUE_LOADING_STATUS_SUBSCRIPTION_IMAGE(state, progress) {
            state.loadingStatusSubscriptionImage = progress
        },
        SET_SUBSCRIPTIONS_GETTED_BY_USERS_LIST(state, subscriptionsGettedByUsers){
            state.subscriptionsGettedByUsers = subscriptionsGettedByUsers
        },
        SET_COUNTER_SUBSCRIPTIONS_GETTED_BY_USERS_LIST(state, analyticsSubscription){
            state.analyticsSubscription = analyticsSubscription
        },
        SET_PAGE_SUBSCRIPTIONS_GETTED(state, page){
            state.actualPageSubscriptionsGetted = page
        }
        
    },
    actions:{
        UploadImageSubscription({ commit }, subscription) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(subscription.image.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_SUBSCRIPTION_IMAGE', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        GetSubscriptionsList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("subscriptionsList").where("id", "!=", "Yn8R3ywqutpbFmyPoAbt").get() //where added for not get "free" subscription
                    const subscriptionsList = []
                    querySnapshot.forEach((doc) =>  {
                        subscriptionsList.push(doc.data())
                    })
                    commit('SET_SUBSCRIPTIONS_LIST', subscriptionsList)
                    resolve(subscriptionsList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateSubscription({}, subscription) {
            return new Promise(async(resolve, reject) => {
                try {
                    delete subscription.image.img
                    const ref = db.collection("subscriptionsList").doc();
                    const newSubscription = await ref.get();
                    subscription.id = ref.id;
                    subscription.createdAt = new Date()
                    subscription.updatedAt = new Date()
                    await db.collection("subscriptionsList").doc(newSubscription.id).set(subscription)
                    resolve(newSubscription.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        UpdateSubscription({}, subscription) {
            return new Promise(async(resolve, reject) => {
                try {
                    if (subscription.image.img) {
                        delete subscription.image.img
                    }
                    if(subscription.createdAt) {
                        delete subscription.createdAt
                    }
                    subscription.updatedAt = new Date()
                    await db.collection("subscriptionsList").doc(subscription.id).update(subscription)
                    resolve(subscription.id);
                } catch (error) {
                    reject(err);
                }
            });
        },
        DeleteSubscription({}, subscription) {
            return new Promise(async(resolve, reject) => {
                try {
                    let storageRefImage = storageImages.child(subscription.image.ref);
                    await storageRefImage.delete()
                    await db.collection("subscriptionsList").doc(subscription.id).delete()
                    resolve(subscription.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
        GetAllSubscriptionsUser({}, uid){
            return new Promise(async (resolve, reject) => {
                try {
                    const userReference =  db.collection("users").doc(uid);
                    const querySnapshot = await db.collection("subscriptionsGettedByUsers").where('user', "==", userReference).orderBy('duration.startDate', 'desc').get()
                    const userSubscriptionsList = []
                    querySnapshot.forEach(async (doc) =>  {
                        let userSubscription = doc.data()
                        console.log(userSubscription);
                        let typeSubscription = await userSubscription.typeSubscription.get()
                        userSubscription.typeSubscription = typeSubscription.data()
                        userSubscriptionsList.push(userSubscription)
                    })
                    resolve(userSubscriptionsList)
                } catch(error) {
                    reject(error)
                }
            })

        },
        AddSubscriptionToUser({}, subscriptionPayload){
            return new Promise(async (resolve, reject) => {
                try {
                    const ref = db.collection("subscriptionsGettedByUsers").doc();
                    subscriptionPayload.typeSubscription = db.collection("subscriptionsList").doc(subscriptionPayload.typeSubscription);
                    subscriptionPayload.user = db.collection("users").doc(subscriptionPayload.user);
                    const newSubscription = await ref.get();
                    subscriptionPayload.id = ref.id;
                    await db.collection("subscriptionsGettedByUsers").doc(newSubscription.id).set(subscriptionPayload)
                    await subscriptionPayload.user.update({ activeSubscription: {ref: ref, isPremium: subscriptionPayload.typeSubscription.id !== 'Yn8R3ywqutpbFmyPoAbt' } })
                    resolve(newSubscription.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        async GetSubscriptionsGettedByUsersList({ commit, getters }, options){
            return new Promise(async (resolve, reject) => {
                try {
                    let ref = db.collection("subscriptionsGettedByUsers")
                    // const referenceFreeSubscription = db.collection("subscriptionsList").doc('Yn8R3ywqutpbFmyPoAbt')

                    let querySnapshot = null
                    if(options.page === 1){
                        // querySnapshot = options.isFree ? ref.orderBy('duration.startDate', 'desc').limit(options.itemsPerPage) : ref.orderBy('typeSubscription').orderBy('duration.startDate', 'desc').limit(options.itemsPerPage).where('typeSubscription', '!=', referenceFreeSubscription)
                        querySnapshot = ref.orderBy('duration.startDate', 'desc').limit(options.itemsPerPage)
                    }else if(options.page > getters.getActualPageSubscriptionsGetted){
                        let lastValue = getters.getSubscriptionsGettedByUsers[getters.getSubscriptionsGettedByUsers.length - 1].duration.startDate
                        console.log('lastValue', lastValue);
                        // querySnapshot = options.isFree ? ref.orderBy('duration.startDate', 'desc').startAfter(lastValue).limit(options.itemsPerPage) : ref.orderBy('typeSubscription').orderBy('duration.startDate', 'desc').startAfter(lastValue).limit(options.itemsPerPage).where('typeSubscription', '!=', referenceFreeSubscription)
                        querySnapshot = ref.orderBy('duration.startDate', 'desc').startAfter(lastValue).limit(options.itemsPerPage)
                    }else if(options.page < getters.getActualPageSubscriptionsGetted){
                        let firstValue = getters.getSubscriptionsGettedByUsers[0].duration.startDate
                        console.log('firstValue', firstValue);
                        // querySnapshot = options.isFree ? ref.orderBy('duration.startDate', 'desc').endBefore(firstValue).limitToLast(options.itemsPerPage) : ref.orderBy('typeSubscription').orderBy('duration.startDate', 'desc').endBefore(firstValue).limitToLast(options.itemsPerPage).where('typeSubscription', '!=', referenceFreeSubscription)
                        querySnapshot = ref.orderBy('duration.startDate', 'desc').endBefore(firstValue).limitToLast(options.itemsPerPage)
                    }
                    querySnapshot = await querySnapshot.get()
                    let subscriptionsUsersList = []

                    for (let i = 0; i < querySnapshot.docs.length; i++) {
                        let subscriptionUser = querySnapshot.docs[i].data();
                        subscriptionUser.typeSubscription = await (await subscriptionUser.typeSubscription.get()).data()
                        subscriptionUser.user = await (await subscriptionUser.user.get()).data()
                        subscriptionsUsersList.push(subscriptionUser);
                    }

                    //·····································
                    // querySnapshot.forEach((doc) => {
                    //     let subscriptionUser = doc.data()
                    //     subscriptionUser.susc
                    //     subscriptionsUsersList.push(subscriptionUser);
                    // });
                    commit('SET_PAGE_SUBSCRIPTIONS_GETTED', options.page)
                    commit('SET_SUBSCRIPTIONS_GETTED_BY_USERS_LIST', subscriptionsUsersList)

                    resolve(subscriptionsUsersList)
                } catch (error) {
                    console.log(error)
                    reject(error)
                }
            })
        },
        GetAllSubscriptionsGettedByUsersList(){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("subscriptionsGettedByUsers").get()

                    console.log(querySnapshot)

                }catch(error){

                }
            })

        },
        async GetCounterSubscriptionsGettedByUsers({ commit }, isFree){
            return new Promise(async (resolve, reject) => {
                try {
                    let ref = db.collection("subscriptionsAnalytics")
                    const querySnapshot = isFree ? await ref.get() : await ref.where("id", "!=", "Yn8R3ywqutpbFmyPoAbt").get()
                    let analyticsSubscription = []
                    querySnapshot.forEach((doc) => {
                        let analytics = doc.data()
                        analyticsSubscription.push(analytics);
                    });
                    commit('SET_COUNTER_SUBSCRIPTIONS_GETTED_BY_USERS_LIST', analyticsSubscription)
                    resolve(analyticsSubscription)
                } catch (error) {
                    reject(error)
                }
            })

        }
    },
    getters:{
        getSubscriptionsList(state){
            return state.subscriptionsList;
        },
        getLoadingStatusSubscriptionImage(state){
            return state.loadingStatusSubscriptionImage;
        },
        getSubscriptionsGettedByUsers(state){
            return state.subscriptionsGettedByUsers;
        },
        counterSubscriptionsGetted(state){
            return state.analyticsSubscription.map(subscription => subscription.total).reduce((a, b) => a + b, 0);
        },
        getActualPageSubscriptionsGetted(state){
            return state.actualPageSubscriptionsGetted
        }
    }
}