<template>
<v-hover v-slot="{ hover }">
        <v-card
            color="primary"
            dark
            height="250px"
            @click="goToRoute()"
        >
            <div class="d-flex justify-center align-center" style="height:100%">
                <div class="text-center">
                    <v-scale-transition>
                        <v-icon :size="hover ? 100 : 60">{{ options.icon }}</v-icon>
                    </v-scale-transition>
                        <h3 class="px-5 mt-2 font-weight-bold">{{ options.name }}</h3>
                </div>
            </div>
        </v-card>

</v-hover>
</template>
<script>
export default {
    name:"app-config-card",
    props:{
        options:Object
    },
    methods: {
        goToRoute(){
            this.$router.push(this.options.route)
            .catch((err) => {
                let emitData = {
                    snackbar: true,
                    btn: "",
                    y: "bottom",
                    x: null,
                    mode: "",
                    timeout: 2000,
                    text: "Sorry, you don't have permissions to enter this view.",
                    color: "red darken-2",
                };
                this.$emit("showSnackBar", emitData);
            })
        }
    },
}
</script>