<template>
     <v-container
        class="elevation-4 rounded-lg px-8 mb-5"
        style="background-color: #fff"
     >
        <v-row justify="center">
            <v-col cols="12" sm="12">
                <v-btn
                    color="primary"
                    icon
                    size="20"
                    class="text-center"
                    @click="$router.go(-1)"
                >
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" sm="4" class="text-center">
                <v-avatar size="100">
                    <v-img :src="profileImage()"/>
                </v-avatar>
                <p class="secondary--text pt-2 mb-0">{{ exerciseByUser.user.firstName }} {{ exerciseByUser.user.lastName }}</p>
                <p class="secondary--text">{{ exerciseByUser.userEmail }}</p>
                <div class="text-center d-flex align-center mt-5">
                <template v-if="exerciseByUser.dynamicLink && exerciseByUser.dynamicLink.length > 0">
                    <v-text-field
                        v-model="exerciseByUser.dynamicLink"
                        ref="exerciseByUserDynamicLinkRef"
                        hide-details="auto"
                        maxlength="4"
                        class="centered-input"
                        readonly
                        solo
                        flat
                        background-color="#efefef"
                    ></v-text-field>
                    <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                        v-bind="attrs"
                            v-on="on"
                        icon 
                        color="primary"
                        v-clipboard:copy="exerciseByUser.dynamicLink"
                        v-clipboard:success="copyLink"
                        v-clipboard:error="onErrorCopyLink">
                        <v-icon>mdi-plus-box-multiple</v-icon>
                    </v-btn>
                    </template>
                    <span>Copy to clipboard</span>
                    </v-tooltip>
                    
                </template>
                <template v-else>
                    <p class="secondary--text mx-auto">There is no dynamic link for this workout yet</p>
                </template>
                </div>
                <!-- <div class="text-center mb-3">
                <v-btn class="mt-3 text-center" text color="secondary" :disabled="loadingDynamicLink" :loading="loadingDynamicLink" @click="generateDynamicLink()">
                <v-icon left dark color="primary"> mdi-link-plus </v-icon>
                    Generate Dynamic Link
                </v-btn>
                </div> -->
            </v-col>
            <v-col cols="12" sm="8">
                <v-row justify="center">
                    <v-col cols="12" sm="12">
                        <p class="primary--text font-weight-black mb-0 text-h6">{{ exerciseByUser.name }}</p>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <v-row>
                            <v-col cols="6" sm="6">
                                <div class="d-flex">
                                    <p class="secondary--text mb-0 mr-2">By Sets</p>
                                    <template>
                                        <v-icon v-if="exerciseByUser.bySets" color="primary">mdi-checkbox-marked-circle</v-icon>
                                        <v-icon v-else color="secondary">mdi-close-circle</v-icon>
                                    </template>
                                </div>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <div class="d-flex">
                                    <p class="secondary--text mb-0 mr-2">By Time</p>
                                    <template>
                                        <v-icon v-if="exerciseByUser.byTime" color="primary">mdi-checkbox-marked-circle</v-icon>
                                        <v-icon v-else color="secondary">mdi-close-circle</v-icon>
                                    </template>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <v-row>
                            <v-col cols="6" sm="6">
                                <div class="d-flex">
                                    <p class="secondary--text mb-0 mr-2">Circuit</p>
                                    <template>
                                        <v-icon v-if="exerciseByUser.circuit" color="primary">mdi-checkbox-marked-circle</v-icon>
                                        <v-icon v-else color="secondary">mdi-close-circle</v-icon>
                                    </template>
                                </div>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <div class="d-flex">
                                    <p class="secondary--text mb-0 mr-2">Suspension</p>
                                    <template>
                                        <v-icon v-if="exerciseByUser.suspension" color="primary">mdi-checkbox-marked-circle</v-icon>
                                        <v-icon v-else color="secondary">mdi-close-circle</v-icon>
                                    </template>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <div class="d-flex">
                            <p class="secondary--text mb-0 mr-2">Rest Time</p>
                            <p class="primary--text mb-0 font-weight-bold">{{ exerciseByUser.restTime }}s</p>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <div class="d-flex">
                            <p class="secondary--text mb-0 mr-2">Circuit Reps</p>
                            <p class="primary--text mb-0 font-weight-bold">{{ exerciseByUser.circuitReps }}</p>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <v-switch
                            color="primary"
                            class="d-flex justify-center center-switch ma-0 mt-3"
                            :input-value="exerciseByUser.enabled"
                            @click="disableExerciseByUser(exerciseByUser)"
                            flat
                            >
                            <template v-slot:label>
                                <p class="secondary--text d-inline mb-0" style="font-size: 14px">Enabled</p>
                            </template>
                        </v-switch>
                    </v-col>
                    <v-col cols="6" sm="6">
                        <p class="secondary--text mb-0">Requested {{ parseToRelativeDate(exerciseByUser.request.createdAt) }}</p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" sm="12">
                <v-data-table
                    v-if="!loading"
                    :headers="headers"
                    :items="exerciseByUser.exercises"
                    item-key="id"
                    :items-per-page="-1"
                    fixed-header
                    class="elevation-4 mb-5"
                >
                    <template v-slot:[`item.imagePreview`]="{ item }">
                        <v-tooltip top color="secondary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar class="my-2">
                            <img
                                v-if="getPreviewImage(item.exercise)" 
                                v-bind="attrs" 
                                v-on="on" 
                                style="cursor:pointer;"
                                @click="openDialogPreviewImage(getPreviewImage(item.exercise))"
                                :src="getPreviewImage(item.exercise)" 
                                />
                            </v-avatar> 
                        </template>
                        <span>Show Image</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.videoPreview`]="{ item }">
                        <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            icon 
                            color="primary"
                            @click="openDialogPreviewVideo(item.exercise)"
                            >
                            <v-icon>mdi-message-video</v-icon>
                        </v-btn>
                        </template>
                        <span>Show Video</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                        <span v-if="item.exercise.name && item.exercise.name['ENG']">
                            {{ item.exercise.name["ENG"] ? item.exercise.name["ENG"] : ''  }}
                        </span>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <span
                            v-if="item.exercise.description && item.exercise.description['ENG']"
                            class="d-block  text-truncate mx-auto"
                            style="max-width: 300px !important"
                        >
                        {{ item.exercise.description["ENG"] ? item.exercise.description["ENG"] : '' }}
                        </span>
                    </template>
                    <template v-slot:[`item.time`]="{ item }">
                        {{ item.time.minutes }} Minutes {{ item.time.seconds }} seconds
                    </template>
                </v-data-table>

            </v-col>
        </v-row>

        <v-dialog
            v-model="dialogPreviewVideo.show"
            transition="dialog-bottom-transition"
            max-width="700"
        >
            <v-card class="text-center">
            <v-card-actions class="pa-0">
                <v-btn icon color="secondary" class="ml-3 mt-2" @click="closeDialogVideoPreview()">
                <v-icon size="20">mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
            <img src="@/assets/logo_x.png" style="vertical-align:middle;" class="mx-2 d-inline" width="20">
            <p class="font-weight-bold secondary--text d-inline">{{ dialogPreviewVideo.name }}</p>
            <v-card-text class="pb-8 mt-3">
                <video
                v-if="dialogPreviewVideo.video"
                ref="listProTrainVideoPreview"
                width="100%"
                height="350px"
                controls
                class="mx-auto"
                :src="dialogPreviewVideo.video ? dialogPreviewVideo.video : ''"
                ></video>
                <!-- <v-btn @click="closeDialogVideoPreview()">
                Close
                </v-btn> -->
            </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogPreviewImage.show" 
            max-width="800"
        >
            <v-card style="line-height: 0; !important;">
            <v-btn fab x-small color="red" style="position:absolute; top:5px; left: 5px;" @click="closeDialogImagePreview()">
                <v-icon size="15" color="white">mdi-close</v-icon>
            </v-btn>
                <img :src="dialogPreviewImage.img" style="width:100%; height:100%;">
            </v-card>
        </v-dialog>
            <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
                snackbar.text
            }}</v-snackbar>
     </v-container>
</template>
<script>
import moment from "moment";
export default {
    name:"ExerciseByUserShow",
    data: () => ({
        exerciseByUser:{
            bySets: false,
            byTime: false,
            circuit: false,
            circuitReps: 0,
            name: "",
            restTime: 0,
            suspension: false,
            user: null,
            userEmail: "",
            id: "",
            exercises: [],
            request: {
                savedOnDB: false,
                status: "",
                createdAt: null,
                updatedAt: null,
                message: ""
            }
        },
        statusList:[
            "PENDING",
            "APPROVED",
            "REJECTED"
        ],
        headers: [
            { text: "", align: "center", value: "imagePreview", sortable: false },
            { text: "Preview", align: "center", value: "videoPreview", sortable: false },
            { text: 'Name', align:'center', value:'name', sortable:false },
            { text: 'Description', align:'center', value:'description', sortable:false },
            { text: 'Reps', align:'center', value:'reps', sortable:false },
            { text: 'Sets', align:'center', value:'sets', sortable:false },
            { text: 'Time', align:'center', value:'time', sortable:false },
        ],
        dialogPreviewVideo:{
            show:false,
            video:'',
            name:''
        },
        dialogPreviewImage:{
            show:false,
            video:'',
        },
        loading: true,
        editStatus: false,
        loadingStatus: false,
        loadingSaveExercise: false,
        snackbar: {
            snackbar: false,
            timeout: 2000,
            text: null,
            color: "",
        },
    }),
    created() {
        const vm = this
        vm.exerciseByUser = vm.$route.params
        vm.getUserAndExercises()
    },
    methods: {
        async getUserAndExercises(){
            const vm = this
            try {
                vm.loading = true
                vm.$emit("showOverlay", true);
                const user = await vm.exerciseByUser.user.get()
                vm.$set(vm.exerciseByUser, 'user', user.data())

                for (let i = 0; i < vm.exerciseByUser.exercises.length; i++) {
                    const exerciseToGet = vm.exerciseByUser.exercises[i];
                    const exercise = await vm.$store.dispatch('GetEspecificExercise', exerciseToGet.exercise)
                    vm.$set(exerciseToGet, 'exercise', exercise)   
                }
                vm.loading = false
                vm.$emit("showOverlay", false);
            } catch (error) {
                vm.loading = false
                vm.$emit("showOverlay", false);
                
            }

        },
        profileImage(){
            const vm = this
            return vm.exerciseByUser.user.photoURL ? vm.exerciseByUser.user.photoURL : vm.exerciseByUser.user.photo ? vm.exerciseByUser.user.photo : require('@/assets/default-avatar.png')
        },
        changeRouteUser(item){
            const vm = this
            vm.$router.push({ name: 'User Profile', params: vm.exerciseByUser.user })
            .catch((err) => {
                vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                vm.snackbar.color = "red darken-2";
                vm.snackbar.snackbar = true;
            })
        },
        parseToRelativeDate(date){
            return moment(date.toDate()).fromNow()
        },
        getColorStatus(status){
            if(status === 'PENDING'){
                return 'amber'
            }else if (status === 'APPROVED'){
                return 'success'
            }else if(status === 'REJECTED'){
                return 'danger'
            }
        },
        getPreviewImage(excercise){
            const images = [
                "imageShaunaLink",
                "imageAlexLink",
                "imageJustinLink",
                "imageVeonLink",
            ];
            let image = ''
            for (let i = 0; i < images.length; i++) {
                const trainer = images[i];
                if(excercise[trainer]){
                    image = excercise[trainer]
                    break
                }
            }
            if(image === '' && excercise.trainersList && excercise.trainersList.length > 0){
                for (let i = 0; i < excercise.trainersList.length; i++) {
                    const trainer = excercise.trainersList[i];
                    if(trainer.exImage && trainer.exImage.link){
                        image = trainer.exImage.link
                        break
                    }
                }
            }
            return image
        },
        getPreviewVideo(excercise){
            const videos = [
                "videoShaunaLink",
                "videoAlexLink",
                "videoJustinLink",
                "videoVeonLink",
            ];
            let video = ''
            for (let i = 0; i < videos.length; i++) {
            const trainer = videos[i];
                if(excercise[trainer]){
                    video = excercise[trainer]
                    break
                }
            }
            if(video === '' && excercise.trainersList && excercise.trainersList.length > 0){
                for (let i = 0; i < excercise.trainersList.length; i++) {
                    const trainer = excercise.trainersList[i];
                    if(trainer.exVideo && trainer.exVideo.link){
                        video = trainer.exVideo.link
                        break
                    }
                }
            }
            return video
        },
        openDialogPreviewVideo(excercise){
            const vm = this
            const video = vm.getPreviewVideo(excercise)
            vm.dialogPreviewVideo = {
                show: true,
                video: video ? video : '',
                name: excercise.name['ENG']
            }
        },
        closeDialogVideoPreview(){
            const vm = this
            vm.dialogPreviewVideo = {
                show: false,
                video: '',
                name: ''
            }
        },
        openDialogPreviewImage(img){
            const vm = this
            vm.dialogPreviewImage = {
                show: true,
                img: img ? img : ''
            }
        },
        closeDialogImagePreview(){
            const vm = this
            vm.dialogPreviewImage.show = false
        },
        async disableExerciseByUser(exerciseByUser){
            const vm = this
            const { enabled, id, name, userEmail } = vm.exerciseByUser
            await vm.$store.dispatch('DisableExerciseByUser', { id, enabled })
            await vm.$store.dispatch("GenerateLog", {
                id: id,
                name,
                email: userEmail,
                type: "editedExerciseByUser",
            })

            // vm.exerciseByUser.enabled = !enabled
                
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Exercise by User disabled successfully",
                color: "success",
            };
            vm.$emit("showSnackBar", emitData);
        },
        onErrorCopyLink(){
        const vm = this;
        vm.snackbar.text = "Copied !";
        vm.snackbar.color = "dark";
        vm.snackbar.snackbar = true;
        },
        copyLink(e){
        const vm = this;
        vm.snackbar.text = "Copied !";
        vm.snackbar.color = "dark";
        vm.snackbar.snackbar = true;
        },
    },
}
</script>