import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";

const db = firebase.firestore();

const storageImages = firebase.storage().ref("exercises-images/");

export default {
    state: {
        pwoList: [],
        pwoStatusImage: 0,
        pwoModuleUnsubscribe: {},
        pwoCounterReproductor: {},
        unsubscribePWOList: null,
        unsubscribePWOCounterReproductor: null
    },
    mutations: {
        SET_VALUE_LOADING_IMAGE_PWO(state, value) {
            state.pwoStatusImage = value
        },
        SET_PWO_LIST(state, pwoList) {
            state.pwoList = pwoList;
        },
        SET_PWO_COUNTER_REPRODUCTOR(state, counterReproductor) {
            state.PWOCounterReproductor = counterReproductor;
        },
        SET_UNSUBSCRIBE_PWO_LIST(state, unsubscribe) {
            state.unsubscribePWOList = unsubscribe;
        },
        GO_UNSUBSCRIBE_PWO_LIST(state) {
            state.unsubscribePWOList();
        },
        SET_UNSUBSCRIBE_PWO_COUNTER_REPRODUCTOR(state, unsubscribe) {
            state.unsubscribePWOCounterReproductor = unsubscribe;
        },
        GO_UNSUBSCRIBE_PWO_COUNTER_REPRODUCTOR(state) {
            state.unsubscribePWOCounterReproductor();
        },
        DELETE_PWO_FROM_ARRAY(state, pwo) {
            const index = state.pwoList.indexOf(pwo);
            if (index > -1) {
                state.pwoList.splice(index, 1);
            }
        }
    },
    actions: {
        GetPWO({ commit }) {
            return new Promise((resolve, reject) => {
                db.collection("ProfessionalWorkouts").get()
                    .then((querySnapshot) => {
                        var professionalWorkouts = [];
                        querySnapshot.forEach((doc) => {
                            let pwo = doc.data();
                            pwo.id = doc.id;
                            professionalWorkouts.push(pwo);
                        });
                        commit("SET_PWO_LIST", professionalWorkouts);
                        resolve(professionalWorkouts);
                    }).catch((err) => {
                        reject(err)
                    })
            });
        },
        GetPWOPaginatedFirstPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                if (getters.getProfessionalWorkouts.length > 0) {
                    commit("GO_UNSUBSCRIBE_PWO_LIST");
                }
                let unsubscribe = db.collection("ProfessionalWorkouts").orderBy('name.ENG').limit(itemsPerPage).onSnapshot((querySnapshot) => {
                        let professionalWorkouts = [];
                        querySnapshot.forEach((doc) => {
                            let pwo = doc.data()
                            pwo.id = doc.id;
                            professionalWorkouts.push(pwo);
                        });
                        commit("SET_PWO_LIST", professionalWorkouts);
                        resolve(professionalWorkouts);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_PWO_LIST", unsubscribe);
            });
        },
        GetPWONextPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                commit("GO_UNSUBSCRIBE_PWO_LIST");
                let unsubscribe = db.collection("ProfessionalWorkouts").orderBy('name.ENG').startAfter(getters.getProfessionalWorkouts[getters.getProfessionalWorkouts.length - 1].name['ENG']).limit(itemsPerPage).onSnapshot((querySnapshot) => {
                        let professionalWorkouts = [];
                        querySnapshot.forEach((doc) => {
                            let pwo = doc.data()
                            pwo.id = doc.id;
                            professionalWorkouts.push(pwo);
                        });
                        console.log(professionalWorkouts);
                        if (professionalWorkouts.length > 0) {
                            commit("SET_PWO_LIST", professionalWorkouts);
                        }
                        resolve(professionalWorkouts);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_PWO_LIST", unsubscribe);
            });
        },
        GetPWOPrevPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                commit("GO_UNSUBSCRIBE_PWO_LIST");
                let unsubscribe = db.collection("ProfessionalWorkouts").orderBy('name.ENG').endBefore(getters.getProfessionalWorkouts[0].name['ENG']).limitToLast(itemsPerPage).onSnapshot((querySnapshot) => {
                        let professionalWorkouts = [];
                        querySnapshot.forEach((doc) => {
                            let pwo = doc.data()
                            pwo.id = doc.id;
                            professionalWorkouts.push(pwo);
                        });
                        commit("SET_PWO_LIST", professionalWorkouts);
                        resolve(professionalWorkouts);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_PWO_LIST", unsubscribe);
            });
        },
        GoUnsuscribePwoList({ commit }) {
            commit("GO_UNSUBSCRIBE_PWO_LIST");
        },
        GoUnsuscribePwoCounterReproductor({ commit }) {
            commit("GO_UNSUBSCRIBE_PWO_COUNTER_REPRODUCTOR");
        },
        UploadImagePWO({ commit }, pwo) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(pwo.image, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_IMAGE_PWO', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL });
                    })
                })
            });
        },
        CreatePWO({}, pwo) {
            return new Promise(async(resolve, reject) => {
                const ref = db.collection("ProfessionalWorkouts").doc();
                const newPWO = await ref.get();
                pwo.id = ref.id;
                await db.collection("ProfessionalWorkouts")
                    .doc(newPWO.id)
                    .set(pwo)
                    .then(async(res) => {
                        await db.collection("countersAnalytics")
                            .doc("pwoReproductions")
                            .update({
                                [newPWO.id]: 0 })
                            .then((resCounter) => {
                                console.log('newPWO.id', newPWO.id);
                                resolve(newPWO.id);
                            })
                            .catch((errCounter) => {
                                reject(errCounter);
                            });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },
        DeletePWO({}, pwo) {
            return new Promise(async(resolve, reject) => {
                await db.collection("ProfessionalWorkouts")
                    .doc(pwo.id)
                    .delete()
                    .then((res) => {
                        db.collection("countersAnalytics").doc('pwoReproductions').update({
                            [pwo.id]: firebase.firestore.FieldValue.delete()
                        })
                        resolve(pwo.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        UpdatePWO({}, pwo) {
            return new Promise(async(resolve, reject) => {
                if (pwo.selectedImage) {
                    delete pwo.selectedImage
                }
                await db.collection("ProfessionalWorkouts")
                    .doc(pwo.id)
                    .update(pwo)
                    .then((res) => {
                        resolve(pwo.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        DisablePWO({}, pwo){
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("ProfessionalWorkouts").doc(pwo.id).update({
                      enabled: !pwo.enabled
                    })
                    resolve(pwo.id);
                } catch (error) {
                    reject(error);
                }
            });
      
        }

    },
    getters: {
        getProfessionalWorkouts(state) {
            return state.pwoList;
        },
        getLoadingImagePwo(state) {
            return state.pwoStatusImage;
        },
        getPWOCounterReproductor(state) {
            return state.pwoCounterReproductor;
        }
    }
}