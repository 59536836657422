<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="4">
        <p class="text-h6 text--secondary font-weight-bold">Exercises</p>
        <!-- <v-btn @click="throwQuery()" color="primary">Throw query</v-btn> -->
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-btn text color="secondary" @click="createExcercise()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New excercise
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          label="Search for a exercises"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getExercises"
          fixed-header
          item-key="tableId"
          class="elevation-4"
          :page.sync="page"
          @update:page="onPageChange"
          :items-per-page.sync="itemsPerPage"
          @update:items-per-page="onItemsPerPageUpdate"
          :sortBy.sync="sortBy"
          @update:sortBy="onSortByChange"
        >
          <template v-slot:[`item.imagePreview`]="{ item }">
            <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar class="my-2">
                  <img
                    v-if="getPreviewImage(item)"
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: pointer"
                    @click="openDialogPreviewImage(getPreviewImage(item))"
                    :src="getPreviewImage(item)"
                  />
                </v-avatar>
              </template>
              <span>Show Image</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.videoPreview`]="{ item }">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="primary"
                  @click="openDialogPreviewVideo(item)"
                >
                  <v-icon>mdi-message-video</v-icon>
                </v-btn>
              </template>
              <span>Show Video</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name["ENG"] }}
          </template>
          <template v-slot:[`item.description.ENG`]="{ item }">
            <span
              class="d-block text-truncate mx-auto"
              style="max-width: 300px !important"
            >
              {{ item.description["ENG"] }}
            </span>
          </template>
          <template v-slot:[`item.isReactNativeExercise`]="{ item }">
            <h4 v-if="item.isReactNativeExercise" class="primary--text">
              New App
            </h4>
            <template v-else>
              <h4 class="secondary--text">Old App</h4>
            </template>
          </template>
          <template v-slot:[`item.twoHands`]="{ item }">
            <h4 v-if="item.twoHands" class="primary--text">Yes</h4>
            <template v-else>
              <h4 class="secondary--text">No</h4>
            </template>
          </template>
          <template class="mx-auto" v-slot:[`item.enabled`]="{ item }">
            <v-switch
              v-if="item"
              class="center-switch"
              color="primary"
              :input-value="item.enabled"
              @click="disableExercise(item)"
              flat
            ></v-switch>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="dropdown">
              <span>...</span>
              <div class="dropdown-content">
                <v-btn
                  class="optionButton"
                  color="transparent"
                  @click="changeRouteEditExercise(item)"
                >
                  <v-icon class="mr-2">mdi-pencil</v-icon>Edit
                </v-btn>
                <v-btn
                  class="optionButton"
                  color="transparent"
                  @click="openDialogConfirm(item)"
                >
                  <v-icon class="mr-2">mdi-delete</v-icon>Delete
                </v-btn>
              </div>
            </div>
            <!-- <v-btn v-if="item" text icon color="primary" @click="changeRouteEditExercise(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn v-if="item" text icon color="secondary" @click="openDialogConfirm(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
          </template>
          <!-- <template v-slot:footer="{}">
            <v-row justify="end">
              <v-col cols="1">
                <v-select
                  class="mr-auto"
                  v-model="itemsPerPage"
                  :items="[5,10,15,20,30]"
                >
               </v-select>
              </v-col>
              <v-col cols="2" class="text-right mr-3" align-self="center">
              <v-btn color="primary" x-small class="mx-1" :disabled="pageCounter === 1" @click="goPrevPage()">
                <v-icon size="15">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <p class="d-inline">{{ pageCounter }}</p>
              <v-btn color="primary" x-small class="mx-1" :disabled="getExercises.length < itemsPerPage || getExercises.length === 0" @click="goNextPage()">
                <v-icon size="15">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
              </v-col>
            </v-row>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogPreviewVideo.show"
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card class="text-center">
        <v-card-actions class="pa-0">
          <v-btn
            icon
            color="secondary"
            class="ml-3 mt-2"
            @click="closeDialogVideoPreview()"
          >
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <img
          src="@/assets/logo_x.png"
          style="vertical-align: middle"
          class="mx-2 d-inline"
          width="20"
        />
        <p class="font-weight-bold secondary--text d-inline">
          {{ dialogPreviewVideo.name }}
        </p>
        <v-card-text class="pb-8 mt-3">
          <video
            v-if="dialogPreviewVideo.video"
            ref="listProTrainVideoPreview"
            width="100%"
            height="350px"
            controls
            class="mx-auto"
            :src="dialogPreviewVideo.video ? dialogPreviewVideo.video : ''"
          ></video>
          <!-- <v-btn @click="closeDialogVideoPreview()">
              Close
            </v-btn> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPreviewImage.show" max-width="800">
      <v-card style="line-height: 0; !important;">
        <v-btn
          fab
          x-small
          color="red"
          style="position: absolute; top: 5px; left: 5px"
          @click="closeDialogImagePreview()"
        >
          <v-icon size="15" color="white">mdi-close</v-icon>
        </v-btn>
        <img :src="dialogPreviewImage.img" style="width: 100%; height: 100%" />
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
      <v-card class="text-center">
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
        <v-icon class="text-center ma-2" size="120" color="primary"
          >mdi-alert</v-icon
        >
        <v-card-text class="pa-4 text-center"
          >This action is irreversible and will delete all data about the
          Excercise. Including the video, image, connection with PWO and
          information</v-card-text
        >
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeDialogConfirm()"
          >No</v-btn
        >

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="openDialogReAuth('deleteExercise', dialog.item)"
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.text }}</v-snackbar
    >
    <modal-reauth
      :dialogReAuth="dialogReAuth"
      @isSuccessReAuth="isSuccessReAuth"
    ></modal-reauth>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
export default {
  name: "Exercises",
  components: { modalReauth },
  data: () => ({
    search: "",
    headers: [
      // { text: "Video", align: "center", value: "image", sortable: false },
      { text: "", align: "center", value: "imagePreview", sortable: false },
      {
        text: "Preview",
        align: "center",
        value: "videoPreview",
        sortable: false,
      },
      // { text: "Order Number", align: "center", value: "orderNumber", sortable: true },
      { text: "Name", align: "center", value: "name.ENG", sortable: true },
      {
        text: "Description",
        align: "center",
        value: "description.ENG",
        sortable: true,
      },
      // { text: "Available On", align: "start", value: "isReactNativeExercise", sortable: true },
      // { text: "Collection", align: "start", value: "collectionName", sortable: true },
      {
        text: "Two Hands",
        align: "center",
        value: "twoHands",
        sortable: false,
      },
      { text: "Enabled", align: "center", value: "enabled", sortable: false },
      { text: "Actions", align: "center", value: "action", sortable: false },
    ],
    loading: true,
    itemsPerPage: 10,
    pageCounter: 1,
    options: {},
    dialogPreviewVideo: {
      show: false,
      video: "",
      name: "",
    },
    dialogPreviewImage: {
      show: false,
      video: "",
    },
    dialogReAuth: {
      show: false,
      action: "",
      item: {},
    },
    dialog: {
      show: false,
      item: {},
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    page: 0,
    itemsPerPage: 10,
    sortBy: "name.ENG",
  }),
  created() {
    let vm = this;
    vm.getExercisesList();
    if (this.$route.params.previousRoute === "Edit Exercise") {
      const tableState = localStorage.getItem("exercisesTableState");
      if (tableState) {
        const tableStateObj = JSON.parse(tableState);
        this.search = tableStateObj.query;
        this.itemsPerPage = tableStateObj.hitsPerPage;
        this.page = tableStateObj.page;
      }
    } else {
      this.itemsPerPage = -1;
    }
  },
  methods: {
    async getExercisesList() {
      let vm = this;
      vm.loading = true;
      vm.$store
        .dispatch("GetExercises")
        .then(async (res) => {
          vm.pageCounter = 1;
          await vm.getExercisesNewList();
          vm.loading = false;
        })
        .catch((err) => {
          console.error(err);
          vm.loading = false;
        });
    },
    async getExercisesNewList() {
      const vm = this;
      try {
        await vm.$store.dispatch("GetExercisesNew");
      } catch (error) {
        console.error(error);
      }
    },
    getFirstPage() {
      let vm = this;
      vm.$store
        .dispatch("GetExercisesListPaginatedFirstPage", vm.itemsPerPage)
        .then((res) => {
          vm.pageCounter = 1;
          vm.loading = false;
        })
        .catch((err) => {
          console.error(err);
          vm.loading = false;
        });
    },
    goNextPage() {
      let vm = this;
      vm.$store
        .dispatch("GetExercisesNextPage", vm.itemsPerPage)
        .then((res) => {
          if (res.length > 0) {
            vm.pageCounter = vm.pageCounter + 1;
          }
          vm.loading = false;
        })
        .catch((err) => {
          console.error(err);
          vm.loading = false;
        });
    },
    goPrevPage() {
      let vm = this;
      vm.$store
        .dispatch("GetExercisesPrevPage", vm.itemsPerPage)
        .then((res) => {
          vm.pageCounter = vm.pageCounter - 1;
          vm.loading = false;
        })
        .catch((err) => {
          console.error(err);
          vm.loading = false;
        });
    },
    changeRouteEditExercise(item) {
      let vm = this;
      vm.$router.push({ name: "Edit Exercise", params: item }).catch((err) => {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    getPreviewImage(excercise) {
      const images = [
        "imageShaunaLink",
        "imageAlexLink",
        "imageJustinLink",
        "imageVeonLink",
      ];
      let image = "";
      for (let i = 0; i < images.length; i++) {
        const trainer = images[i];
        if (excercise[trainer]) {
          image = excercise[trainer];
          break;
        }
      }
      if (image === "" && excercise.trainersList.length > 0) {
        for (let i = 0; i < excercise.trainersList.length; i++) {
          const trainer = excercise.trainersList[i];
          if (trainer.exImage && trainer.exImage.link) {
            image = trainer.exImage.link;
            break;
          }
        }
      }
      return image;
    },
    getPreviewVideo(excercise) {
      const videos = [
        "videoShaunaLink",
        "videoAlexLink",
        "videoJustinLink",
        "videoVeonLink",
      ];
      let video = "";
      for (let i = 0; i < videos.length; i++) {
        const trainer = videos[i];
        if (excercise[trainer]) {
          video = excercise[trainer];
          break;
        }
      }
      if (video === "" && excercise.trainersList.length > 0) {
        for (let i = 0; i < excercise.trainersList.length; i++) {
          const trainer = excercise.trainersList[i];
          if (trainer.exVideo && trainer.exVideo.link) {
            video = trainer.exVideo.link;
            break;
          }
        }
      }
      return video;
    },
    openDialogPreviewVideo(excercise) {
      const vm = this;
      const video = vm.getPreviewVideo(excercise);
      vm.dialogPreviewVideo = {
        show: true,
        video: video ? video : "",
        name: excercise.name["ENG"],
      };
    },
    closeDialogVideoPreview() {
      const vm = this;
      vm.dialogPreviewVideo = {
        show: false,
        video: "",
        name: "",
      };
    },
    openDialogPreviewImage(img) {
      const vm = this;
      vm.dialogPreviewImage = {
        show: true,
        img: img ? img : "",
      };
    },
    closeDialogImagePreview() {
      const vm = this;
      vm.dialogPreviewImage.show = false;
      // vm.dialogPreviewImage = {
      //   show: false,
      //   img: ''
      // }
    },
    async disableExercise(exercise) {
      const vm = this;
      const { collectionName, enabled, id, name } = exercise;
      await vm.$store.dispatch("DisableExercise", {
        id,
        collectionName,
        enabled,
      });
      await vm.$store.dispatch("GenerateLog", {
        id: id,
        title: name["ENG"],
        type: "editedExercise",
      });

      let emitData = {
        snackbar: true,
        btn: "",
        y: "bottom",
        x: null,
        mode: "",
        timeout: 2000,
        text: "Exercise edited successfully",
        color: "success",
      };
      vm.$emit("showSnackBar", emitData);
      vm.getExercisesList();
    },
    deleteExercise(exercise) {
      const vm = this;
      vm.$emit("showOverlay", true);
      vm.$store
        .dispatch("DeleteExercise", exercise)
        .then((res) => {
          vm.$store.dispatch("GenerateLog", {
            id: exercise.id,
            title: exercise.name.ENG,
            type: "deletedExercise",
          });
          vm.getExercisesList();
          vm.$emit("showOverlay", false);
          // vm.loading = false;
        })
        .catch((err) => {
          vm.$emit("showOverlay", false);
          console.error(err);
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error deleting Exercise",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        });
    },
    openDialogConfirm(item) {
      let vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      let vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "deleteExercise":
          vm.deleteExercise(dialogReAuthResponse.item);
          break;
        default:
          break;
      }
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.closeDialogConfirm();
    },
    createExcercise() {
      let vm = this;
      vm.$router.push({ name: "Create Excercise" }).catch((err) => {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    onPageChange(page) {
      console.log(page);
    },
    onItemsPerPageUpdate(items) {
      console.log(items);
    },
    onSortByChange(sortBy, test) {
      console.log(sortBy, test);
    },
  },
  destroyed() {
    let vm = this;
    // vm.$store.dispatch("GoUnsubscribeExercisesModule", "exercise");
  },
  computed: {
    ...mapGetters(["getExercises"]),
  },
  watch: {
    options: {
      handler() {
        console.log(this.options);
      },
      deep: true,
    },
    // itemsPerPage:{
    //   handler(newValue){
    //     this.getFirstPage()
    //   }
    // }
  },
  beforeRouteLeave(to, from, next) {
    console.log(to);
    if (to.name === "Edit Exercise") {
      const currentState = JSON.stringify({
        hitsPerPage: this.itemsPerPage,
        query: this.search,
        page: this.page,
      });
      localStorage.setItem("exercisesTableState", currentState);
    } else {
      localStorage.removeItem("exercisesTableState");
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "Edit Exercise") {
      to.params.previousRoute = from.name;
    }
    next();
  },
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown span {
  padding: 4px 10px;
  font-size: 24px;
  font-weight: bold;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* padding: 12px 16px; */
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
}

.dropdown:hover span {
  background-color: #e4e4e4;
}

.optionButton {
  font-size: 14px !important;
  justify-content: start;
  text-align: left;
}
</style>