<template>
  <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff"
  >
    <v-form ref="formTrainer" v-model="validTrainer">
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-btn
            color="primary"
            icon
            size="20"
            class="text-center"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h3 class="secondary--text d-inline mr-2">Create Trainer</h3>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto text-center">
            <!-- <p class="secondary--text mt-5 text-left d-inline">Avatar Trainer</p> -->
            <v-card
            color="#efefef"
            class="rounded-circle elevation-0 d-flex mt-5 mx-auto justify-center align-center"
            width="250"
            height="250"
            @click="$refs.trainerImage.$refs.input.click()"
            >
            <v-img :src="trainer.image.img ? trainer.image.img : ''" cover width="250" class="rounded-circle" height="250">
            </v-img>
                <v-icon large color="primary" style="position: absolute; transform: translate(50%,-50%); top: 50%; right: 50%;"> mdi-camera </v-icon>
            </v-card>
             <!-- <div class="mx-auto mt-5 text-center" style="width:150px">
                <p class="secondary--text text-center pb-1">Order number</p>
                <v-text-field
                    v-model.number="trainer.orderNumber"
                    @keypress="numberValid($event)"
                    maxlength="4"
                    class="centered-input"
                    solo
                    flat
                    background-color="#efefef"
                ></v-text-field>
            </div> -->
        </v-col>
          <v-col cols="12" sm="12" md="4" class="mx-auto">
            <p class="secondary--text pb-1">First Name</p>
            <v-text-field
            v-model="trainer.firstName"
            :rules="trainerRules.firstName"
            solo
            flat
            background-color="#efefef"
            ></v-text-field>
            <p class="secondary--text pb-1">Last Name</p>
            <v-text-field
            v-model="trainer.lastName"
            :rules="trainerRules.lastName"
            solo
            flat
            background-color="#efefef"
            ></v-text-field>
            <p class="secondary--text pb-1">Biography</p>
            <v-textarea v-model="trainer.biography" :rules="trainerRules.biography" maxlength="250" counter="250" solo flat no-resize height="355" background-color="#efefef">
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" class="float-right" :disabled="!trainer.image.img || !validTrainer" @click="uploadTrainerImage()">Finish</v-btn>
         </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="dialogUploadImage"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading image, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingStatusTrainerImage"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
     <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
     <v-file-input
      ref="trainerImage"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg"
      dark
      outlined
      :show-size="1000"
      @change="getTrainerImage"
    />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
export default {
  name: "TrainerCreate",
  components: { modalReauth },
  data: () => ({
      validTrainer:false,
      trainer:{
          firstName:'',
          lastName:'',
          biography:'',
          image:{
              img:null,
              link:'',
              ref:'',
              size:'',
              type:''
          },
          orderNumber:''
      },
      trainerRules:{
        firstName: [v => !!v || 'First Name is required'],
        lastName: [v => !!v || 'Last Name is required'],
        biography: [(v) => !!v || "Biography section is required"],
      },
      snackbar: {
        snackbar: false,
        timeout: 2000,
        text: null,
        color: "",
      },
      dialogUploadImage:false,

  }),
  methods:{
    numberValid(e){
        e = (e) || window.event
        const charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault()
        } else {
            return true
        }
    },
    getFileSize(bytes, dp = 1) {
      const thresh = 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
        //si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']; // original
      const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return bytes.toFixed(dp) + ' ' + units[u];
    },
    getTrainerImage(file){
        let vm = this
        if(file){
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          const fr = new FileReader();
          fr.onload = (el) => {
            vm.trainer.image = {
              img: el.target.result,
              file: file.type.split("/")[1],
              size:vm.getFileSize(file.size, 2),
              link:""
            }
          };
          fr.readAsDataURL(file);
        }else{
           let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .png and .jpeg files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }
      }
    },
    uploadTrainerImage(){
      let vm = this;
      vm.dialogUploadImage = true
       vm.$store
        .dispatch("UploadImageTrainer", vm.trainer)
        .then((storage) => {
          vm.trainer.image.link = storage.link
          vm.trainer.image.ref = storage.ref
          vm.dialogUploadImage = false
          vm.createTrainer();
          
        }).catch((err) => {
            console.log(err);
          vm.dialogUploadImage = false

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error uploading image",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        })
    },
    async createTrainer(){
     const vm = this;
          try {
            const id = await vm.$store.dispatch('CreateTrainer', vm.trainer)
            await vm.$store.dispatch("GenerateLog", {
                id: id,
                name: vm.trainer.firstName,
                type: "createdTrainer",
            })
          
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Trainer Created successfully",
                color: "success",
            };
            vm.$router.push("/admin/trainers")
            .catch((err) => {
                vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                vm.snackbar.color = "red darken-2";
                vm.snackbar.snackbar = true;
            })
            vm.$emit("showSnackBar", emitData);
          } catch (error) {

              let emitData = {
                  snackbar: true,
                  btn: "",
                  y: "bottom",
                  x: null,
                  mode: "",
                  timeout: 2000,
                  text: "Error creating trainer",
                  color: "red",
              };
              vm.$emit("showSnackBar", emitData);
          }
    }
  },
  computed:{
      ...mapGetters(["getLoadingStatusTrainerImage" ])
  }
};
</script>