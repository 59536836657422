import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { v4 as uuidv4 } from "uuid";

Vue.use(VueAxios, Axios);

const db = firebase.firestore();


Vue.use(VueAxios, Axios);

const storage = firebase.storage().ref("firmwareFiles/");

export default {
    state:{
        firmwareVersionsList: [],
        loadingStatusFirmwareVersionFile:0,
    },
    mutations:{
        SET_FIRMWARE_VERSIONS_LIST(state, firmwareVersionsList){
            state.firmwareVersionsList = firmwareVersionsList
        },
        SET_VALUE_LOADING_STATUS_FIRMWARE_VERSION_FILE(state, progress) {
            state.loadingStatusFirmwareVersionFile = progress
        },
    },
    actions:{
        UploadFileFirmwareVersion({ commit }, firmwareVersion) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storage.child(`${firmwareVersion.file.name}[${random}].json`);
                const blob = new Blob([firmwareVersion.file.data], { type: 'application/json' });
                let metadata = {
                    contentType: 'application/json'
                }
                let uploadTask = storageRef.put(blob, metadata)
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_FIRMWARE_VERSION_FILE', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        GetFirmwareVersionsList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("firmwareVersionsList").get()
                    const firmwareVersionsList = []
                    querySnapshot.forEach(async(doc) =>  {
                        let firmwareVersion = doc.data()
                        firmwareVersionsList.push(firmwareVersion)
                    })
                    commit('SET_FIRMWARE_VERSIONS_LIST', firmwareVersionsList)
                    resolve(firmwareVersionsList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        GetFirmwareVersion({ }, firmwareVersion){
            return new Promise(async (resolve, reject) => {
                try {
                    let resFirmwareVersion = await db.collection("firmwareVersionsList").doc(firmwareVersion.id).get()
                    let gettedFirmwareVersion = resFirmwareVersion.data()
                    resolve(gettedFirmwareVersion)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateFirmwareVersion({}, firmwareVersion) {
            return new Promise(async(resolve, reject) => {
                try {
                    delete firmwareVersion.file.data
                    const ref = db.collection("firmwareVersionsList").doc();
                    let newFirmwareVersion = await ref.get();
                    firmwareVersion.id = ref.id;
                    firmwareVersion.createdAt = new Date()
                    firmwareVersion.updatedAt = new Date()
                    await db.collection("firmwareVersionsList").doc(newFirmwareVersion.id).set(firmwareVersion)
                    resolve(newFirmwareVersion.id);
                } catch (error) {
                    reject(error);
                }
            })
        },
        UpdateFirmwareVersion({}, firmwareVersion) {
            return new Promise(async(resolve, reject) => {
                try {
                    if (firmwareVersion.file.data) {
                        delete firmwareVersion.file.data
                    }
                    if(firmwareVersion.createdAt) {
                        delete firmwareVersion.createdAt
                    }
                    firmwareVersion.updatedAt = new Date()
                    await db.collection("firmwareVersionsList").doc(firmwareVersion.id).update(firmwareVersion)
                    resolve(firmwareVersion.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteFirmwareVersion({}, firmwareVersion) {
            return new Promise(async(resolve, reject) => {
                try {
                    let storageRef = storage.child(firmwareVersion.file.ref);
                    await storageRef.delete()
                    await db.collection("firmwareVersionsList").doc(firmwareVersion.id).delete()
                    resolve(firmwareVersion.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    getters:{
        getFirmwareVersionsList(state){
            return state.firmwareVersionsList;
        },
        getLoadingStatusFirmwareVersionFile(state){
            return state.loadingStatusFirmwareVersionFile;
        },
    }
}