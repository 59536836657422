import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import moment from 'moment';
import { requestEndPoint } from '@/store/Api'

const db = firebase.firestore();

Vue.use(VueAxios, Axios);
export default {
    state: {
        feedbackList: [],
        unsubscribeGetFeedbackList: null,
        subscribedFeedbackList: false,
        feedbackNotifications: [],
        unsubscribeGetFeedbackNotifications: null,
        subscribedFeedbackNotifications: false,
        feedbackListForBadge: [],
        unsubscribeGetFeedbackListForBadge: null,
        subscribedFeedbackListForBadge: false,
        feedbackListWithoutCategorie: [],
        unsubscribeGetFeedbackListWithoutCategorie: null,
        subscribedFeedbackListWithoutCategorie: false,
        feedbackListWithoutCategorie: [],
        unsubscribeGetFeedbackListWithoutCategorie: null,
        subscribedFeedbackListWithoutCategorie: false,
        feedbackCategoriesList: [],
        unsubscribeGetFeedbackCategoriesList: null,
        subscribedFeedbackCategoriesList: false,
        feedbackStatusList: [],
        unsubscribeGetFeedbackStatusList: null,
        subscribedFeedbackStatusList: false,
        feedbackEmailList: [],
        unsubscribeGetFeedbackEmailList: null,
        subscribedFeedbackEmailList: false,
    },
    mutations: {
        SET_FEEDBACK_LIST(state, feedbackList) {
            state.feedbackList = feedbackList;
        },
        SET_UNSUBSCRIBE_GET_FEEDBACK_LIST(state, unsubscribe) {
            state.unsubscribeGetFeedbackList = unsubscribe;
            state.subscribedFeedbackList = true
        },
        GO_UNSUBSCRIBE_GET_FEEDBACK_LIST(state) {
            state.unsubscribeGetFeedbackList();
            state.subscribedFeedbackList = false
            state.unsubscribeGetFeedbackList = null
        },
        ADD_FEEDBACK_NOTIFICATION(state, feedbackNotification) {
            state.feedbackNotifications.push(feedbackNotification)
        },
        SET_UNSUBSCRIBE_GET_FEEDBACK_NOTIFICATIONS(state, unsubscribe) {
            state.unsubscribeGetFeedbackNotifications = unsubscribe;
            state.subscribedFeedbackNotifications = true
        },
        GO_UNSUBSCRIBE_GET_FEEDBACK_NOTIFICATIONS(state) {
            state.unsubscribeGetFeedbackNotifications();
            state.subscribedFeedbackNotifications = false
            state.unsubscribeGetFeedbackNotifications = null
        },
        SET_FEEDBACK_LIST_FOR_BADGE(state, feedbackListForBadge) {
            state.feedbackListForBadge = feedbackListForBadge;
        },
        SET_UNSUBSCRIBE_GET_FEEDBACK_LIST_FOR_BADGE(state, unsubscribe) {
            state.unsubscribeGetFeedbackListForBadge = unsubscribe;
            state.subscribedFeedbackListForBadge = true
        },
        GO_UNSUBSCRIBE_GET_FEEDBACK_LIST_FOR_BADGE(state) {
            state.unsubscribeGetFeedbackListForBadge();
            state.subscribedFeedbackListForBadge = false
            state.unsubscribeGetFeedbackListForBadge = null
        },
        SET_FEEDBACK_LIST_WITHOUT_CATEGORIE(state, feedbackListWithoutCategorie) {
            state.feedbackListWithoutCategorie = feedbackListWithoutCategorie;
        },
        SET_UNSUBSCRIBE_GET_FEEDBACK_LIST_WITHOUT_CATEGORIE(state, unsubscribe) {
            state.unsubscribeGetFeedbackListWithoutCategorie = unsubscribe;
            state.subscribedFeedbackListWithoutCategorie = true
        },
        GO_UNSUBSCRIBE_GET_FEEDBACK_LIST_WITHOUT_CATEGORIE(state) {
            state.unsubscribeGetFeedbackListWithoutCategorie();
            state.subscribedFeedbackListWithoutCategorie = false
            state.unsubscribeGetFeedbackListWithoutCategorie = null
        },
        SET_FEEDBACK_CATEGORIES_LIST(state, feedbackCategoriesList) {
            state.feedbackCategoriesList = feedbackCategoriesList;
        },
        SET_UNSUBSCRIBE_GET_FEEDBACK_CATEGORIES_LIST(state, unsubscribe) {
            state.unsubscribeGetFeedbackCategoriesList = unsubscribe;
            state.subscribedFeedbackCategoriesList = true
        },
        GO_UNSUBSCRIBE_GET_FEEDBACK_CATEGORIES_LIST(state) {
            state.unsubscribeGetFeedbackCategoriesList();
            state.subscribedFeedbackCategoriesList = false
            state.unsubscribeGetFeedbackCategoriesList = null
        },
        SET_FEEDBACK_STATUS_LIST(state, feedbackStatusList) {
            state.feedbackStatusList = feedbackStatusList;
        },
        SET_UNSUBSCRIBE_GET_FEEDBACK_STATUS_LIST(state, unsubscribe) {
            state.unsubscribeGetFeedbackStatusList = unsubscribe;
            state.subscribedFeedbackStatusList = true
        },
        GO_UNSUBSCRIBE_GET_FEEDBACK_STATUS_LIST(state) {
            state.unsubscribeGetFeedbackStatusList();
            state.subscribedFeedbackStatusList = false
            state.unsubscribeGetFeedbackStatusList = null
        },
        SET_FEEDBACK_EMAIL_LIST(state, feedbackEmailList) {
            state.feedbackEmailList = feedbackEmailList;
        },
        SET_UNSUBSCRIBE_GET_FEEDBACK_EMAIL_LIST(state, unsubscribe) {
            state.unsubscribeGetFeedbackEmailList = unsubscribe;
            state.subscribedFeedbackEmailList = true
        },
        GO_UNSUBSCRIBE_GET_FEEDBACK_EMAIL_LIST(state) {
            state.unsubscribeGetFeedbackEmailList();
            state.subscribedFeedbackEmailList = false
            state.unsubscribeGetFeedbackEmailList = null
        },
    },
    actions: {
        GetFeedback({}, id) {
            return new Promise((resolve, reject) => {
                firebase
                    .firestore()
                    .collection("feedbacks")
                    .doc(id)
                    .get()
                    .then(async(res) => {
                        let feedback = res.data();
                        if (feedback.hasCategorie) {
                            await feedback.categorie.get().then(categorie => { feedback.categorieObj = categorie.data() })
                        }
                        if (feedback.hasStatus) {
                            await feedback.status.get().then(status => { feedback.statusObj = status.data() })
                        }
                        resolve(feedback)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            });

        },
        GetFeedbackList({ commit, getters }) {
            return new Promise(async(resolve, reject) => {
                db.collection("feedbacks").where('hasCategorie', '==', true).orderBy('createdAt', 'desc').get().then((querySnapshot) => {
                    let feedbackList = [];
                    querySnapshot.forEach(async(doc) => {
                        let feedback = doc.data()
                        feedback.id = doc.id
                        if (feedback.hasCategorie) {
                            await feedback.categorie.get().then(categorie => { feedback.categorieObj = categorie.data() })
                        }
                        if (feedback.hasStatus) {
                            await feedback.status.get().then(status => { feedback.statusObj = status.data() })
                        }
                        feedbackList.push(feedback);
                    });
                    commit("SET_FEEDBACK_LIST", feedbackList);
                    resolve(feedbackList);
                }).catch((error) => {
                    reject(error);
                });

                // commit("SET_UNSUBSCRIBE_GET_FEEDBACK_LIST", unsubscribe);
            });
        },
        GetFeedbackListNextPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                commit("GO_UNSUBSCRIBE_GET_FEEDBACK_LIST");
                let unsubscribe = db.collection("feedbacks").where('hasCategorie', '==', true).orderBy('createdAt', 'desc').startAfter(getters.getFeedbackList[getters.getFeedbackListWithoutCategorie.length - 1].createdAt).limit(itemsPerPage).onSnapshot((querySnapshot) => {
                        let feedbackList = [];
                        querySnapshot.forEach((doc) => {
                            let feedback = doc.data()
                            feedbackList.push(feedback);
                        });
                        if (feedbackList.length > 0) {
                            commit("SET_FEEDBACK_LIST", feedbackList);
                        }
                        resolve(feedbackList);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_GET_FEEDBACK_LIST", unsubscribe);
            });
        },
        GetFeedbackListPrevPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                commit("GO_UNSUBSCRIBE_GET_FEEDBACK_LIST");
                let unsubscribe = db.collection("feedbacks").where('hasCategorie', '==', true).orderBy('createdAt', 'desc').endBefore(getters.getFeedbackList[0].createdAt).limitToLast(itemsPerPage).onSnapshot((querySnapshot) => {
                        let feedbackList = [];
                        querySnapshot.forEach((doc) => {
                            let feedback = doc.data()
                            feedbackList.push(feedback);
                        });
                        commit("SET_FEEDBACK_LIST", feedbackList);
                        resolve(feedbackList);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_GET_FEEDBACK_LIST", unsubscribe);
            });
        },
        GetFeedbackListWithoutCategoriePaginatedFirstPage({ commit }) {
            return new Promise(async(resolve, reject) => {
                // if(getters.isSubscribedFeedbackListWithoutCategorie){
                //   commit("GO_UNSUBSCRIBE_GET_FEEDBACK_LIST_WITHOUT_CATEGORIE");
                // }
                db.collection("feedbacks").where('hasCategorie', '==', false).orderBy('createdAt', 'desc').get().then((querySnapshot) => {
                        let feedbackListWithoutCategorie = [];
                        querySnapshot.forEach((doc) => {
                            let feedback = doc.data()
                            feedback.id = doc.id
                            feedbackListWithoutCategorie.push(feedback);
                        });
                        commit("SET_FEEDBACK_LIST_WITHOUT_CATEGORIE", feedbackListWithoutCategorie);
                        resolve(feedbackListWithoutCategorie);
                    }).catch((error) => {
                        reject(error);
                    })
                    // commit("SET_UNSUBSCRIBE_GET_FEEDBACK_LIST_WITHOUT_CATEGORIE", unsubscribe);
            })
        },
        GetFeedbackListWithoutCategorieNextPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                commit("GO_UNSUBSCRIBE_GET_FEEDBACK_LIST_WITHOUT_CATEGORIE");
                let unsubscribe = db.collection("feedbacks").where('hasCategorie', '==', false).orderBy('createdAt', 'desc').startAfter(getters.getFeedbackListWithoutCategorie[getters.getFeedbackListWithoutCategorie.length - 1].createdAt).limit(itemsPerPage).onSnapshot((querySnapshot) => {
                        let feedbackListWithoutCategorie = [];
                        querySnapshot.forEach((doc) => {
                            let feedback = doc.data()
                            feedbackListWithoutCategorie.push(feedback);
                        });
                        if (feedbackListWithoutCategorie.length > 0) {
                            commit("SET_FEEDBACK_LIST_WITHOUT_CATEGORIE", feedbackListWithoutCategorie);
                        }
                        resolve(feedbackListWithoutCategorie);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_GET_FEEDBACK_LIST_WITHOUT_CATEGORIE", unsubscribe);
            });
        },
        GetFeedbackListWithoutCategoriePrevPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                commit("GO_UNSUBSCRIBE_GET_FEEDBACK_LIST_WITHOUT_CATEGORIE");
                let unsubscribe = db.collection("feedbacks").where('hasCategorie', '==', false).orderBy('createdAt', 'desc').endBefore(getters.getFeedbackListWithoutCategorie[0].createdAt).limitToLast(itemsPerPage).onSnapshot((querySnapshot) => {
                        let feedbackListWithoutCategorie = [];
                        querySnapshot.forEach((doc) => {
                            let feedback = doc.data()
                            feedbackListWithoutCategorie.push(feedback);
                        });
                        commit("SET_FEEDBACK_LIST_WITHOUT_CATEGORIE", feedbackListWithoutCategorie);
                        resolve(feedbackListWithoutCategorie);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_GET_FEEDBACK_LIST_WITHOUT_CATEGORIE", unsubscribe);
            });
        },
        GetFeedbackListWithoutCategoriePaginatedFirstPageForBadge({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                if (getters.getFeedbackList.length > 0 && getters.isSubscribedFeedbackListForBadge) {
                    commit("GO_UNSUBSCRIBE_GET_FEEDBACK_LIST_FOR_BADGE");
                }
                let unsubscribe = db.collection("feedbacks").where('hasCategorie', '==', false).orderBy('createdAt', 'desc').limit(itemsPerPage).onSnapshot((querySnapshot) => {
                        let feedbackListForBadge = [];
                        querySnapshot.forEach((doc) => {
                            let feedback = doc.data()
                            feedback.id = doc.id
                            feedbackListForBadge.push(feedback);
                        });
                        commit("SET_FEEDBACK_LIST_FOR_BADGE", feedbackListForBadge);
                        resolve(feedbackListForBadge);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_GET_FEEDBACK_LIST_FOR_BADGE", unsubscribe);
            });
        },
        GetFeedbackNotifications({ commit }) {
            return new Promise(async(resolve, reject) => {
                const createFunctionCounter = (handlerFunction, invokeBeforeExecution) => {
                    let count = 0;
                    return (snapshot) => {
                        count++;
                        if (count <= invokeBeforeExecution) {
                            return null;
                        }
                        return handlerFunction(snapshot, count);
                    }
                }
                const handleActivitySubscription = (querySnapshot, counter) => {
                    const initialLoad = counter === 1
                    let feedbackListNotifications = [];
                    querySnapshot.docChanges().forEach(async(change) => {
                        let feedback = change.doc.data()
                        feedback.id = change.doc.id
                        feedback.createdAt = moment(feedback.createdAt.toDate()).fromNow()
                        feedbackListNotifications.push(feedback);
                        if (!initialLoad) {
                            if (change.type === "added") {
                                commit('ADD_FEEDBACK_NOTIFICATION', feedback)
                            }
                        }
                    });
                    resolve(feedbackListNotifications);
                }
                const handleActivitySubscriptionWithCounter = createFunctionCounter(handleActivitySubscription, 0);
                let unsubscribe = db.collection("feedbacks").orderBy('createdAt').startAt(new Date()).onSnapshot(handleActivitySubscriptionWithCounter,
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_GET_FEEDBACK_NOTIFICATIONS", unsubscribe);
            })
        },
        ChangeCategorieOrStatusFeedback({}, data) {
            return new Promise(async(resolve, reject) => {
                if (data.change.categorie) {
                    data.change.categorie = db.collection('feedbacksCategories').doc(data.change.categorie)
                } else if (data.change.status) {
                    data.change.status = db.collection('feedbacksStatus').doc(data.change.status)
                }
                console.log(data);
                db.collection("feedbacks")
                    .doc(data.feedback)
                    .update(
                        data.change
                    )
                    .then((res) => {
                        resolve(data.change)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })

        },
        ResolveFeedback({}, id) {
            return new Promise(async(resolve, reject) => {
                db.collection("feedbacks")
                    .doc(id)
                    .update({
                        readed: true
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        GetFeedbackCategoriesList({ commit }) {
            return new Promise(async(resolve, reject) => {
                let unsubscribe = db.collection("feedbacksCategories").onSnapshot((querySnapshot) => {
                        let feedbackCategoriesList = [];
                        querySnapshot.forEach(async(doc) => {
                            let feedbackCategorie = doc.data()
                            feedbackCategoriesList.push(feedbackCategorie);
                        });
                        commit("SET_FEEDBACK_CATEGORIES_LIST", feedbackCategoriesList);
                        resolve(feedbackCategoriesList);
                    },
                    (error) => {
                        reject(error);
                    });
                commit("SET_UNSUBSCRIBE_GET_FEEDBACK_CATEGORIES_LIST", unsubscribe);
            });
        },
        async CreateFeedbackCategorie({}, feedbackCategorie) {
            return new Promise(async(resolve, reject) => {
                const ref = db.collection("feedbacksCategories").doc();
                const newFeedbackCategorie = await ref.get();
                feedbackCategorie.id = ref.id;
                await db.collection("feedbacksCategories")
                    .doc(newFeedbackCategorie.id)
                    .set(feedbackCategorie)
                    .then((res) => {
                        resolve(newFeedbackCategorie.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },
        GetFeedbackStatusList({ commit }) {
            return new Promise(async(resolve, reject) => {
                let unsubscribe = db.collection("feedbacksStatus").onSnapshot((querySnapshot) => {
                        let feedbackStatusList = [];
                        querySnapshot.forEach(async(doc) => {
                            let feedbackStatus = doc.data()
                            feedbackStatusList.push(feedbackStatus);
                        });
                        commit("SET_FEEDBACK_STATUS_LIST", feedbackStatusList);
                        resolve(feedbackStatusList);
                    },
                    (error) => {
                        reject(error);
                    });
                commit("SET_UNSUBSCRIBE_GET_FEEDBACK_STATUS_LIST", unsubscribe);
            });
        },
        async CreateFeedbackStatus({}, feedbackStatus) {
            return new Promise(async(resolve, reject) => {
                const ref = db.collection("feedbacksStatus").doc();
                const newFeedbackStatus = await ref.get();
                feedbackStatus.id = ref.id;
                await db.collection("feedbacksStatus")
                    .doc(newFeedbackStatus.id)
                    .set(feedbackStatus)
                    .then((res) => {
                        resolve(newFeedbackStatus.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },
        DeleteCategorieOrStatus({}, data) {
            return new Promise(async(resolve, reject) => {
                await db.collection(data.collection)
                    .doc(data.id)
                    .delete()
                    .then((res) => {
                        resolve(data.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        GetFeedbackEmailList({ commit }) {
            return new Promise(async(resolve, reject) => {
                let unsubscribe = db.collection("feedbacksEmails").onSnapshot((querySnapshot) => {
                        let feedbackEmailList = [];
                        querySnapshot.forEach(async(doc) => {
                            let feedbackEmail = doc.data()
                            feedbackEmailList.push(feedbackEmail);
                        });
                        commit("SET_FEEDBACK_EMAIL_LIST", feedbackEmailList);
                        resolve(feedbackEmailList);
                    },
                    (error) => {
                        reject(error);
                    });
                commit("SET_UNSUBSCRIBE_GET_FEEDBACK_EMAIL_LIST", unsubscribe);
            });
        },
        async AddFeedbackEmail({}, feedbackEmail) {
            return new Promise(async(resolve, reject) => {
                const ref = db.collection("feedbacksEmails").doc();
                const newFeedbackEmail = await ref.get();
                feedbackEmail.id = ref.id;
                await db.collection("feedbacksEmails")
                    .doc(newFeedbackEmail.id)
                    .set(feedbackEmail)
                    .then((res) => {
                        resolve(newFeedbackEmail.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },
        DeleteEmailFromListFeedbacks({}, data) {
            return new Promise(async(resolve, reject) => {
                await db.collection("feedbacksEmails")
                    .doc(data.id)
                    .delete()
                    .then((res) => {
                        resolve(data.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        GetFromEmailDefaultFeedback({}) {
            return new Promise((resolve, reject) => {
                firebase
                    .firestore()
                    .collection("feedbacksFromDefaultEmail")
                    .doc('UsslbyYzy9Y9OYMsztWr')
                    .get()
                    .then(async(res) => {
                        let feedback = res.data();
                        resolve(feedback)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            });

        },
        UpdateFromDefaultEmailFeedback({}, payload) {
            return new Promise((resolve, reject) => {
                Vue.axios
                    .post(requestEndPoint.saveFromAddressFeedback, payload)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        },
        GoUnsuscribeGetFeedbackListWithoutCategorie({ commit }) {
            commit("GO_UNSUBSCRIBE_GET_FEEDBACK_LIST_WITHOUT_CATEGORIE");
        },
        GoUnsuscribeGetFeedbackList({ commit }) {
            commit("GO_UNSUBSCRIBE_GET_FEEDBACK_LIST");
        },
        GoUnsuscribeGetFeedbackListForBadge({ commit }) {
            commit("GO_UNSUBSCRIBE_GET_FEEDBACK_LIST_FOR_BADGE");
        },
        GoUnsuscribeGetFeedbackNotifications({ commit }) {
            commit("GO_UNSUBSCRIBE_GET_FEEDBACK_NOTIFICATIONS");
        },
        GoUnsuscribeGetFeedbackCategoriesList({ commit }) {
            commit("GO_UNSUBSCRIBE_GET_FEEDBACK_CATEGORIES_LIST");
        },
        GoUnsuscribeGetFeedbackStatusList({ commit }) {
            commit("GO_UNSUBSCRIBE_GET_FEEDBACK_STATUS_LIST");
        },
        GoUnsuscribeGetFeedbackEmailList({ commit }) {
            commit("GO_UNSUBSCRIBE_GET_FEEDBACK_EMAIL_LIST");
        },
    },
    getters: {
        getFeedbackList(state) {
            return state.feedbackList;
        },
        isSubscribedFeedbackList(state) {
            return state.subscribedFeedbackList;
        },
        getFeedbackListWithoutCategorie(state) {
            return state.feedbackListWithoutCategorie;
        },
        isSubscribedFeedbackListWithoutCategorie(state) {
            return state.subscribedFeedbackListWithoutCategorie;
        },
        getFeedbackNotifications(state) {
            return state.feedbackNotifications;
        },
        isSubscribedFeedbackNotifications(state) {
            return state.subscribedFeedbackNotifications;
        },
        getFeedbackCategoriesList(state) {
            return state.feedbackCategoriesList
        },
        isSubscribedFeedbackCategoriesList(state) {
            return state.subscribedFeedbackCategoriesList
        },
        getFeedbackStatusList(state) {
            return state.feedbackStatusList
        },
        isSubscribedFeedbackStatusList(state) {
            return state.subscribedFeedbackStatusList
        },
        getFeedbackEmailList(state) {
            return state.feedbackEmailList
        },
        isSubscribedFeedbackEmailList(state) {
            return state.subscribedFeedbackEmailList
        },
        getCountFeedbackWithoutCategorieForBadge(state) {
            if (state && state.feedbackListForBadge && state.feedbackListForBadge.length <= 10) {
                return state.feedbackListForBadge.length
            } else {
                return '+10'
            }
        },
        isSubscribedFeedbackListForBadge(state) {
            return state.subscribedFeedbackListForBadge;
        },
    },
};