<template>
  <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff"
  >
    <v-form ref="formPreSet" v-model="validPreSet">
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-btn
            color="primary"
            icon
            size="20"
            class="text-center"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h3 class="secondary--text d-inline mr-2">Create Pre-Set</h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="black" dark v-bind="attrs" v-on="on" size="32"
                >mdi-translate</v-icon
              >
            </template>
            <span
              >Here you can choose the language of the exercise to translate
              it</span
            >
          </v-tooltip>
          <v-menu class="text-center">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon class="elevation-3">
                <v-avatar size="32">
                  <v-img :src="flagActualTranslate"></v-img>
                </v-avatar>
              </v-btn>
            </template>
            <v-list v-if="listLanguages">
              <v-list-item
                v-for="(lang, index) in listLanguages"
                :key="index"
                @click="changeLanguage(lang)"
              >
                <v-list-item-action>
                  <v-avatar size="32" class="elevation-3">
                    <v-img :src="lang.flag"></v-img>
                  </v-avatar>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="mx-auto text-left">
          <p class="secondary--text pb-1" style="font-size: 14px">Name</p>
          <v-text-field
            v-model="preSet.name[actualTranslateLang]"
            :rules="rulesPreSet.name"
            solo
            flat
            counter
            background-color="#efefef"
          ></v-text-field>
          <!-- <div class="mx-auto text-center" style="width:250px">
            <p class="secondary--text mt-5 text-left" style="font-size: 14px">End Day</p>
              <v-datetime-picker 
              v-model="preSet.endDay"
              :text-field-props=" { backgroundColor: '#efefef', solo: true, flat: true }"
              :date-picker-props="{ color: 'primary' }"
              :time-picker-props="{ color: 'primary' }"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template slot="actions" slot-scope="{ parent }">
                    <v-btn color="secondary" text @click.native="parent.clearHandler">Cancel</v-btn>
                    <v-btn color="primary" @click="parent.okHandler">Save</v-btn>
                  </template>
             </v-datetime-picker> 
            </div>   -->
          <!-- <p class="secondary--text pb-1 text-center" style="font-size: 14px">Disable on end day?</p>
            <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="preSet.disabledOnEndDay"
                :input-value="preSet.disabledOnEndDay"
                flat
            ></v-switch> -->
          <!-- <div class="mx-auto text-center" style="width:250px">
                <p class="secondary--text pb-1" style="font-size: 14px">Subscription</p>
                 <v-select
                  v-model="preSet.subscription"
                  :items="getSubscriptionsList"
                  item-text="name.ENG"
                  return-object
                  solo
                  flat
                  background-color="#efefef"
                ></v-select>
             </div> -->
          <div class="mx-auto text-center" style="width: 150px">
            <p class="secondary--text pb-1" style="font-size: 14px">
              Difficulty
            </p>
            <v-select
              v-model="preSet.difficulty"
              :items="listDifficulty"
              item-text="value"
              item-value="value"
              solo
              flat
              background-color="#efefef"
            ></v-select>
          </div>
          <!-- <div class="mx-auto text-center" style="width:150px">
              <p class="secondary--text text-center pb-1">Order number</p>
              <v-text-field
                  v-model.number="preSet.orderNumber"
                  :rules="rulesPreSet.orderNumber"
                  @keypress="numberValid($event)"
                  maxlength="4"
                  class="centered-input"
                  solo
                  flat
                  background-color="#efefef"
              ></v-text-field>
            </div> -->
          <v-expansion-panels accordion flat>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="secondary--text"
                style="border-bottom: #efeeee 2px solid"
                >Workout Values</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row justify="center">
                  <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">
                      Circuit Begginer
                    </p>
                    <v-select
                      v-model="preSet.circuitBegginer"
                      :items="listCircuit"
                      item-text="value"
                      item-value="value"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">
                      Circuit Intermediate
                    </p>
                    <v-select
                      v-model="preSet.circuitIntermediate"
                      :items="listCircuit"
                      item-text="value"
                      item-value="value"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">
                      Circuit Advanced
                    </p>
                    <v-select
                      v-model="preSet.circuitAdvanced"
                      :items="listCircuit"
                      item-text="value"
                      item-value="value"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">
                      Est. Cal Begginer
                    </p>
                    <v-text-field
                      v-model.number="preSet.estCalBegginer"
                      :rules="rulesPreSet.estCalBegginer"
                      @keypress="numberValid($event)"
                      maxlength="4"
                      class="centered-input"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">
                      Est. Cal Intermediate
                    </p>
                    <v-text-field
                      v-model.number="preSet.estCalIntermediate"
                      :rules="rulesPreSet.estCalIntermediate"
                      @keypress="numberValid($event)"
                      maxlength="4"
                      class="centered-input"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">
                      Est. Cal Advanced
                    </p>
                    <v-text-field
                      v-model.number="preSet.estCalAdvanced"
                      :rules="rulesPreSet.estCalAdvanced"
                      @keypress="numberValid($event)"
                      maxlength="4"
                      class="centered-input"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="start">
                  <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">
                      Est. Time Begginer
                    </p>
                    <v-text-field
                      v-model.number="preSet.estTimeBegginer"
                      :rules="rulesPreSet.estTimeBegginer"
                      @keypress="numberValid($event)"
                      maxlength="5"
                      class="centered-input"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">
                      Est. Time Intermediate
                    </p>
                    <v-text-field
                      v-model.number="preSet.estTimeIntermediate"
                      :rules="rulesPreSet.estTimeIntermediate"
                      @keypress="numberValid($event)"
                      maxlength="5"
                      class="centered-input"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">
                      Est. Time Advanced
                    </p>
                    <v-text-field
                      v-model.number="preSet.estTimeAdvanced"
                      :rules="rulesPreSet.estTimeAdvanced"
                      @keypress="numberValid($event)"
                      maxlength="5"
                      class="centered-input"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">
                      Default Rest Time
                    </p>
                    <v-text-field
                      v-model.number="preSet.defaultRestTime"
                      :rules="rulesPreSet.defaultRestTime"
                      @keypress="numberValid($event)"
                      maxlength="2"
                      class="centered-input"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">
                      Super Sets
                    </p>
                    <v-select
                      v-model="preSet.superSets"
                      :items="listSuperSet"
                      item-text="value"
                      item-value="value"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" sm="12" md="3" class="mx-auto text-left">
          <p class="secondary--text pb-1" style="font-size: 14px">
            Change image
          </p>
          <v-card
            color="#efefef"
            class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
            min-width="250"
            min-height="250"
            @click="$refs.imagePreSet.$refs.input.click()"
          >
            <v-img
              :src="preSet.image.img ? preSet.image.img : ''"
              cover
              width="250"
              class="rounded-lg"
              height="250"
            >
            </v-img>
            <v-icon
              large
              color="primary"
              style="
                position: absolute;
                transform: translate(50%, -50%);
                top: 50%;
                right: 50%;
              "
            >
              mdi-camera
            </v-icon>
          </v-card>
          <p class="secondary--text mt-4 pb-1" style="font-size: 14px">
            Details
          </p>
          <v-textarea
            v-model="preSet.details[actualTranslateLang]"
            maxlength="500"
            counter="500"
            solo
            flat
            no-resize
            height="250"
            background-color="#efefef"
          >
          </v-textarea>
        </v-col>
        <v-col cols="12" sm="12" md="3" class="mx-auto text-left">
          <p class="secondary--text pb-1" style="font-size: 14px">
            Description
          </p>
          <v-textarea
            v-model="preSet.description[actualTranslateLang]"
            :rules="rulesPreSet.description"
            counter
            solo
            flat
            no-resize
            height="250"
            background-color="#efefef"
          >
          </v-textarea>

          <v-col cols="12" sm="12" md="3" class="mx-auto text-left">
          <v-switch
            color="primary"
            class="d-flex justify-center center-switch ma-0 mt-3"
            v-model="preSet.enabled"
            :input-value="preSet.enabled"
            flat
          >
            <template v-slot:label>
              <p class="secondary--text d-inline mb-0" style="font-size: 14px">
                Enabled
              </p>
            </template>
          </v-switch>
          <v-switch
            color="primary"
            class="d-flex justify-center center-switch ma-0 mt-3"
            v-model="preSet.isFree"
            :input-value="preSet.isFree"
            flat
          >
            <template v-slot:label>
              <p class="secondary--text d-inline mb-0" style="font-size: 14px">
                Free
              </p>
            </template>
          </v-switch>
          <v-switch
            color="primary"
            class="d-flex justify-center center-switch ma-0 mt-3"
            v-model="preSet.isGrandFathered"
            :input-value="preSet.isGrandFathered"
            flat
          >
            <template v-slot:label>
              <p class="secondary--text d-inline mb-0" style="font-size: 14px">
                GrandFathered
              </p>
            </template>
          </v-switch>
        </v-col>
        </v-col>
        
      </v-row>
    </v-form>

    <v-row justify="start" class="py-3" v-if="filtersRendered">
      <v-col cols="12">
        <p class="secondary--text mb-0">Filters Selected</p>
      </v-col>
      <template v-for="(filter, i) in getPreSetsFilters">
        <v-col
          cols="3"
          v-if="
            preSet.filters[getPreSetsFilters[i].type] &&
            preSet.filters[getPreSetsFilters[i].type].length > 0
          "
          :key="getPreSetsFilters[i].type"
        >
          <div
            class="rounded-xl"
            style="border: #efefef solid 2px; height: 100%"
          >
            <p class="secondary--text font-weight-bold pt-1 text-center">
              {{ getPreSetsFilters[i].name["ENG"] }}
            </p>
            <template
              v-for="(principalFilter, j) in preSet.filters[
                getPreSetsFilters[i].type
              ]"
            >
              <v-chip
                v-if="preSet.filters[getPreSetsFilters[i].type][j]"
                :key="preSet.filters[getPreSetsFilters[i].type][j].name"
                color="primary"
                dark
                class="ma-2"
                close
                @click:close="
                  deleteFilterChipParent(
                    getPreSetsFilters[i].type,
                    preSet.filters[getPreSetsFilters[i].type][j]
                  )
                "
              >
                {{ preSet.filters[getPreSetsFilters[i].type][j].name }}
              </v-chip>
              <template
                v-if="
                  preSet.filters[getPreSetsFilters[i].type][j].children &&
                  preSet.filters[getPreSetsFilters[i].type][j].children.length >
                    0
                "
              >
                <template
                  v-for="(childrenFilter, k) in preSet.filters[
                    getPreSetsFilters[i].type
                  ][j].children"
                >
                  <v-chip
                    v-if="
                      preSet.filters[getPreSetsFilters[i].type][j].children[k]
                    "
                    :key="
                      preSet.filters[getPreSetsFilters[i].type][j].children[k]
                        .name
                    "
                    color="secondary"
                    dark
                    class="ma-2"
                    close
                    @click:close="
                      deleteFilterChipChild(
                        getPreSetsFilters[i].type,
                        preSet.filters[getPreSetsFilters[i].type][j],
                        preSet.filters[getPreSetsFilters[i].type][j].children[k]
                      )
                    "
                  >
                    {{
                      preSet.filters[getPreSetsFilters[i].type][j].children[k]
                        .name
                    }}
                  </v-chip>

                  <template
                    v-if="
                      preSet.filters[getPreSetsFilters[i].type][j].children[
                        k
                      ] &&
                      preSet.filters[getPreSetsFilters[i].type][j].children[k]
                        .children &&
                      preSet.filters[getPreSetsFilters[i].type][j].children[k]
                        .children.length > 0
                    "
                  >
                    <template
                      v-for="(thirdLevel, x) in preSet.filters[
                        getPreSetsFilters[i].type
                      ][j].children[k].children"
                    >
                      <!--fix click:close --><v-chip
                        v-if="
                          preSet.filters[getPreSetsFilters[i].type][j].children[
                            k
                          ].children[x]
                        "
                        :key="
                          preSet.filters[getPreSetsFilters[i].type][j].children[
                            k
                          ].children[x].name
                        "
                        color="black"
                        dark
                        class="ma-2"
                        close
                        @click:close="
                          deleteFilterChipChild(
                            getPreSetsFilters[i].type,
                            preSet.filters[getPreSetsFilters[i].type][j],
                            preSet.filters[getPreSetsFilters[i].type][j]
                              .children[k]
                          )
                        "
                      >
                        {{
                          preSet.filters[getPreSetsFilters[i].type][j].children[
                            k
                          ].children[x].name
                        }}
                      </v-chip>
                    </template>
                  </template>
                </template>
              </template>
            </template>
          </div>
        </v-col>
      </template>
    </v-row>

    <v-row justify="start" class="py-3">
      <v-expansion-panels tile>
        <v-expansion-panel>
          <v-expansion-panel-header class="primary--text font-weight-bold">
            Filters
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="start" v-if="filtersRendered">
              <v-col cols="3" v-for="(filter, i) in getPreSetsFilters" :key="i">
                <p class="secondary--text font-weight-bold pb-1 text-center">
                  {{ filter.name["ENG"] }}
                </p>
                <v-treeview
                  v-model="filtersSelected[filter.type]"
                  selected-color="primary"
                  :items="filter.listFilters"
                  :selection-type="'leaf'"
                  :item-key="'name'"
                  selectable
                  return-object
                  open-all
                  @input="getUpdateTreeview($event, filter.type)"
                ></v-treeview>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row justify="start" align="center">
      <v-col cols="12" sm="12" md="3">
        <v-select
          v-model="preSet.typeBy"
          :items="listTypeBy"
          item-text="name"
          item-value="value"
          solo
          flat
          background-color="#efefef"
          @change="changeTypeBy"
        ></v-select>
      </v-col>
      <v-spacer />
      <v-col cols="12" sm="12" md="6">
        <v-btn
          color="primary"
          class="float-right"
          :disabled="
            !validPreSet ||
            !preSet.image.img ||
            (preSet.typeBy === 'circuit' && preSet.circuits.length <= 0)
          "
          @click="uploadImage()"
          >Finish</v-btn
        >
      </v-col>
    </v-row>
    <template v-if="preSet.typeBy === 'circuit'">
      <v-row justify="start" v-if="preSet.circuits.length > 0">
        <v-col cols="12" sm="12" md="12">
          <p class="secondary--text mb-1">Circuits</p>
          <hr />
        </v-col>
      </v-row>
      <template v-if="preSet.circuits.length > 0">
        <v-row
          justify="start"
          v-for="(circuit, index) in preSet.circuits"
          :key="index"
        >
          <v-col cols="12" sm="12" md="3" align-self="center">
            <v-btn
              text
              color="secondary"
              @click="openModalAddOrEditExercise(true, 'circuit', index)"
            >
              <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
              Add Exercise to Circuit {{ index + 1 }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="4" class="d-flex align-center">
            <p
              class="secondary--text mb-1 text-center pr-2"
              style="font-size: 14px"
            >
              Name
            </p>
            <v-text-field
              v-model.number="preSet.circuits[index].name[actualTranslateLang]"
              hide-details
              solo
              flat
              counter
              background-color="#efefef"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-center">
            <p
              class="secondary--text mb-1 text-center pr-2"
              style="font-size: 14px"
            >
              Rest Time
            </p>
            <v-text-field
              v-model.number="preSet.circuits[index].restTime"
              @keypress="numberValid($event)"
              maxlength="3"
              hide-details
              class="centered-input"
              solo
              flat
              background-color="#efefef"
            ></v-text-field>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" sm="12" md="2" class="text-right">
            <v-btn fab x-small color="red" @click="deleteCircuit(index)">
              <v-icon color="white"> mdi-delete </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headersExercises"
              :items="circuit.exercises"
              :items-per-page="5"
              fixed-header
              class="elevation-4"
            >
              <template v-slot:body="props">
                <draggable
                  v-if="props"
                  :list="preSet.circuits[index].exercises"
                  tag="tbody"
                >
                  <tr
                    v-for="(circuitData, indexDrag) in preSet.circuits[index]
                      .exercises"
                    :key="indexDrag"
                  >
                    <td>
                      <v-icon small class="page__grab-icon">
                        mdi-arrow-all
                      </v-icon>
                    </td>
                    <td class="text-center">
                      {{ preSet.circuits[index].exercises[indexDrag].sets }}
                    </td>
                    <td
                      v-if="
                        preSet.circuits[index].exercises[indexDrag].exercise
                          .name
                      "
                    >
                      {{
                        preSet.circuits[index].exercises[indexDrag].exercise
                          .name["ENG"]
                      }}
                    </td>
                    <td>
                      {{
                        preSet.circuits[index].exercises[indexDrag]
                          .typeCounter === "byReps"
                          ? "By Reps"
                          : "By Time"
                      }}
                    </td>
                    <!-- <td> 
                    <template v-if="preSet.circuits[index].exercises[indexDrag].typeCounter === 'byTime'">
                      {{ preSet.circuits[index].exercises[indexDrag].time.minutes }} Minutes {{ preSet.circuits[index].exercises[indexDrag].time.seconds }} seconds
                    </template>
                    <template v-if="preSet.circuits[index].exercises[indexDrag].typeCounter === 'byReps'">
                      N/A
                    </template>
                  </td>
                  <td> {{ preSet.circuits[index].exercises[indexDrag].typeCounter === 'byReps' ? preSet.circuits[index].exercises[indexDrag].reps : 'N/A' }} </td> -->
                    <td class="text-center">
                      {{
                        preSet.circuits[index].exercises[indexDrag]
                          .multiplyFactor
                      }}
                    </td>
                    <td class="text-center">
                      {{ preSet.circuits[index].exercises[indexDrag].restTime }}
                    </td>
                    <td class="text-center">
                      <h4
                        v-if="
                          preSet.circuits[index].exercises[indexDrag].autoInit
                        "
                        class="primary--text"
                      >
                        Yes
                      </h4>
                      <template v-else>
                        <h4 class="secondary--text">No</h4>
                      </template>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="primary"
                        size="20"
                        small
                        @click="
                          openModalAddOrEditExercise(
                            false,
                            'circuit',
                            index,
                            preSet.circuits[index].exercises[indexDrag],
                            indexDrag
                          )
                        "
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                      <v-btn
                        icon
                        color="red"
                        size="20"
                        small
                        @click="deleteSection(index, indexDrag, true)"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </draggable>
              </template>
              <!-- <template v-slot:[`item.typeCounter`]="{ item }">
                {{ item.typeCounter === 'byReps' ? 'By Reps' : 'By Time'}}
            </template>
            <template v-slot:[`item.time`]="{ item }">
              <template v-if="item.typeCounter === 'byTime'">
                {{ item.time.minutes }} Minutes {{ item.time.seconds }} seconds
              </template>
              <template v-if="item.typeCounter === 'byReps'">
                N/A
              </template>
              
            </template>
            <template v-slot:[`item.reps`]="{ item }">
              {{ item.typeCounter === 'byReps' ? item.reps : 'N/A'}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                icon
                color="primary"
                size="20"
                small
                @click="openModalAddOrEditExercise(false, 'circuit', index, item, circuit.exercises.indexOf(item))"
                ><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn
                icon
                color="red"
                size="20"
                small
                @click="deleteSection(index, circuit.exercises.indexOf(item))"
                ><v-icon>mdi-delete</v-icon></v-btn
                >
            </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </template>
      <v-row justify="center">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              class="mx-auto my-3"
              fab
              dark
              small
              @click="addNewCircuit()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Circuit</span>
        </v-tooltip>
      </v-row>
    </template>
    <template v-if="preSet.typeBy === 'station'">
      <v-row justify="start">
        <v-col cols="12" sm="12" md="12">
          <p class="secondary--text mb-1">Station</p>
          <hr />
        </v-col>
      </v-row>
      <template>
        <v-row justify="start">
          <v-col cols="12" sm="12" md="3" align-self="center">
            <v-btn
              text
              color="secondary"
              @click="openModalAddOrEditExercise(true, 'station')"
            >
              <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
              Add Exercise to Station
            </v-btn>
          </v-col>
          <v-col cols="12" md="4" class="d-flex align-center">
            <p
              class="secondary--text mb-1 text-center pr-2"
              style="font-size: 14px"
            >
              Name
            </p>
            <v-text-field
              v-model.number="preSet.station.name[actualTranslateLang]"
              hide-details
              solo
              flat
              counter
              background-color="#efefef"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-center">
            <p
              class="secondary--text mb-1 text-center pr-2"
              style="font-size: 14px"
            >
              Rest Time
            </p>
            <v-text-field
              v-model.number="preSet.station.restTime"
              @keypress="numberValid($event)"
              maxlength="3"
              hide-details
              class="centered-input"
              solo
              flat
              background-color="#efefef"
            ></v-text-field>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headersExercises"
              :items="preSet.station.exercises"
              :items-per-page="5"
              fixed-header
              class="elevation-4"
            >
              <template v-slot:body="props">
                <draggable
                  v-if="props"
                  :list="preSet.station.exercises"
                  tag="tbody"
                >
                  <tr
                    v-for="(stationData, indexDrag) in preSet.station.exercises"
                    :key="indexDrag"
                  >
                    <td>
                      <v-icon small class="page__grab-icon">
                        mdi-arrow-all
                      </v-icon>
                    </td>
                    <td class="text-center">
                      {{ preSet.station.exercises[indexDrag].sets }}
                    </td>
                    <td
                      v-if="preSet.station.exercises[indexDrag].exercise.name"
                    >
                      {{
                        preSet.station.exercises[indexDrag].exercise.name["ENG"]
                      }}
                    </td>
                    <td>
                      {{
                        preSet.station.exercises[indexDrag].typeCounter ===
                        "byReps"
                          ? "By Reps"
                          : "By Time"
                      }}
                    </td>
                    <!-- <td> 
                      <template v-if="preSet.station.exercises[indexDrag].typeCounter === 'byTime'">
                        {{ preSet.station.exercises[indexDrag].time.minutes }} Minutes {{ preSet.station.exercises[indexDrag].time.seconds }} seconds
                      </template>
                      <template v-if="preSet.station.exercises[indexDrag].typeCounter === 'byReps'">
                        N/A
                      </template>
                    </td>
                    <td> {{ preSet.station.exercises[indexDrag].typeCounter === 'byReps' ? preSet.station.exercises[indexDrag].reps : 'N/A' }} </td> -->
                    <td class="text-center">
                      {{ preSet.station.exercises[indexDrag].multiplyFactor }}
                    </td>
                    <td class="text-center">
                      {{ preSet.station.exercises[indexDrag].restTime }}
                    </td>
                    <td class="text-center">
                      <h4
                        v-if="preSet.station.exercises[indexDrag].autoInit"
                        class="primary--text"
                      >
                        Yes
                      </h4>
                      <template v-else>
                        <h4 class="secondary--text">No</h4>
                      </template>
                    </td>
                    <td>
                      <v-btn
                        icon
                        color="primary"
                        size="20"
                        small
                        @click="
                          openModalAddOrEditExercise(
                            false,
                            'station',
                            null,
                            preSet.station.exercises[indexDrag],
                            indexDrag
                          )
                        "
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                      <v-btn
                        icon
                        color="red"
                        size="20"
                        small
                        @click="deleteSection(null, indexDrag, false)"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </draggable>
              </template>
              <!-- <template v-slot:[`item.typeCounter`]="{ item }">
                {{ item.typeCounter === 'byReps' ? 'By Reps' : 'By Time'}}
            </template>
            <template v-slot:[`item.time`]="{ item }">
              <template v-if="item.typeCounter === 'byTime'">
                {{ item.time.minutes }} Minutes {{ item.time.seconds }} seconds
              </template>
              <template v-if="item.typeCounter === 'byReps'">
                N/A
              </template>
              
            </template>
            <template v-slot:[`item.reps`]="{ item }">
              {{ item.typeCounter === 'byReps' ? item.reps : 'N/A'}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                icon
                color="primary"
                size="20"
                small
                @click="openModalAddOrEditExercise(false, 'station', null, item, preSet.station.exercises.indexOf(item))"
                ><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn
                icon
                color="red"
                size="20"
                small
                @click="deleteSection(null, preSet.station.exercises.indexOf(item))"
                ><v-icon>mdi-delete</v-icon></v-btn
                >
            </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </template>
    <v-dialog
      v-model="dialogAddOrEditExercise.show"
      width="700"
      class="ma-0"
      persistent
    >
      <v-card style="overflow: hidden !important">
        <v-card-actions class="pa-0">
          <v-btn
            icon
            color="red"
            class="ml-3 mt-2"
            @click="closeModalAddOrEditExercise()"
          >
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-form ref="formAddExercise" v-model="validAddExercise">
          <v-card class="elevation-0" v-if="dialogAddOrEditExercise.isAdd">
            <v-container class="pa-0">
              <v-row justify="center">
                <v-col cols="12" md="11">
                  <p class="secondary--text pl-2">Exercise</p>
                  <v-autocomplete
                    v-model="dialogAddOrEditExercise.add.selectedExercise"
                    :rules="rulesDialogAddOrEditExercise.selectedExercise"
                    :items="exercisesList"
                    solo
                    flat
                    background-color="#efefef"
                    hide-selected
                    item-text="name.ENG"
                    :item-value="
                      dialogAddOrEditExercise.add.selectedExercise.id
                    "
                    placeholder="Search excercise by name here"
                    @keydown.enter.stop.prevent
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row justify="center" class="mt-0">
                <v-col cols="12" md="3">
                  <p class="secondary--text text-center">Auto-Init</p>
                  <v-switch
                    v-if="dialogAddOrEditExercise.add"
                    color="primary"
                    class="d-flex justify-center center-switch ma-0 mt-3"
                    v-model="dialogAddOrEditExercise.add.autoInit"
                    :input-value="dialogAddOrEditExercise.add.autoInit"
                    hide-details
                    flat
                  >
                  </v-switch>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="3">
                  <p class="secondary--text pl-2 text-center">
                    Multiply Factor
                  </p>
                  <v-text-field
                    v-if="dialogAddOrEditExercise.add"
                    v-model="dialogAddOrEditExercise.add.multiplyFactor"
                    :rules="rulesDialogAddOrEditExercise.multiplyFactor"
                    @keypress="numberFloatValid($event)"
                    maxlength="3"
                    class="centered-input"
                    solo
                    flat
                    background-color="#efefef"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <p class="secondary--text pl-2 text-center">Sets</p>
                  <v-text-field
                    v-model.number="dialogAddOrEditExercise.add.sets"
                    :rules="rulesDialogAddOrEditExercise.sets"
                    @keypress="numberValid($event)"
                    @input="renderRepsOrTimeInputs(true)"
                    maxlength="3"
                    class="centered-input"
                    solo
                    flat
                    background-color="#efefef"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <p class="secondary--text pl-2 text-center">Rest Time</p>
                  <v-text-field
                    v-model.number="dialogAddOrEditExercise.add.restTime"
                    :rules="rulesDialogAddOrEditExercise.restTime"
                    @keypress="numberValid($event)"
                    maxlength="3"
                    class="centered-input"
                    solo
                    flat
                    background-color="#efefef"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="3">
                  <p class="secondary--text pl-2 text-center">Type</p>
                  <v-select
                    v-model="dialogAddOrEditExercise.add.typeCounter"
                    :items="listTypeCounter"
                    item-text="name"
                    item-value="value"
                    solo
                    flat
                    background-color="#efefef"
                    @input="renderRepsOrTimeInputs(true)"
                  ></v-select>
                </v-col>
                <!-- <v-spacer /> -->
                <v-col cols="12" md="6">
                  <template
                    v-if="dialogAddOrEditExercise.add.typeCounter === 'byReps'"
                  >
                    <p class="secondary--text pl-2 text-center">Reps</p>
                    <v-row>
                      <v-col
                        :key="index"
                        cols="6"
                        v-for="(rep, index) in dialogAddOrEditExercise.add
                          .repsList"
                      >
                        <div class="d-flex align-center">
                          <p
                            class="secondary--text text-center mr-3 mb-7"
                            style="white-space: nowrap; font-size: 14px"
                          >
                            Set {{ index + 1 }}
                          </p>
                          <v-text-field
                            v-model="
                              dialogAddOrEditExercise.add.repsList[index].value
                            "
                            :rules="rulesDialogAddOrEditExercise.reps"
                            :key="index"
                            @keypress="numberValid($event)"
                            maxlength="3"
                            class="centered-input"
                            solo
                            flat
                            background-color="#efefef"
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                  <template
                    v-if="dialogAddOrEditExercise.add.typeCounter === 'byTime'"
                  >
                    <p class="secondary--text pl-2 text-center">Time</p>
                    <v-row>
                      <v-col
                        :key="index"
                        cols="6"
                        v-for="(rep, index) in dialogAddOrEditExercise.add
                          .timeList"
                      >
                        <div class="d-flex align-center">
                          <p
                            class="secondary--text text-center mr-3 mb-7"
                            style="white-space: nowrap; font-size: 14px"
                          >
                            Set {{ index + 1 }}
                          </p>
                          <v-text-field
                            v-model="
                              dialogAddOrEditExercise.add.timeList[index].value
                            "
                            :rules="rulesDialogAddOrEditExercise.time"
                            :key="index"
                            maxlength="5"
                            class="centered-input"
                            solo
                            flat
                            background-color="#efefef"
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <template v-else>
            <v-card class="elevation-0">
              <v-container class="pa-0">
                <v-row justify="center">
                  <v-col cols="12" md="11">
                    <p class="secondary--text pl-2">Exercise</p>
                    <v-autocomplete
                      v-model="dialogAddOrEditExercise.edit.selectedExercise"
                      :rules="rulesDialogAddOrEditExercise.selectedExercise"
                      :items="exercisesList"
                      solo
                      flat
                      background-color="#efefef"
                      hide-selected
                      item-text="name.ENG"
                      :item-value="
                        dialogAddOrEditExercise.add.selectedExercise.id
                      "
                      placeholder="Search excercise by name here"
                      @keydown.enter.stop.prevent
                      return-object
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row justify="center" class="mt-0">
                  <v-col cols="12" md="3">
                    <p class="secondary--text text-center">Auto-Init</p>
                    <v-switch
                      v-if="dialogAddOrEditExercise.edit"
                      color="primary"
                      class="d-flex justify-center center-switch ma-0 mt-3"
                      v-model="dialogAddOrEditExercise.edit.autoInit"
                      :input-value="dialogAddOrEditExercise.edit.autoInit"
                      hide-details
                      flat
                    >
                    </v-switch>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" md="3">
                    <p class="secondary--text pl-2 text-center">
                      Multiply Factor
                    </p>
                    <v-text-field
                      v-if="dialogAddOrEditExercise.edit"
                      v-model="dialogAddOrEditExercise.edit.multiplyFactor"
                      :rules="rulesDialogAddOrEditExercise.multiplyFactor"
                      @keypress="numberFloatValid($event)"
                      maxlength="3"
                      class="centered-input"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <p class="secondary--text pl-2 text-center">Sets</p>
                    <v-text-field
                      v-model.number="dialogAddOrEditExercise.edit.sets"
                      :rules="rulesDialogAddOrEditExercise.sets"
                      @keypress="numberValid($event)"
                      @input="renderRepsOrTimeInputs(false)"
                      maxlength="3"
                      class="centered-input"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <p class="secondary--text pl-2 text-center">Rest Time</p>
                    <v-text-field
                      v-model.number="dialogAddOrEditExercise.edit.restTime"
                      :rules="rulesDialogAddOrEditExercise.restTime"
                      @keypress="numberValid($event)"
                      maxlength="3"
                      class="centered-input"
                      solo
                      flat
                      background-color="#efefef"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" md="3">
                    <p class="secondary--text pl-2 text-center">Type</p>
                    <v-select
                      v-model="dialogAddOrEditExercise.edit.typeCounter"
                      :items="listTypeCounter"
                      item-text="name"
                      item-value="value"
                      solo
                      flat
                      background-color="#efefef"
                      @input="renderRepsOrTimeInputs(false)"
                    ></v-select>
                  </v-col>
                  <!-- <v-spacer /> -->
                  <v-col cols="12" md="6">
                    <template
                      v-if="
                        dialogAddOrEditExercise.edit.typeCounter === 'byReps'
                      "
                    >
                      <p class="secondary--text pl-2 text-center">Reps</p>
                      <v-row>
                        <v-col
                          :key="index"
                          cols="6"
                          v-for="(rep, index) in dialogAddOrEditExercise.edit
                            .repsList"
                        >
                          <div class="d-flex align-center">
                            <p
                              class="secondary--text text-center mr-3 mb-7"
                              style="white-space: nowrap; font-size: 14px"
                            >
                              Set {{ index + 1 }}
                            </p>
                            <v-text-field
                              v-model="
                                dialogAddOrEditExercise.edit.repsList[index]
                                  .value
                              "
                              :rules="rulesDialogAddOrEditExercise.reps"
                              :key="index"
                              @keypress="numberValid($event)"
                              maxlength="3"
                              class="centered-input"
                              solo
                              flat
                              background-color="#efefef"
                            ></v-text-field>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                    <template
                      v-if="
                        dialogAddOrEditExercise.edit.typeCounter === 'byTime'
                      "
                    >
                      <p class="secondary--text pl-2 text-center">Time</p>
                      <v-row>
                        <v-col
                          :key="index"
                          cols="6"
                          v-for="(rep, index) in dialogAddOrEditExercise.edit
                            .timeList"
                        >
                          <div class="d-flex align-center">
                            <p
                              class="secondary--text text-center mr-3 mb-7"
                              style="white-space: nowrap; font-size: 14px"
                            >
                              Set {{ index + 1 }}
                            </p>
                            <v-text-field
                              v-model="
                                dialogAddOrEditExercise.edit.timeList[index]
                                  .value
                              "
                              :rules="rulesDialogAddOrEditExercise.time"
                              :key="index"
                              maxlength="5"
                              class="centered-input"
                              solo
                              flat
                              background-color="#efefef"
                            ></v-text-field>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </template>
          <div class="text-center">
            <v-btn
              color="primary"
              class="text-left mb-5"
              :disabled="!validAddExercise"
              @click="
                dialogAddOrEditExercise.isAdd
                  ? saveAddExercise()
                  : saveEditExercise()
              "
            >
              Save
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogUploadImagePreSet" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Uploading image, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingImagePreSet"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ##################-->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.text }}</v-snackbar
    >
    <v-file-input
      ref="imagePreSet"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg"
      dark
      outlined
      :show-size="1000"
      @change="getImagePreSet"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DatetimePicker from "@/components/admin/DatetimePicker.vue";
import moment from "moment";
import firebase from "firebase";
import draggable from "vuedraggable";
export default {
  name: "PreSetsCreate",
  components: {
    draggable,
    "v-datetime-picker": DatetimePicker,
  },
  data: () => ({
    validPreSet: false,
    validAddExercise: false,
    dialogUploadImagePreSet: false,
    exercisesList: [],
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    preSet: {
      circuits: [
        {
          name: {},
          exercises: [],
          restTime: 0,
        },
      ],
      station: {
        name: {},
        exercises: [],
        restTime: 0,
      },
      difficulty: "",
      typeBy: "circuit",
      circuitBegginer: 1,
      circuitIntermediate: 1,
      circuitAdvanced: 1,
      defaultRestTime: 0,
      description: {},
      enabled: true,
      isFree: false,
      estCalBegginer: 0,
      estCalIntermediate: 0,
      estCalAdvanced: 0,
      estTimeIntermediate: 0,
      estTimeBegginer: 0,
      estTimeAdvanced: 0,
      image: {
        img: null,
        link: "",
        ref: "",
        size: "",
        type: "",
      },
      name: {},
      details: {},
      superSets: null, // 0 = null
      orderNumber: "",
      counterReproductions: 0,
      subscription: "",
      filters: {},
      dynamicLink: "",
      isGrandFathered: false,
      endDay: "",
      disabledOnEndDay: false,
    },
    filtersRendered: false,
    filtersSelected: {},
    rulesPreSet: {
      name: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 4) || "Name must be greater than 4",
      ],
      description: [(v) => !!v || "Description section is required"],
      estCalBegginer: [
        (v) => v != "" || parseInt(v) === 0 || "Est. Cal Begginer is required",
      ],
      estCalIntermediate: [
        (v) =>
          v != "" || parseInt(v) === 0 || "Est. Cal Intermediate is required",
      ],
      estCalAdvanced: [
        (v) => v != "" || parseInt(v) === 0 || "Est. Cal Advanced is required",
      ],
      estTimeBegginer: [
        (v) => v != "" || parseInt(v) === 0 || "Est. Time Begginer is required",
      ],
      estTimeIntermediate: [
        (v) =>
          v != "" || parseInt(v) === 0 || "Est. Time Intermediate is required",
      ],
      estTimeAdvanced: [
        (v) => v != "" || parseInt(v) === 0 || "Est. Time Advanced is required",
      ],
      defaultRestTime: [
        (v) => v != "" || parseInt(v) === 0 || "Default Rest Time is required",
      ],
      superSets: [
        (v) => v != "" || parseInt(v) === 0 || "Super Sets is required",
      ],
    },
    rulesDialogAddOrEditExercise: {
      selectedExercise: [(v) => !!v || "Exercise is required"],
      multiplyFactor: [
        (v) => v != "" || parseFloat(v) === 0 || "Multiply factor is required",
        (v) =>
          v == 0 ||
          (String(v).substr(v.length - 1) !== "." &&
            String(v).charAt(0) !== ".") ||
          "Please enter a valid decimal value",
      ],
      reps: [(v) => v != "" || parseInt(v) === 0 || "Reps is required"],
      restTime: [
        (v) => v != "" || parseInt(v) === 0 || "Rest Time is required",
      ],
      sets: [(v) => v != "" || parseInt(v) === 0 || "Sets is required"],
      time: [
        (v) => !!v || "End section is required",
        (v) =>
          /^([0-5]\d:[0-5]\d$)/.test(v) || "End section format must be valid",
      ],
    },
    dialogAddOrEditExercise: {
      show: false,
      isAdd: false,
      typeBy: "",
      add: {
        selectedExercise: "",
        multiplyFactor: 1,
        typeCounter: "byReps",
        reps: 0,
        repsList: [{ value: 0 }],
        timeList: [{ value: "00:00" }],
        restTime: 0,
        sets: 1,
        time: "00:00",
        autoInit: false,
      },
      edit: {
        selectedExercise: "",
        multiplyFactor: 1,
        reps: 0,
        repsList: [{ value: 0 }],
        timeList: [{ value: "00:00" }],
        restTime: 0,
        sets: 1,
        time: "00:00",
        autoInit: false,
      },
    },
    headersExercises: [
      { text: "", align: "center", value: "drag", sortable: false },
      { text: "Sets", align: "center", value: "sets", sortable: true },
      { text: "Excercise", align: "center", value: "exercise", sortable: true },
      { text: "Type", align: "center", value: "typeCounter", sortable: true },
      {
        text: "Multiply Factor",
        align: "center",
        value: "multiplyFactor",
        sortable: true,
      },
      { text: "Rest Time", align: "center", value: "restTime", sortable: true },
      { text: "Auto Init", align: "center", value: "autoInit", sortable: true },
      { text: "Actions", align: "center", value: "actions", sortable: true },
    ],
    actualTranslateLang: "ENG",
    listLanguages: [
      {
        name: "English",
        code: "ENG",
        flag: require("@/assets/flags/usa-flag.png"),
      },
      {
        name: "Japanese",
        code: "JAP",
        flag: require("@/assets/flags/japan-flag.jpg"),
      },
      {
        name: "Spanish",
        code: "ESP",
        flag: require("@/assets/flags/spain-flag.png"),
      },
      {
        name: "Korean",
        code: "KOR",
        flag: require("@/assets/flags/south-korea-flag.png"),
      },
    ],
    selectedExerciseSection: {},
    //////////////////////
    /// Select values ///
    ////////////////////
    listDifficulty: [
      {
        value: "Easy",
      },
      {
        value: "Medium",
      },
      {
        value: "Hard",
      },
    ],
    listTypeCounter: [
      {
        name: "By Time",
        value: "byTime",
      },
      {
        name: "By Reps",
        value: "byReps",
      },
    ],
    listTypeBy: [
      {
        name: "Circuit",
        value: "circuit",
      },
      {
        name: "Station",
        value: "station",
      },
    ],
    listCircuit: [
      {
        value: 1,
      },
      {
        value: 2,
      },
      {
        value: 3,
      },
      {
        value: 4,
      },
      {
        value: 5,
      },
      {
        value: 6,
      },
      {
        value: 7,
      },
      {
        value: 8,
      },
      {
        value: 9,
      },
      {
        value: 10,
      },
    ],
    listSuperSet: [
      {
        value: null,
      },
      {
        value: 1,
      },
      {
        value: 2,
      },
      {
        value: 3,
      },
      {
        value: 4,
      },
      {
        value: 5,
      },
      {
        value: 6,
      },
      {
        value: 7,
      },
      {
        value: 8,
      },
      {
        value: 9,
      },
      {
        value: 10,
      },
    ],
  }),
  async created() {
    const vm = this;
    try {
      vm.$emit("showOverlay", true);
      await vm.getExercisesNewList();
      await vm.getSubscriptionsToSet();
      await vm.getPreSetsFiltersList();
      vm.$emit("showOverlay", false);
    } catch (error) {
      console.log(error);
      vm.$emit("showOverlay", false);
    }
  },
  methods: {
    async getExercisesNewList() {
      const vm = this;
      try {
        vm.exercisesList = await vm.$store.dispatch("GetExercisesNew");
      } catch (error) {
        console.error(error);
      }
    },
    async getSubscriptionsToSet() {
      const vm = this;
      try {
        await vm.$store.dispatch("GetSubscriptionsList");
      } catch (error) {
        console.error(error);
      }
    },
    async getPreSetsFiltersList() {
      const vm = this;
      try {
        await vm.$store.dispatch("GetFiltersListPreSets");
        for (let i = 0; i < vm.getPreSetsFilters.length; i++) {
          const filter = vm.getPreSetsFilters[i];
          vm.$set(vm.filtersSelected, filter.type, []);
        }
        vm.filtersRendered = true;
      } catch (error) {
        console.error(error);
      }
    },
    deleteFilterChipParent(type, node) {
      const vm = this;
      vm.preSet.filters[type] = vm.preSet.filters[type].filter(
        (parentFilter) => parentFilter.name !== node.name
      );
      vm.$set(vm.filtersSelected, type, vm.preSet.filters[type]);
    },
    deleteFilterChipChild(type, parent, child) {
      const vm = this;

      const parentIndex = vm.preSet.filters[type].findIndex(
        (parentFilter) => parentFilter.name === parent.name
      );
      const childIndex = vm.preSet.filters[type][
        parentIndex
      ].children.findIndex((childFilter) => childFilter.name === child.name);
      const deletedChild = vm.preSet.filters[type][parentIndex].children.splice(
        childIndex,
        1
      );

      const indexTreeview = vm.filtersSelected[type].findIndex(
        (filter) => filter.name === child.name
      );
      const deletedInTreeview = vm.filtersSelected[type].splice(
        indexTreeview,
        1
      );
      console.log(vm.preSet);
    },
    // getUpdateTreeview(updatedNodes, type){
    //   const vm = this
    //   vm.$set(vm.preSet.filters, type, [])

    //   const filterToUpdate = vm.getPreSetsFilters.find(filter => filter.type === type).listFilters
    //   updatedNodes.forEach((node) => {
    //     if(node.children){
    //       vm.preSet.filters[type].push(node)
    //     }else{
    //       filterToUpdate.forEach((principalFilter) => {
    //         let nodeParent = principalFilter.children.find(child => child.name === node.name)
    //         if(nodeParent){
    //           let nodeParentHasSaved = vm.preSet.filters[type].find(filterSaved => filterSaved.name === principalFilter.name)
    //           if(nodeParentHasSaved){
    //             let index = vm.preSet.filters[type].findIndex(filterSaved => filterSaved.name === nodeParentHasSaved.name)
    //             vm.preSet.filters[type][index].children.push(node)
    //           }else{
    //             vm.preSet.filters[type].push({ name:principalFilter.name, children:[node] })
    //           }
    //         }
    //       })
    //     }

    //   })
    // },
    getUpdateTreeview(updatedNodes, type) {
      const vm = this;
      vm.$set(vm.preSet.filters, type, []);

      const filterToUpdate = vm.getPreSetsFilters.find(
        (filter) => filter.type === type
      ).listFilters;

      updatedNodes.forEach((node) => {
        if (node.children) {
          vm.preSet.filters[type].push(node);
        } else {
          filterToUpdate.forEach((principalFilter) => {
            let nodeParent = principalFilter.children.find(
              (child) => child.name === node.name
            );
            if (nodeParent) {
              let nodeParentHasSaved = vm.preSet.filters[type].find(
                (filterSaved) => filterSaved.name === principalFilter.name
              );
              if (nodeParentHasSaved) {
                let index = vm.preSet.filters[type].findIndex(
                  (filterSaved) => filterSaved.name === nodeParentHasSaved.name
                );
                vm.preSet.filters[type][index].children.push(node);
              } else {
                vm.preSet.filters[type].push({
                  name: principalFilter.name,
                  children: [node],
                });
              }
            } else {
              principalFilter.children.forEach((subFilter) => {
                if (subFilter.children) {
                  const thirdLevel = subFilter.children.find(
                    (subF) => subF.name === node.name
                  );
                  if (thirdLevel) {
                    let indexPrincipalFilter = vm.preSet.filters[
                      type
                    ].findIndex(
                      (filterSaved) => filterSaved.name === principalFilter.name
                    );
                    if (indexPrincipalFilter !== -1) {
                      let indexSubFilter = vm.preSet.filters[type][
                        indexPrincipalFilter
                      ].children.findIndex(
                        (subFilterSaved) =>
                          subFilterSaved.name === subFilter.name
                      );
                      if (indexSubFilter !== -1) {
                        vm.preSet.filters[type][indexPrincipalFilter].children[
                          indexSubFilter
                        ].children.push(node);
                      } else {
                        vm.preSet.filters[type][
                          indexPrincipalFilter
                        ].children.push({
                          name: subFilter.name,
                          children: [node],
                        });
                      }
                    } else {
                      vm.preSet.filters[type].push({
                        name: principalFilter.name,
                        children: [{ name: subFilter.name, children: [node] }],
                      });
                    }
                  }
                }
              });
            }
          });
        }
      });
    },
    changeLanguage(lang) {
      const vm = this;
      vm.actualTranslateLang = lang.code;
    },
    getImagePreSet(file) {
      const vm = this;
      if (file) {
        if (
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png"
        ) {
          const fr = new FileReader();
          fr.onload = (el) => {
            vm.preSet.image = {
              img: el.target.result,
              file: file.type.split("/")[1],
              size: vm.getFileSize(file.size, 2),
              link: "",
            };
          };
          fr.readAsDataURL(file);
        } else {
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Only .png and .jpeg files are accepted",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        }
      }
    },
    renderRepsOrTimeInputs(isAdd) {
      const vm = this;
      const key = isAdd ? "add" : "edit";
      const typeArray =
        vm.dialogAddOrEditExercise[key].typeCounter === "byReps"
          ? "repsList"
          : "timeList";
      const actualQuantity = vm.dialogAddOrEditExercise[key].sets;
      if (actualQuantity != "") {
        if (vm.dialogAddOrEditExercise[key][typeArray].length <= 0) {
          for (let i = 0; i < actualQuantity; i++) {
            vm.dialogAddOrEditExercise[key][typeArray].push({
              value: typeArray === "repsList" ? 0 : "00:00",
            });
          }
        } else {
          const calculateIndexes =
            actualQuantity - vm.dialogAddOrEditExercise[key][typeArray].length;
          if (calculateIndexes > 0) {
            for (let i = 0; i < calculateIndexes; i++) {
              vm.dialogAddOrEditExercise[key][typeArray].push({
                value: typeArray === "repsList" ? 0 : "00:00",
              });
            }
          } else {
            for (let i = 0; i < Math.abs(calculateIndexes); i++) {
              vm.dialogAddOrEditExercise[key][typeArray].pop();
            }
          }
        }
      }
    },
    getFileSize(bytes, dp = 1) {
      const thresh = 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + " B";
      }
      //si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']; // original
      const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let u = -1;
      const r = 10 ** dp;

      do {
        bytes /= thresh;
        ++u;
      } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
      );

      return bytes.toFixed(dp) + " " + units[u];
    },
    changeTypeBy(typeBy) {
      const vm = this;
      if (typeBy === "circuit") {
        if (vm.preSet.circuits.length === 0) {
          vm.addNewCircuit();
        }
        vm.$set(vm.preSet, "station", {
          name: {},
          exercises: [],
          restTime: 0,
        });
      } else if (typeBy === "station") {
        vm.$set(vm.preSet, "circuits", []);
      }
    },
    openModalAddOrEditExercise(
      isAdd,
      typeBy,
      circuitIndex,
      exercise,
      exerciseIndex
    ) {
      let vm = this;
      if (!isAdd) {
        vm.dialogAddOrEditExercise.edit = {
          selectedExercise: exercise.exercise,
          multiplyFactor: exercise.multiplyFactor,
          repsList: exercise.repsList,
          timeList: exercise.timeList,
          restTime: exercise.restTime,
          sets: exercise.sets,
          typeCounter: exercise.typeCounter,
          exerciseIndex: exerciseIndex,
          autoInit: exercise.autoInit,
        };

        if (exercise.typeCounter === "byTime" && exercise.timeList.length > 0) {
          vm.dialogAddOrEditExercise.edit.timeList = exercise.timeList.map(
            (time) => {
              return {
                value: moment("1900-01-01 00:00:00")
                  .add(
                    `00:${time.value.minutes}:${time.value.seconds}`,
                    "seconds"
                  )
                  .format("mm:ss"),
              };
            }
          );
        }
      }

      vm.dialogAddOrEditExercise.typeBy = typeBy;
      if (typeBy === "circuit") {
        vm.dialogAddOrEditExercise.circuitIndex = circuitIndex;
      }
      vm.dialogAddOrEditExercise.isAdd = isAdd;
      vm.dialogAddOrEditExercise.show = true;
    },
    closeModalAddOrEditExercise() {
      let vm = this;
      vm.dialogAddOrEditExercise = {
        show: false,
        isAdd: false,
        typeBy: "",
        circuitIndex: null,
        add: {
          selectedExercise: "",
          multiplyFactor: 1,
          typeCounter: "byReps",
          repsList: [{ value: 0 }],
          timeList: [{ value: "00:00" }],
          reps: 0,
          restTime: 0,
          sets: 1,
          time: "00:00",
          autoInit: false,
        },
        edit: {
          selectedExercise: "",
          multiplyFactor: 1,
          typeCounter: "byReps",
          repsList: [{ value: 0 }],
          timeList: [{ value: "00:00" }],
          reps: 0,
          restTime: 0,
          sets: 1,
          time: "00:00",
          autoInit: false,
        },
      };
      vm.$nextTick(() => {
        if (vm.$refs.formAddExercise) {
          vm.$refs.formAddExercise.resetValidation();
        }
      });
    },
    saveAddExercise() {
      let vm = this;

      let dataToSave = {
        exercise: vm.dialogAddOrEditExercise.add.selectedExercise,
        multiplyFactor: parseFloat(
          vm.dialogAddOrEditExercise.add.multiplyFactor
        ),
        typeCounter: vm.dialogAddOrEditExercise.add.typeCounter,
        repsList: [],
        timeList: [],
        restTime: vm.dialogAddOrEditExercise.add.restTime,
        sets: vm.dialogAddOrEditExercise.add.sets,
        autoInit: vm.dialogAddOrEditExercise.add.autoInit,
      };

      if (vm.dialogAddOrEditExercise.add.typeCounter === "byTime") {
        dataToSave.timeList = vm.dialogAddOrEditExercise.add.timeList.map(
          (time) => {
            let parserTime = moment.duration(`00:${time.value}`, "seconds");
            return {
              value: {
                minutes: parserTime.get("minutes"),
                seconds: parserTime.get("seconds"),
              },
            };
          }
        );
      } else if (vm.dialogAddOrEditExercise.add.typeCounter === "byReps") {
        dataToSave.repsList = vm.dialogAddOrEditExercise.add.repsList;
      }

      if (vm.dialogAddOrEditExercise.typeBy === "circuit") {
        vm.preSet.circuits[
          vm.dialogAddOrEditExercise.circuitIndex
        ].exercises.push(dataToSave);
      } else if (vm.dialogAddOrEditExercise.typeBy === "station") {
        vm.preSet.station.exercises.push(dataToSave);
      }

      vm.closeModalAddOrEditExercise();
    },
    saveEditExercise() {
      let vm = this;
      let dataToEdit = {
        exercise: vm.dialogAddOrEditExercise.edit.selectedExercise,
        multiplyFactor: parseFloat(
          vm.dialogAddOrEditExercise.edit.multiplyFactor
        ),
        repsList: [],
        timeList: [],
        typeCounter: vm.dialogAddOrEditExercise.edit.typeCounter,
        restTime: vm.dialogAddOrEditExercise.edit.restTime,
        sets: vm.dialogAddOrEditExercise.edit.sets,
        autoInit: vm.dialogAddOrEditExercise.edit.autoInit,
      };

      if (vm.dialogAddOrEditExercise.edit.typeCounter === "byTime") {
        dataToEdit.timeList = vm.dialogAddOrEditExercise.edit.timeList.map(
          (time) => {
            let parserTime = moment.duration(`00:${time.value}`, "seconds");
            return {
              value: {
                minutes: parserTime.get("minutes"),
                seconds: parserTime.get("seconds"),
              },
            };
          }
        );
      } else if (vm.dialogAddOrEditExercise.edit.typeCounter === "byReps") {
        dataToEdit.repsList = vm.dialogAddOrEditExercise.edit.repsList;
      }

      if (
        vm.dialogAddOrEditExercise.typeBy === "circuit" &&
        vm.preSet.circuits[vm.dialogAddOrEditExercise.circuitIndex].exercises[
          vm.dialogAddOrEditExercise.edit.exerciseIndex
        ]
      ) {
        vm.$set(
          vm.preSet.circuits[vm.dialogAddOrEditExercise.circuitIndex].exercises,
          vm.dialogAddOrEditExercise.edit.exerciseIndex,
          dataToEdit
        );
      } else if (
        vm.dialogAddOrEditExercise.typeBy === "station" &&
        vm.preSet.station.exercises[
          vm.dialogAddOrEditExercise.edit.exerciseIndex
        ]
      ) {
        vm.$set(
          vm.preSet.station.exercises,
          vm.dialogAddOrEditExercise.edit.exerciseIndex,
          dataToEdit
        );
      }
      vm.closeModalAddOrEditExercise();
    },
    deleteSection(circuitIndex, exerciseIndex, isCircuit) {
      const vm = this;
      if (isCircuit) {
        vm.preSet.circuits[circuitIndex].exercises.splice(exerciseIndex, 1);
      } else {
        vm.preSet.station.exercises.splice(exerciseIndex, 1);
      }
    },
    addNewCircuit() {
      const vm = this;
      vm.preSet.circuits.push({
        name: {},
        exercises: [],
        restTime: 0,
      });
    },
    deleteCircuit(index) {
      const vm = this;
      vm.preSet.circuits.splice(index, 1);
    },
    numberValid(e) {
      e = e || window.event;
      const charCode = e.which ? e.which : e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    numberFloatValid(e) {
      e = e || window.event;
      const charCode = e.which ? e.which : e.keyCode;
      if (charCode > 31 && charCode != 46 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    uploadImage() {
      let vm = this;
      if (vm.preSet.image.img && vm.preSet.image.link == "") {
        vm.dialogUploadImagePreSet = true;
        vm.$store
          .dispatch("UploadImagePreSet", vm.preSet)
          .then((storage) => {
            vm.preSet.image.link = storage.link;
            vm.preSet.image.ref = storage.ref;
            vm.dialogUploadImagePreSet = false;
            vm.createPreSet();
          })
          .catch((err) => {
            console.log(err);
            vm.dialogUploadImagePreSet = false;

            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Error uploading image",
              color: "red",
            };
            vm.$emit("showSnackBar", emitData);
          });
      } else {
        vm.createPreSet();
      }
    },
    asignReferenceFirebaseExercises() {
      const vm = this;
      if (vm.preSet.typeBy === "circuit") {
        for (let i = 0; i < vm.preSet.circuits.length; i++) {
          for (let j = 0; j < vm.preSet.circuits[i].exercises.length; j++) {
            let idExercise = vm.preSet.circuits[i].exercises[j].exercise.id;
            let collectionName = vm.preSet.circuits[i].exercises[j].exercise
              .isReactNativeExercise
              ? "excercisesNew"
              : "excercises";
            let documentExercise = firebase
              .firestore()
              .collection(collectionName)
              .doc(idExercise);
            vm.preSet.circuits[i].exercises[j].exercise = documentExercise;
          }
        }
      } else if (vm.preSet.typeBy === "station") {
        for (let i = 0; i < vm.preSet.station.exercises.length; i++) {
          let idExercise = vm.preSet.station.exercises[i].exercise.id;
          let collectionName = vm.preSet.station.exercises[i].exercise
            .isReactNativeExercise
            ? "excercisesNew"
            : "excercises";
          let documentExercise = firebase
            .firestore()
            .collection(collectionName)
            .doc(idExercise);
          vm.preSet.station.exercises[i].exercise = documentExercise;
        }
      }
    },
    async createPreSet() {
      let vm = this;
      vm.$emit("showOverlay", true);
      vm.asignReferenceFirebaseExercises();
      try {
        const id = await vm.$store.dispatch("CreatePreSet", vm.preSet);
        const payload = {
          routeName: "preSets",
          title: vm.preSet.name["ENG"],
          id: id,
          img: vm.preSet.image.link,
        };
        vm.$store
          .dispatch("GenerateDynamicLink", payload)
          .then(async (dynamicLinks) => {
            vm.preSet.dynamicLink = dynamicLinks.shortLink;
            await vm.$store.dispatch("UpdatePreSet", vm.preSet);

            await vm.$store.dispatch("GenerateLog", {
              id: id,
              name: vm.preSet.name["ENG"],
              type: "createdPreSet",
            });
          });

        let emitData = {
          snackbar: true,
          btn: "",
          y: "bottom",
          x: null,
          mode: "",
          timeout: 2000,
          text: "Pre-Set Created successfully",
          color: "success",
        };
        vm.$router.push("/admin/pre-sets").catch((err) => {
          vm.snackbar.text =
            "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        });
        vm.$emit("showOverlay", false);
        vm.$emit("showSnackBar", emitData);
      } catch (error) {
        vm.$emit("showOverlay", false);
        let emitData = {
          snackbar: true,
          btn: "",
          y: "bottom",
          x: null,
          mode: "",
          timeout: 2000,
          text: "Error creating Pre-Set",
          color: "red",
        };
        vm.$emit("showSnackBar", emitData);
      }
    },
  },
  computed: {
    flagActualTranslate() {
      let vm = this;
      if (vm.listLanguages.length > 0) {
        let currentFlag = vm.listLanguages.find(
          (flag) => flag.code === vm.actualTranslateLang
        );
        return currentFlag.flag;
      }
    },
    ...mapGetters([
      "getLoadingImagePreSet",
      "getPreSetsFilters",
      "getSubscriptionsList",
    ]),
  },
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>