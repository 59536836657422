import firebase from "firebase";
// import Vue from "vue";
// import Axios from "axios";
// import VueAxios from "vue-axios";
import { v4 as uuidv4 } from "uuid";

// Vue.use(VueAxios, Axios);

const db = firebase.firestore();

const storage = firebase.storage().ref("programsVideos/");
const storageImages = firebase.storage().ref("programsImages/");

export default {
    state: {
        programsList: [],
        loadingStatusVideoProgram: 0,
        loadingStatusPreviewImageProgram: 0
    },
    mutations: {
        SET_PROGRAMS_LIST(state, programsList) {
            state.programsList = programsList
        },
        SET_VALUE_LOADING_STATUS_VIDEO_PROGRAM(state, value) {
            state.loadingStatusVideoProgram = value
        },
        SET_VALUE_LOADING_STATUS_PREVIEW_IMAGE_PROGRAM(state, value) {
            state.loadingStatusPreviewImageProgram = value
        },
        DELETE_PROGRAM_FROM_LIST(state, program) {
            let index = state.programsList.findIndex(p => p.id === program.id)
            state.programsList.splice(index, 1)
        }
    },
    actions: {
        GetProgramsList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("programsList").get()
                    const programsList = []
                    querySnapshot.forEach((doc) =>  {
                        programsList.push(doc.data())
                    })
                    commit('SET_PROGRAMS_LIST', programsList)
                    resolve(programsList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        GetProgram({ }, program){
            return new Promise(async (resolve, reject) => {
                try {
                    let resProgram = await db.collection("programsList").doc(program.id).get()
                    let gettedProgram = resProgram.data()
                    gettedProgram.endDay = gettedProgram.endDay ? gettedProgram.endDay.toDate() : ''
                    if(gettedProgram.subscription && gettedProgram.subscription != ""){
                        let subscription = await gettedProgram.subscription.get()
                        gettedProgram.subscription = subscription.data()
                    }
                    console.log(JSON.stringify(gettedProgram));
                    resolve(gettedProgram)
                } catch(error) {
                    reject(error)
                }
            })
        },
        UploadVideoProgram({ commit }, program) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storage.child(random);
                let bytes = new Blob([new Uint8Array(program.presentationVideo.blob)]);
                console.log('storageRef', storageRef.name);
                let metadata = {
                    contentType: 'video/mp4'
                }
                let uploadTask = storageRef.put(bytes, metadata)
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_VIDEO_PROGRAM', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        UploadImagePreviewProgram({ commit }, program) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(program.selectedImagePreview.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_PREVIEW_IMAGE_PROGRAM', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        CreateProgram({}, program) {
            return new Promise(async(resolve, reject) => {
                if (program.presentationVideo && program.presentationVideo.video) {
                    delete program.presentationVideo.video
                }
                if (program.selectedImagePreview && program.selectedImagePreview.img) {
                    delete program.selectedImagePreview.img
                }
                if(program.subscription && program.subscription != ""){
                    program.subscription = db.collection("subscriptionsList").doc(program.subscription.id)
                }
                const ref = db.collection("programsList").doc();
                const newProgram = await ref.get();
                program.id = ref.id;
                program.createdAt = new Date()
                program.updatedAt = new Date()
                await db.collection("programsList")
                    .doc(newProgram.id)
                    .set(program)
                    .then(async(res) => {
                        console.log('newProgram.id', newProgram.id);
                        resolve(newProgram.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },
        UpdateProgram({}, program) {
            return new Promise(async(resolve, reject) => {
                if (program.presentationVideo && program.presentationVideo.video) {
                    delete program.presentationVideo.video
                }
                if (program.selectedImagePreview && program.selectedImagePreview.img) {
                    delete program.selectedImagePreview.img
                }
                if(program.subscription && program.subscription != ""){
                    program.subscription = db.collection("subscriptionsList").doc(program.subscription.id)
                }
                if(program.createdAt) {
                    delete program.createdAt
                }
                program.updatedAt = new Date()
                await db.collection("programsList")
                    .doc(program.id)
                    .update(program)
                    .then((res) => {
                        resolve(program.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        DeleteProgram({ commit }, program) {
            return new Promise(async(resolve, reject) => {
                if (program.presentationVideo && program.presentationVideo.ref) {
                    let storageRef = storage.child(program.presentationVideo.ref);
                    try {
                        await storageRef.delete()
                    } catch (error) {
                        reject(error)
                    }
                }
                if (program.selectedImagePreview && program.selectedImagePreview.ref) {
                    let storageRefImage = storageImages.child(program.selectedImagePreview.ref);
                    try {
                        await storageRefImage.delete()
                    } catch (error) {
                        reject(error)
                    }
                }
                try {
                    await db.collection("programsList").doc(program.id).delete()
                    commit('DELETE_PROGRAM_FROM_LIST', program)
                    resolve(program.id)
                } catch (error) {
                    reject(error)
                }
            })
        },
        DisableProgram({}, program){
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("programsList").doc(program.id).update({
                      enabled: !program.enabled
                    })
                    resolve(program.id);
                } catch (error) {
                    reject(error);
                }
            });
      
        }

    },
    getters: {
        getProgramsList(state) {
            return state.programsList
        },
        getLoadingStatusVideoProgram(state) {
            return state.loadingStatusVideoProgram
        },
        getLoadingStatusPreviewImageProgram(state) {
            return state.loadingStatusPreviewImageProgram
        },
    }
}