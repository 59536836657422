import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { v4 as uuidv4 } from "uuid";

Vue.use(VueAxios, Axios);

const db = firebase.firestore();


Vue.use(VueAxios, Axios);

const storageImages = firebase.storage().ref("tipsImages/");

export default {
    state:{
        tipsList: [],
        loadingStatusTipImage:0,
        categoriesTipsList:[]
    },
    mutations:{
        SET_TIPS_LIST(state, tipsList){
            state.tipsList = tipsList
        },
        SET_CATEGORIES_TIPS_LIST(state, categoriesTipsList){
            state.categoriesTipsList = categoriesTipsList
        },
        SET_VALUE_LOADING_STATUS_TIP_IMAGE(state, progress) {
            state.loadingStatusTipImage = progress
        },
    },
    actions:{
        UploadImageTip({ commit }, tip) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(tip.image.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_TIP_IMAGE', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        GetTipsList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("tipsList").get()
                    const tipsList = []
                    querySnapshot.forEach(async(doc) =>  {
                        let tip = doc.data()
                        let category = await tip.category.get()
                        tip.category = category.data()
                        tipsList.push(tip)
                    })
                    commit('SET_TIPS_LIST', tipsList)
                    resolve(tipsList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        GetTip({ }, tip){
            return new Promise(async (resolve, reject) => {
                try {
                    let resTip = await db.collection("tipsList").doc(tip.id).get()
                    let gettedTip = resTip.data()
                    let category = await gettedTip.category.get()
                    gettedTip.category = category.data()
                    resolve(gettedTip)
                } catch(error) {
                    reject(error)
                }
            })
        },
        GetCategoriesTipsList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("categoriesTipsList").get()
                    const categoriesTipsList = []
                    querySnapshot.forEach((doc) =>  {
                        categoriesTipsList.push(doc.data())
                    })
                    console.log(categoriesTipsList)
                    commit('SET_CATEGORIES_TIPS_LIST', categoriesTipsList)
                    resolve(categoriesTipsList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateTip({}, tip) {
            return new Promise(async(resolve, reject) => {
                try {
                    delete tip.image.img
                    tip.category = db.collection("categoriesTipsList").doc(tip.category.id);
                    const ref = db.collection("tipsList").doc();
                    const newTip = await ref.get();
                    tip.id = ref.id;
                    tip.createdAt = new Date()
                    tip.updatedAt = new Date()
                    await db.collection("tipsList").doc(newTip.id).set(tip)
                    resolve(newTip.id);
                } catch (error) {
                    reject(error);
                }
            })
        },
        CreateCategoryTip({}, categoryTip) {
            return new Promise(async(resolve, reject) => {
                try {
                    const ref = db.collection("categoriesTipsList").doc();
                    const newCategoryTip = await ref.get();
                    categoryTip.id = ref.id;
                    await db.collection("categoriesTipsList").doc(newCategoryTip.id).set(categoryTip)
                    resolve(newCategoryTip.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        UpdateTip({}, tip) {
            return new Promise(async(resolve, reject) => {
                try {
                    if (tip.image.img) {
                        delete tip.image.img
                    }
                    if(tip.createdAt) {
                        delete tip.createdAt
                    }
                    tip.updatedAt = new Date()
                    tip.category = db.collection("categoriesTipsList").doc(tip.category.id);
                    await db.collection("tipsList").doc(tip.id).update(tip)
                    resolve(tip.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        UpdateCategoryTip({}, category) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("categoriesTipsList").doc(category.id).update(category)
                    resolve(category.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteTip({}, tip) {
            return new Promise(async(resolve, reject) => {
                try {
                    let storageRefImage = storageImages.child(tip.image.ref);
                    await storageRefImage.delete()
                    await db.collection("tipsList").doc(tip.id).delete()
                    resolve(tip.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteCategoryTip({}, category) {
            return new Promise(async(resolve, reject) => {
                try {
                    console.log('category', category)
                    await db.collection("categoriesTipsList").doc(category.id).delete()
                    resolve(category.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    getters:{
        getTipsList(state){
            return state.tipsList;
        },
        getLoadingStatusTipImage(state){
            return state.loadingStatusTipImage;
        },
        getCategoriesTipsList(state){
            return state.categoriesTipsList;
        },
    }
}