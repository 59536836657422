<template>
 <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff"
  >
  <v-row justify="start">
      <v-col cols="12" sm="12" class="pa-0">
        <v-btn color="primary" icon size="20" @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" class="text-center">
        <div
          v-if="feedback.user.photoURL !== ''"
          class="rounded-circle avatar-profile-image mx-auto mb-3"
          :style="`background-image:url(${feedback.user.photoURL})`"
        ></div>
        <template v-else>
          <div class="text-center mb-3">
            <img class="rounded-circle avatar-profile-image-detail" :src="require('@/assets/default-avatar.png')">
          </div>
        </template>
        <h3 v-if="feedback.user.uid" :class="`d-inline text-center ${feedback.readed ? 'text-decoration-line-through grey--text text--lighten-1 font-weight-regular' : 'secondary--text'}`">Feedback Detail: <span :class="`${feedback.readed ? 'text-decoration-line-through grey--text text--lighten-1 font-weight-regular' : 'primary--text'}`">{{ feedback.id }}</span></h3>
      </v-col>
  </v-row>
  <v-row justify="start">
      <v-col cols="12" sm="12" md="4">
        <h3 class="secondary--text"><span class="font-weight-light">Name:</span> {{ feedback.user.firstName }} {{ feedback.user.lastName }}</h3>
        <h3 class="secondary--text"><span class="font-weight-light">Email:</span> {{ feedback.emailUser }}</h3>
        <h3 class="secondary--text"><span class="font-weight-light">Country:</span> {{ feedback.user.country }}</h3>
        <h3 class="secondary--text" v-if="feedback && feedback.user && feedback.user.metadata"><span class="font-weight-light">Account Creation:</span> {{ getParsedDate(feedback.user.metadata.creationTime) }}</h3>
       <!-- </div> -->
      </v-col>
  <!-- </v-row>
  <v-row justify="start"> -->
    <v-col cols="12" sm="12" md="4">
      <!-- <h3 class="secondary--text mb-2">Title: <span class="font-weight-light">{{ feedback.title }}</span></h3> -->
      <h3 class="secondary--text">Message:</h3>
      <p class="secondary--text">{{ feedback.message }}</p>
    </v-col>
    <v-col cols="12" sm="12" md="4">
      <h3 class="secondary--text">appVersion: <span class="font-weight-light">{{ feedback.deviceInfo.appVersion }}</span></h3>
      <h3 class="secondary--text">manufacturer: <span class="font-weight-light">{{ feedback.deviceInfo.manufacturer }}</span></h3>
      <h3 class="secondary--text">model: <span class="font-weight-light">{{ feedback.deviceInfo.model }}</span></h3>
      <h3 class="secondary--text">operatingSystem: <span class="font-weight-light">{{ feedback.deviceInfo.operatingSystem }}</span></h3>
      <h3 class="secondary--text">osVersion: <span class="font-weight-light">{{ feedback.deviceInfo.osVersion }}</span></h3>
      <h3 class="secondary--text">platform: <span class="font-weight-light">{{ feedback.deviceInfo.platform }}</span></h3>
      <h3 class="secondary--text">uuid: <span class="font-weight-light">{{ feedback.deviceInfo.uuid }}</span></h3>
    </v-col>
  </v-row>
  <v-row justify="center">
    <v-col cols="3">
      <p class="secondary--text pb-1" style="font-size: 14px">Categorie</p>
      <template v-if="!editCategorie">
        <v-chip
        v-if="feedback.categorieObj"
        :color="feedback.categorieObj.color"
        text-color="white"
        >
          {{ feedback.categorieObj.name }}
        </v-chip>
        <template v-else>
          No setted categorie
        </template>
        <v-btn
          v-if="!feedback.readed"
          icon
          color="secondary"
          size="20"
          small
          @click="editCategorie = true"
          ><v-icon>mdi-pencil</v-icon></v-btn>
      </template>
      <template v-else>
        <div class="d-flex">
        <v-select
          v-if="!feedback.readed"
          :items="getFeedbackCategoriesList"
          :value="feedback.categorieObj"
          item-text="name"
          item-value="name"
          solo
          flat
          return-object
          background-color="#efefef"
          @change="changeCategorie($event)"
        ></v-select>
        <v-btn
          class="mt-2"
          icon
          color="secondary"
          size="20"
          small
          @click="editCategorie = false"
          ><v-icon>mdi-close</v-icon></v-btn>
        </div>
      </template>

    </v-col>
    <v-col cols="3">
      <p class="secondary--text pb-1" style="font-size: 14px">Status</p>
        <template v-if="!editStatus">
          <v-chip
          v-if="feedback.statusObj"
          :color="feedback.statusObj.color"
          text-color="white"
          >
            {{ feedback.statusObj.name }}
          </v-chip>
          <template v-else>
            No setted status
          </template>
          <v-btn
            v-if="!feedback.readed"
            icon
            color="secondary"
            size="20"
            small
            @click="editStatus = true"
            ><v-icon>mdi-pencil</v-icon></v-btn>
        </template>
        <template v-else>
          <div class="d-flex">
          <v-select
            v-if="!feedback.readed"
            :items="getFeedbackStatusList"
            :value="feedback.statusObj"
            item-text="name"
            item-value="name"
            solo
            flat
            return-object
            background-color="#efefef"
            @change="changeStatus($event)"
          ></v-select>
          <v-btn
            class="mt-2"
            icon
            color="secondary"
            size="20"
            small
            @click="editStatus = false"
            ><v-icon>mdi-close</v-icon></v-btn>
          </div>
        </template>
    </v-col>
  </v-row>
  <v-row justify="center">
    <v-btn
      small
      color="primary"
      class="mx-1"
      @click="goToUserProfile()"
    >
      <v-icon left>
        mdi-account
      </v-icon>
      show profile
    </v-btn>
    <v-btn
      small
      color="success"
      class="mx-1"
      :loading="loading"
      :disabled="feedback.readed || loading"
      @click="resolveFeedback()"
    >
      <v-icon left>
        mdi-check
      </v-icon>
      Resolved
    </v-btn>
  </v-row>
  <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
 </v-container>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "FeedbackDetail",
  data: () => ({
    feedback:{
      createdAt: '',
      id: '',
      title: '',
      message: '',
      readed: false,
      deviceInfo: {
        appBuild: '',
        appVersion: '',
        isVirtual: false,
        manufacturer: '',
        model: '',
        operatingSystem: '',
        osVersion: '',
        platform: '',
        uuid: '',
      },
      user: {
        firstName:'',
        lastName:'',
        email:'',
        uid:'',
        metadata:{
          creationTime:''
        },
        photoURL:'',
        country:''
      }
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    editCategorie:false,
    editStatus:false,
    loading:false,
  }),
  created(){
    let vm = this
    vm.getFeedback()
  },
  methods:{
    getUser(){
      let vm = this
      vm.$store
          .dispatch("GetUser", vm.feedback.user.id)
          .then((user) => {
            // vm.feedback = vm.$route.params
            vm.feedback.user = user
            // vm.user.adminLevel = vm.user.adminLevel ? parseInt(vm.$route.params.adminLevel, 10) : "";
            console.log(vm.feedback);
            
            vm.obtainCategoriesList()
            vm.obtainStatusList()
            vm.$emit("showOverlay", false);
          })
          .catch((err) => {
            console.log(err)
            vm.$emit("showOverlay", false);
          })
    },
    getFeedback(){
      let vm = this
      vm.$emit("showOverlay", true);
      let splittedId = window.location.pathname.split( '/' ).pop()
      vm.$store
          .dispatch("GetFeedback", splittedId)
          .then((feedback) =>   {
            console.log(feedback);
            vm.feedback = feedback
            vm.feedback.id = splittedId
            vm.getUser();
            // vm.user.adminLevel = vm.user.adminLevel ? parseInt(vm.$route.params.adminLevel, 10) : "";
          })
          .catch((err) => {
            console.log(err)
            vm.$emit("showOverlay", false);
          })
    },
    obtainCategoriesList(){
      let vm = this
      vm.$store.dispatch('GetFeedbackCategoriesList')
      .then((res)=>{
      }).catch((err)=>{
        console.error(err)
        vm.$emit("showOverlay", false);
      })
    },
    obtainStatusList(){
      let vm = this
      vm.$store.dispatch('GetFeedbackStatusList')
      .then((res)=>{
      }).catch((err)=>{
        console.error(err)
        vm.$emit("showOverlay", false);
      })
    },
   getParsedDate(date){
      if(date){ return moment(date).format("DD/MM/YYYY") }
    },
    goToUserProfile(){
      let vm = this
      vm.$router.push({ name: 'User Profile', params: vm.feedback.user })
      .catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      })
    },
    changeCategorie(e){
      let vm = this
      let data = {
        feedback:vm.feedback.id,
        change:{
          hasCategorie:true,
          categorie:e.id
        }
      }
      vm.$store.dispatch("ChangeCategorieOrStatusFeedback", data)
        .then((res) => {
          vm.feedback.categorie = res.categorie
          vm.feedback.categorieObj = e
          vm.$store.dispatch("GenerateLog", {
            id: vm.feedback.id,
            emailUser:vm.feedback.emailUser,
            name:e.name,
            type: "changedCategorieToFeedback",
          });
          vm.editCategorie = false
          let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Feedback categorie changed!",
              color: "dark",
            };
            vm.$emit("showSnackBar", emitData);
        })
        .catch((err) => {
            vm.loading = false
            console.log(err)
        })
    },
    changeStatus(e){
      let vm = this
      let data = {
        feedback:vm.feedback.id,
        change:{
          hasStatus:true,
          status:e.id
        }
      }
      vm.$store.dispatch("ChangeCategorieOrStatusFeedback", data)
        .then((res) => {
          vm.feedback.status = res.status
          vm.feedback.statusObj = e
          vm.$store.dispatch("GenerateLog", {
              id: vm.feedback.id,
              emailUser:vm.feedback.emailUser,
              name:e.name,
              type: "changedStatusToFeedback",
          });
          vm.editStatus = false
          let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Feedback status changed!",
              color: "dark",
            };
            vm.$emit("showSnackBar", emitData);
        })
        .catch((err) => {
            console.log(err)
        })
    },
    resolveFeedback(){
      let vm = this
      vm.loading = true
      vm.$store.dispatch("ResolveFeedback", vm.feedback.id)
        .then((res) => {
            vm.$store.dispatch("GenerateLog", {
              id: vm.feedback.id,
              emailUser:vm.feedback.emailUser,
              type: "resolvedFeedback",
            });
            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Feedback resolved!",
              color: "success",
            };
            vm.$emit("showSnackBar", emitData);
            vm.loading = false
            vm.$router.push({ name: 'Feedback List' })
            .catch((err) => {
              vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
              vm.snackbar.color = "red darken-2";
              vm.snackbar.snackbar = true;
            })
            // vm.user.adminLevel = vm.user.adminLevel ? parseInt(vm.$route.params.adminLevel, 10) : "";
          })
          .catch((err) => {
            console.log(err)
          })
    }
  },
  computed:{
     ...mapGetters(["getFeedbackCategoriesList", "isSubscribedFeedbackCategoriesList", "getFeedbackStatusList", "isSubscribedFeedbackStatusList"]),
  },
  destroyed(){
    let vm = this
    if(vm.isSubscribedFeedbackCategoriesList){
      vm.$store.dispatch('GoUnsuscribeGetFeedbackCategoriesList')
    }
    if(vm.isSubscribedFeedbackStatusList){
      vm.$store.dispatch('GoUnsuscribeGetFeedbackStatusList')
  }
}
}
</script>
<style lang="scss">
  .avatar-profile-image-detail {
    width: 70px;
    height: 70px;
    background-repeat: no-repeat;
    background-position: center center;
    object-fit: contain;
  }
</style>