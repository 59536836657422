<template>
    <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="2">
        <p class="text-h6 text--secondary font-weight-bold">Feedback</p>
      </v-col>
      
      <v-col cols="12" sm="12" md="2">
        <v-btn
          text
          color="secondary"
          @click="goSettingsFeedbacks()"
        >
        <v-icon left dark color="primary"> mdi-cog </v-icon>
         Settings Feedbacks
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="8">
        <!-- <v-text-field
          label="Search for a Feedback"
          v-model="tableWithoutCategorie.search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field> -->
      </v-col>
        <v-col cols="12" sm="12" md="4">
          <p class="text-h6 text--secondary mb-0 font-weight-bold">Uncategorized</p>
        </v-col>
         <v-col cols="12" sm="12" md="5">
          <v-text-field
            label="Search for a Uncategorized Feedback"
            v-model="tableWithoutCategorie.search"
            single-line
            outlined
            rounded
            filled
            dense
            append-icon="mdi-magnify"
            ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headersUncategorized"
            :search="tableWithoutCategorie.search"
            :loading="tableWithoutCategorie.loading"
            :items="getFeedbackListWithoutCategorie"
            item-key="id"
            fixed-header
            class="elevation-4"
          >
          <template v-slot:[`item.id`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ item.id }}</span>
          </template>
          <template v-slot:[`item.emailUser`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ item.emailUser }}</span>
          </template>
          <template v-slot:[`item.deviceInfo.appVersion`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ item.deviceInfo.appVersion }}</span>
          </template>
          <template v-slot:[`item.deviceInfo.operatingSystem`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ item.deviceInfo.operatingSystem }}</span>
          </template>
          <template v-slot:[`item.deviceInfo.manufacturer`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ item.deviceInfo.manufacturer }}</span>
          </template>
          <template v-slot:[`item.deviceInfo.model`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ item.deviceInfo.model }}</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ parseToRelativeDate(item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              icon
              color="primary"
              @click="changeRouteFeedback(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <!-- <template v-slot:footer="{}">
            <v-row justify="end">
              <v-col cols="1">
                <v-select
                  class="mr-auto"
                  v-model="tableWithoutCategorie.itemsPerPage"
                  :items="[5,10,15,20,30]"
                >
              </v-select>
              </v-col>
              <v-col cols="2" class="text-right mr-3" align-self="center">
              <v-btn color="primary" x-small class="mx-1" :disabled="tableWithoutCategorie.pageCounter === 1" @click="goPrevPageWithoutCategorie()">
                <v-icon size="15">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <p class="d-inline">{{ tableWithoutCategorie.pageCounter }}</p>
              <v-btn color="primary" x-small class="mx-1" :disabled="getFeedbackListWithoutCategorie.length < tableWithoutCategorie.itemsPerPage || getFeedbackListWithoutCategorie.length === 0" @click="goNextPageWithoutCategorie()">
                <v-icon size="15">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
              </v-col>
            </v-row>
          </template> -->
          </v-data-table>
        </v-col>


         <v-col cols="12" sm="12" md="4">
          <p class="text-h6 text--secondary mb-0 font-weight-bold">Categorized</p>
        </v-col>
        <v-col cols="12" sm="12" md="5">
          <v-text-field
            label="Search for a Uncategorized Feedback"
            v-model="tableFeedbackList.search"
            single-line
            outlined
            rounded
            filled
            dense
            append-icon="mdi-magnify"
            ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headersCategorized"
            :search="tableFeedbackList.search"
            :loading="tableFeedbackList.loading"
            :items="getFeedbackList"
            item-key="id"
            fixed-header
            class="elevation-4"
          >
          <template v-slot:[`item.id`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ item.id }}</span>
          </template>
          <template v-slot:[`item.emailUser`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ item.emailUser }}</span>
          </template>
          <template v-slot:[`item.categorieObj.name`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">
              <v-chip
                v-if="item.categorieObj"
                :color="item.categorieObj.color"
                text-color="white"
                >
                  {{ item.categorieObj.name }}
                </v-chip>
                <template v-else>
                  Categorie is deleted
              </template>
            </span>
          </template>
          <template v-slot:[`item.statusObj.name`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">
              <v-chip
                v-if="item.statusObj"
                :color="item.statusObj.color"
                text-color="white"
                >
                  {{ item.statusObj.name }}
                </v-chip>
                <template v-else>
                  Has no state
              </template>
            </span>
          </template>
          <template v-slot:[`item.deviceInfo.appVersion`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ item.deviceInfo.appVersion }}</span>
          </template>
          <template v-slot:[`item.deviceInfo.operatingSystem`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ item.deviceInfo.operatingSystem }}</span>
          </template>
          <template v-slot:[`item.deviceInfo.manufacturer`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ item.deviceInfo.manufacturer }}</span>
          </template>
          <template v-slot:[`item.deviceInfo.model`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ item.deviceInfo.model }}</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span :class="item.readed ? 'text-decoration-line-through grey--text text--lighten-1' : ''">{{ parseToRelativeDate(item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              icon
              color="primary"
              @click="changeRouteFeedback(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <!-- <template v-slot:footer="{}">
            <v-row justify="end">
              <v-col cols="1">
                <v-select
                  class="mr-auto"
                  v-model="tableFeedbackList.itemsPerPage"
                  :items="[5,10,15,20,30]"
                >
              </v-select>
              </v-col>
              <v-col cols="2" class="text-right mr-3" align-self="center">
              <v-btn color="primary" x-small class="mx-1" :disabled="tableFeedbackList.pageCounter === 1" @click="goPrevPageFeedbackList()">
                <v-icon size="15">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <p class="d-inline">{{ tableFeedbackList.pageCounter }}</p>
              <v-btn color="primary" x-small class="mx-1" :disabled="getFeedbackList.length < tableFeedbackList.itemsPerPage || getFeedbackList.length === 0" @click="goNextPageFeedbackList()">
                <v-icon size="15">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
              </v-col>
            </v-row>
          </template> -->
          </v-data-table>
        </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "FeedbackList",
  data: () => ({
      headersCategorized: [
        { text: "Feedback ID", align: "center", value: "id",sortable: false, },
        { text: "Email", align: "start", value: "emailUser", sortable: true },
        { text: "Categorie", align: "center", value: "categorieObj.name", sortable: true },
        { text: "Status", align: "center", value: "statusObj.name", sortable: true },
        { text: "App Version", align: "center", value: "deviceInfo.appVersion", sortable: true },
        { text: "OS", align: "start", value: "deviceInfo.operatingSystem", sortable: true },
        { text: "Manufacter", align: "start", value: "deviceInfo.manufacturer", sortable: true },
        { text: "Model", align: "start", value: "deviceInfo.model", sortable: true },
        { text: "Reported", align: "start", value: "createdAt", sortable: true },
        { text: "Show", align: "start", value: "action", sortable: false },
     ],
    headersUncategorized: [
        { text: "Feedback ID", align: "center", value: "id",sortable: false, },
        { text: "Email", align: "start", value: "emailUser", sortable: true },
        { text: "App Version", align: "center", value: "deviceInfo.appVersion", sortable: true },
        { text: "OS", align: "start", value: "deviceInfo.operatingSystem", sortable: true },
        { text: "Manufacter", align: "start", value: "deviceInfo.manufacturer", sortable: true },
        { text: "Model", align: "start", value: "deviceInfo.model", sortable: true },
        { text: "Reported", align: "start", value: "createdAt", sortable: true },
        { text: "Show", align: "start", value: "action", sortable: false },
     ],
    tableWithoutCategorie:{
      itemsPerPage:10,
      pageCounter:1,
      search:"",
      loading:false,
    },
    tableFeedbackList:{
      itemsPerPage:10,
      pageCounter:1,
      search:"",
      loading:false,
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    
  }),
  created(){
    let vm = this
    vm.getFeedbackWithoutCategorie()
    // vm.getFirstPageWithoutCategorie()
    vm.getFeedbackListCategorized()
  },
  methods:{
    changeRouteFeedback(feedback){
      let vm = this
      vm.$router.push({ name: "Feedback Detail", params: feedback })
      .catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      })
    },
    goSettingsFeedbacks(){
      let vm = this
      vm.$router.push({ name: "Feedback Settings" })
      .catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      })
    },
    getFeedbackWithoutCategorie(){
      let vm = this
      vm.tableWithoutCategorie.loading = true
      vm.$store.dispatch('GetFeedbackListWithoutCategoriePaginatedFirstPage')
      .then((res)=>{
        // vm.tableWithoutCategorie.pageCounter = 1
        vm.tableWithoutCategorie.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.tableWithoutCategorie.loading = false
      })
    },
     goNextPageWithoutCategorie(){
      let vm = this
      vm.$store.dispatch('GetFeedbackListWithoutCategorieNextPage', vm.tableWithoutCategorie.itemsPerPage)
      .then((res)=>{
        if(res.length > 0){
          vm.tableWithoutCategorie.pageCounter = vm.tableWithoutCategorie.pageCounter + 1
        }
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.tableWithoutCategorie.loading = false
      })
    },
    goPrevPageWithoutCategorie(){
      let vm = this
      vm.$store.dispatch('GetFeedbackListWithoutCategoriePrevPage', vm.tableWithoutCategorie.itemsPerPage)
      .then((res)=>{
        vm.tableWithoutCategorie.pageCounter = vm.tableWithoutCategorie.pageCounter - 1
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.tableWithoutCategorie.loading = false
      })
    },

    getFeedbackListCategorized(){
      let vm = this
      vm.tableFeedbackList.loading = true
      vm.$store.dispatch('GetFeedbackList')
      .then((res)=>{
        // vm.tableFeedbackList.pageCounter = 1
        vm.tableFeedbackList.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.tableFeedbackList.loading = false
      })
    },
     goNextPageFeedbackList(){
      let vm = this
      vm.$store.dispatch('GetFeedbackListNextPage', vm.tableFeedbackList.itemsPerPage)
      .then((res)=>{
        if(res.length > 0){
          vm.tableFeedbackList.pageCounter = vm.tableFeedbackList.pageCounter + 1
        }
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.tableFeedbackList.loading = false
      })
    },
    goPrevPageFeedbackList(){
      let vm = this
      vm.$store.dispatch('GetFeedbackListPrevPage', vm.tableFeedbackList.itemsPerPage)
      .then((res)=>{
        vm.tableFeedbackList.pageCounter = vm.tableFeedbackList.pageCounter - 1
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.tableFeedbackList.loading = false
      })
    },
    parseToRelativeDate(date){
     return moment(date.toDate()).fromNow()
    },
  },
  computed: {
    ...mapGetters(["getFeedbackListWithoutCategorie",  "getFeedbackList"]),
  },
  // watch:{
  //   "tableWithoutCategorie.itemsPerPage":function(newValue){
  //     this.getFirstPageWithoutCategorie()
  //   },
  //   "tableFeedbackList.itemsPerPage":function(newValue){
  //     this.getFirstPageFeedbackList()
  //   }
  // }
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>