const localhost = {
    getAllUsers: 'http://localhost:5001/maxpro-dev/us-central1/getFullUsers',
    searchByEmail: 'http://localhost:5001/maxpro-dev/us-central1/getUserByEmail',
    disableUser: 'http://localhost:5001/maxpro-dev/us-central1/disableUser',
    sendResetPasswordEmail: 'http://localhost:5001/maxpro-dev/us-central1/sendResetPasswordEmail',
    sendVerificationEmail: 'http://localhost:5001/maxpro-dev/us-central1/sendVerificationEmail',
    getAdminEmail: 'http://localhost:5001/maxpro-dev/us-central1/getAdminEmail',
    sendMassiveNotification: 'http://localhost:5001/maxpro-dev/us-central1/sendMassiveNotification',
    sendUserNotification: 'http://localhost:5001/maxpro-dev/us-central1/sendUserNotification',
    getUserSubCollections: 'http://localhost:5001/maxpro-dev/us-central1/getUserSubCollections',
    saveFromAddressFeedback: 'http://localhost:5001/maxpro-dev/us-central1/saveFromAddressFeedback',
    querieForSaveMetadata: 'http://localhost:5001/maxpro-dev/us-central1/querieForSaveMetadata',
    retrieveAuthInfoUsers: 'http://localhost:5001/maxpro-dev/us-central1/retrieveAuthInfoUsers',
    resetAllLeadersCoachedClasses: 'http://localhost:5001/maxpro-dev/us-central1/resetAllLeadersCoachedClasses',
    sendNotificationToTopic: 'http://localhost:5001/maxpro-dev/us-central1/sendNotificationToTopic',
    addUserToManyTopics: 'http://localhost:5001/maxpro-dev/us-central1/addUserToManyTopics',
}
const localhostProd = {
    getAllUsers: 'http://localhost:5001/maxfit-app/us-central1/getFullUsers',
    searchByEmail: 'http://localhost:5001/maxfit-app/us-central1/getUserByEmail',
    disableUser: 'http://localhost:5001/maxfit-app/us-central1/disableUser',
    sendResetPasswordEmail: 'http://localhost:5001/maxfit-app/us-central1/sendResetPasswordEmail',
    sendVerificationEmail: 'http://localhost:5001/maxfit-app/us-central1/sendVerificationEmail',
    getAdminEmail: 'http://localhost:5001/maxfit-app/us-central1/getAdminEmail',
    sendMassiveNotification: 'http://localhost:5001/maxfit-app/us-central1/sendMassiveNotification',
    sendUserNotification: 'http://localhost:5001/maxfit-app/us-central1/sendUserNotification',
    getUserSubCollections: 'http://localhost:5001/maxfit-app/us-central1/getUserSubCollections',
    saveFromAddressFeedback: 'http://localhost:5001/maxfit-app/us-central1/saveFromAddressFeedback',
    querieForSaveMetadata: 'http://localhost:5001/maxfit-app/us-central1/querieForSaveMetadata',
    coachedClassesResetLeaderboards: 'http://localhost:5001/maxfit-app/us-central1/coachedClassesResetLeaderboards',
    retrieveAuthInfoUsers: 'http://localhost:5001/maxfit-app/us-central1/retrieveAuthInfoUsers',
    resetAllLeadersCoachedClasses: 'http://localhost:5001/maxfit-app/us-central1/resetAllLeadersCoachedClasses',
    sendNotificationToTopic: 'http://localhost:5001/maxfit-app/us-central1/sendNotificationToTopic',
    addUserToManyTopics: 'http://localhost:5001/maxfit-app/us-central1/addUserToManyTopics',
}
const development = {
    getAllUsers: 'https://us-central1-maxpro-dev.cloudfunctions.net/getFullUsers',
    searchByEmail: 'https://us-central1-maxpro-dev.cloudfunctions.net/getUserByEmail',
    disableUser: 'https://us-central1-maxpro-dev.cloudfunctions.net/disableUser',
    sendResetPasswordEmail: 'https://us-central1-maxpro-dev.cloudfunctions.net/sendResetPasswordEmail',
    sendVerificationEmail: 'https://us-central1-maxpro-dev.cloudfunctions.net/sendVerificationEmail',
    getAdminEmail: 'https://us-central1-maxpro-dev.cloudfunctions.net/getAdminEmail',
    sendMassiveNotification: 'https://us-central1-maxpro-dev.cloudfunctions.net/sendMassiveNotification',
    sendUserNotification: 'https://us-central1-maxpro-dev.cloudfunctions.net/sendUserNotification',
    getUserSubCollections: 'https://us-central1-maxpro-dev.cloudfunctions.net/getUserSubCollections',
    saveFromAddressFeedback: 'https://us-central1-maxpro-dev.cloudfunctions.net/saveFromAddressFeedback',
    querieForSaveMetadata: 'https://us-central1-maxpro-dev.cloudfunctions.net/querieForSaveMetadata',
    coachedClassesResetLeaderboards: 'https://us-central1-maxpro-dev.cloudfunctions.net/coachedClassesResetLeaderboards',
    retrieveAuthInfoUsers: 'https://us-central1-maxpro-dev.cloudfunctions.net/retrieveAuthInfoUsers',
    sendNotificationToTopic: 'https://us-central1-maxpro-dev.cloudfunctions.net/sendNotificationToTopic',
    addUserToManyTopics: 'https://us-central1-maxpro-dev.cloudfunctions.net/addUserToManyTopics',
}
const production = {
    getAllUsers: 'https://us-central1-maxfit-app.cloudfunctions.net/getFullUsers',
    searchByEmail: 'https://us-central1-maxfit-app.cloudfunctions.net/getUserByEmail',
    disableUser: 'https://us-central1-maxfit-app.cloudfunctions.net/disableUser',
    sendResetPasswordEmail: 'https://us-central1-maxfit-app.cloudfunctions.net/sendResetPasswordEmail',
    sendVerificationEmail: 'https://us-central1-maxfit-app.cloudfunctions.net/sendVerificationEmail',
    getAdminEmail: 'https://us-central1-maxfit-app.cloudfunctions.net/getAdminEmail',
    sendMassiveNotification: 'https://us-central1-maxfit-app.cloudfunctions.net/sendMassiveNotification',
    sendUserNotification: 'https://us-central1-maxfit-app.cloudfunctions.net/sendUserNotification',
    getUserSubCollections: 'https://us-central1-maxfit-app.cloudfunctions.net/getUserSubCollections',
    saveFromAddressFeedback: 'https://us-central1-maxfit-app.cloudfunctions.net/saveFromAddressFeedback',
    querieForSaveMetadata: 'https://us-central1-maxfit-app.cloudfunctions.net/querieForSaveMetadata',
    coachedClassesResetLeaderboards: 'https://us-central1-maxfit-app.cloudfunctions.net/coachedClassesResetLeaderboards',
    retrieveAuthInfoUsers: 'https://us-central1-maxfit-app.cloudfunctions.net/retrieveAuthInfoUsers',
    sendNotificationToTopic: 'https://us-central1-maxfit-app.cloudfunctions.net/sendNotificationToTopic',
    addUserToManyTopics: 'https://us-central1-maxfit-app.cloudfunctions.net/addUserToManyTopics',
}

const requestEndPoint = production
export { requestEndPoint }