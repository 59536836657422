import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { v4 as uuidv4 } from "uuid";
import { requestEndPoint } from '@/store/Api'

Vue.use(VueAxios, Axios);

const db = firebase.firestore();

const storage = firebase.storage().ref("onDemandVideos/");
const storageImages = firebase.storage().ref("onDemandPreviewImages/");

export default {
    state: {
        loadingStatusOnDemand: 0,
        statusImageOnDemand: 0,
        onDemandList: [],
        onDemandCounterReproductor: {},
        unsubscribeOnDemandList: null,
        unsubscribeOnDemandCounterReproductor: null
    },
    mutations: {
        SET_VALUE_LOADING_STATUS_ON_DEMAND(state, value) {
            state.loadingStatusOnDemand = value
        },
        SET_VALUE_LOADING_IMAGE_ON_DEMAND(state, value) {
            state.statusImageOnDemand = value
        },
        SET_ON_DEMAND_LIST(state, onDemandList) {
            state.onDemandList = onDemandList;
        },
        SET_UNSUBSCRIBE_ON_DEMAND_LIST(state, unsubscribe) {
            state.unsubscribeOnDemandList = unsubscribe;
        },
        GO_UNSUBSCRIBE_ON_DEMAND_LIST(state) {
            state.unsubscribeOnDemandList();
        },
        SET_ON_DEMAND_COUNTER_REPRODUCTOR(state, counterReproductor) {
            state.onDemandCounterReproductor = counterReproductor;
        },
        SET_UNSUBSCRIBE_ON_DEMAND_COUNTER_REPRODUCTOR(state, unsubscribe) {
            state.unsubscribeOnDemandCounterReproductor = unsubscribe;
        },
        GO_UNSUBSCRIBE_ON_DEMAND_COUNTER_REPRODUCTOR(state) {
            state.unsubscribeOnDemandCounterReproductor();
        },
    },
    actions: {
        UploadVideoOnDemand({ commit }, onDemand) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storage.child(random);
                let bytes = new Blob([new Uint8Array(onDemand.principalVideo.blob)]);
                console.log('storageRef', storageRef.name);
                let metadata = {
                    contentType: 'video/mp4'
                }
                let uploadTask = storageRef.put(bytes, metadata)
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_ON_DEMAND', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        UploadImagePreviewOnDemand({ commit }, onDemand) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(onDemand.selectedImagePreview.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_IMAGE_ON_DEMAND', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        CreateSetOnDemand({}, onDemand) {
            return new Promise(async(resolve, reject) => {
                delete onDemand.principalVideo.video
                delete onDemand.selectedImagePreview.img
                const ref = db.collection("onDemandList").doc();
                const newOnDemand = await ref.get();
                onDemand.id = ref.id;
                onDemand.createdAt = new Date()
                onDemand.updatedAt = new Date()
                await db.collection("onDemandList")
                    .doc(newOnDemand.id)
                    .set(onDemand)
                    .then(async(res) => {
                        await db.collection("countersAnalytics")
                            .doc("onDemandReproductions")
                            .update({
                                [newOnDemand.id]: 0
                            })
                            .then((resCounter) => {
                                console.log('newOnDemand.id', newOnDemand.id);
                                resolve(newOnDemand.id);
                            })
                            .catch((errCounter) => {
                                reject(errCounter);
                            });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },
        GetOnDemandList({ commit, getters }) {
            return new Promise(async(resolve, reject) => {
                db.collection("onDemandList").get()
                    .then((querySnapshot) => {
                        let onDemandList = [];
                        querySnapshot.forEach((doc) => {
                            let onDemand = doc.data();
                            onDemand.counterReproductions = getters.getOnDemandCounterReproductor[onDemand.id] ? getters.getOnDemandCounterReproductor[onDemand.id] : 0
                            onDemandList.push(onDemand);
                        });
                        commit("SET_ON_DEMAND_LIST", onDemandList);
                        resolve(onDemandList);
                    }).catch((err) => {
                        reject(err)
                    })
            });
        },
        GetOnDemandPaginatedFirstPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                if (getters.getOnDemandList.length > 0) {
                    commit("GO_UNSUBSCRIBE_ON_DEMAND_LIST");
                }
                let unsubscribe = db.collection("onDemandList").orderBy('name').limit(itemsPerPage).onSnapshot((querySnapshot) => {
                        let onDemandList = [];
                        querySnapshot.forEach((doc) => {
                            let onDemand = doc.data()
                            onDemandList.push(onDemand);
                        });
                        commit("SET_ON_DEMAND_LIST", onDemandList);
                        resolve(onDemandList);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_ON_DEMAND_LIST", unsubscribe);
            });
        },
        GetOnDemandNextPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                commit("GO_UNSUBSCRIBE_ON_DEMAND_LIST");
                let unsubscribe = db.collection("onDemandList").orderBy('name').startAfter(getters.getOnDemandList[getters.getOnDemandList.length - 1].name).limit(itemsPerPage).onSnapshot((querySnapshot) => {
                        let onDemandList = [];
                        querySnapshot.forEach((doc) => {
                            let onDemand = doc.data()
                            onDemandList.push(onDemand);
                        });
                        console.log(onDemandList);
                        if (onDemandList.length > 0) {
                            commit("SET_ON_DEMAND_LIST", onDemandList);
                        }
                        resolve(onDemandList);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_ON_DEMAND_LIST", unsubscribe);
            });
        },
        GetOnDemandPrevPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                commit("GO_UNSUBSCRIBE_ON_DEMAND_LIST");
                let unsubscribe = db.collection("onDemandList").orderBy('name').endBefore(getters.getProTrainersList[0].name).limitToLast(itemsPerPage).onSnapshot((querySnapshot) => {
                        let onDemandList = [];
                        querySnapshot.forEach((doc) => {
                            let onDemand = doc.data()
                            onDemandList.push(onDemand);
                        });
                        commit("SET_ON_DEMAND_LIST", onDemandList);
                        resolve(onDemandList);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_ON_DEMAND_LIST", unsubscribe);
            });
        },
        GetOnDemandCounterReproductor({ commit }) {
            return new Promise(async(resolve, reject) => {
                db.collection("countersAnalytics").doc('onDemandReproductions').get()
                    .then((querySnapshot) => {
                        let counterReproductor = querySnapshot.data()
                        commit("SET_ON_DEMAND_COUNTER_REPRODUCTOR", counterReproductor);
                        resolve(counterReproductor);
                    }).catch((err) => {
                        reject(err)
                    })
            });
        },
        GoUnsuscribeGetOnDemandList({ commit }) {
            commit("GO_UNSUBSCRIBE_ON_DEMAND_LIST");
        },
        GoUnsuscribeGetOnDemandCounterReproductor({ commit }) {
            commit("GO_UNSUBSCRIBE_ON_DEMAND_COUNTER_REPRODUCTOR");
        },
        DeleteOnDemand({}, onDemand) {
            return new Promise(async(resolve, reject) => {
                let storageRef = storage.child(onDemand.principalVideo.ref);
                let storageRefImage = storageImages.child(onDemand.selectedImagePreview.ref);

                await storageRefImage.delete()
                    .then(async() => {
                        await storageRef.delete()
                            .then(async() => {
                                await db.collection("onDemandList")
                                    .doc(onDemand.id)
                                    .delete()
                                    .then((res) => {
                                        db.collection("countersAnalytics").doc('onDemandReproductions').update({
                                            [onDemand.id]: firebase.firestore.FieldValue.delete()
                                        })
                                        resolve(onDemand.id);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            }).catch((errorImg) => {
                                console.log('errorImg deleting storage', errorImg);
                                // Uh-oh, an error occurred!
                            });
                        // File deleted successfully
                    }).catch((error) => {
                        console.log('error deleting storage', error);
                        // Uh-oh, an error occurred!
                    });


            });
        },
        UpdateOnDemand({}, onDemand) {
            return new Promise(async(resolve, reject) => {
                if (onDemand.principalVideo.video) {
                    delete onDemand.principalVideo.video
                }
                if (onDemand.selectedImagePreview.img) {
                    delete onDemand.selectedImagePreview.img
                }
                if(onDemand.createdAt) {
                    delete onDemand.createdAt
                }
                onDemand.updatedAt = new Date()
                await db.collection("onDemandList")
                    .doc(onDemand.id)
                    .update(onDemand)
                    .then((res) => {
                        resolve(onDemand.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        RunQueryUpdateAllUsers({}) {
            return new Promise(async(resolve, reject) => {
                Vue.axios
                    .post(requestEndPoint.querieForSaveMetadata, {}, { accept: 'Application/json' })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        }
    },
    getters: {
        getLoadingStatusOnDemand(state) {
            return state.loadingStatusOnDemand;
        },
        getLoadingImageOnDemand(state) {
            return state.statusImageOnDemand;
        },
        getOnDemandList(state) {
            return state.onDemandList;
        },
        getOnDemandCounterReproductor(state) {
            return state.onDemandCounterReproductor;
        }
    },
};