import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import InstantSearch from 'vue-instantsearch';
import VueJsonToCsv from 'vue-json-to-csv';

Vue.config.productionTip = false

Vue.use(InstantSearch);

router.beforeEach((to, from, next) => {
  let admin = store.getters.getAdmin

  if (to.matched.some(record => record.meta.forAdmin || record.meta.forCustomerService )) {
    if(Object.keys(admin).length > 0){
      if(admin.adminLevel !== 3){
        let matched = false
        const verifyRoute = to.matched.some((record) => {
          if(Object.keys(admin).length > 0){
            admin.allowedRoutes.some((route)=> {
                matched = route == record.name
                return matched
            })
            return matched
          }
        })
        if(!verifyRoute){
          let isSafeRoute = to.matched.some(record => {
            return record.name === 'User List'
          })
          if(!isSafeRoute){
            next({ path: '/admin/user-list' })
          }else{
            next()
          }
        }else{
          next()
        }
      }else{
        next();
      }
    }else{
      next({ path: '/' })
    }
  
  }else{
    if(to.matched.some(record => {record.name != 'Home'})){
      next({ path: '/' })
    }else{
      next()
    }
  }


})

Vue.component('vue-json-to-csv', VueJsonToCsv);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
