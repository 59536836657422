import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { v4 as uuidv4 } from "uuid";

const db = firebase.firestore();
const storage = firebase.storage();

const reactAppExercisesStorageVideos =  firebase.storage().ref("reactAppExercisesVideos/");
const reactAppExercisesStorageImages =  firebase.storage().ref("reactAppExercisesImages/");

const uniqueExercisesStorageVideos =  firebase.storage().ref("uniqueExercisesVideos/");
const uniqueExercisesStorageImages =  firebase.storage().ref("uniqueExercisesImages/");

// let start = null; 
// let end = null;
// let listeners = []
// let listExercises = []
export default {
  state: {
    exercises: [],
    exercisesModuleUnsubscribe: {},
    imageLoadingStatus:{
      imageAlex:0,
      imageJustin:0,
      imageShauna:0,
      imageVeon:0
    },
    videoLoadingStatus:{
      videoAlex:0,
      videoJustin:0,
      videoShauna:0,
      videoVeon:0
    },
    videoTrainerLoadingStatus: 0,
    imageTrainerLoadingStatus: 0,
    uniqueVideoLoadingStatus:0,
    uniqueImageLoadingStatus:0,
  },
  mutations: {
    SET_EXERCISES(state, exercises) {
      state.exercises = exercises;
    },
    SET_CONCAT_EXERCISES(state, exercisesNew) {
      state.exercises = state.exercises.concat(exercisesNew)
    },
    SET_UNSUBSCRIBE_EXERCISES_MODULE(state, unsubscribe) {
      Vue.set(
        state.exercisesModuleUnsubscribe,
        unsubscribe.type,
        unsubscribe.query
      );
    },
    GO_UNSUBSCRIBE_EXERCISES_MODULE(state, type) {
      state.exercisesModuleUnsubscribe[type]();
    },
    RESET_UNSUBSCRIBE_EXERCISES_MODULE(state) {
      state.exercisesModuleUnsubscribe = null;
    },
    SET_EXERCISE_VIDEO_LOADING_STATUS(state, loading){
      Vue.set(
        state.videoLoadingStatus,
        loading.trainer,
        loading.status
      );
    },
    SET_EXERCISE_IMAGE_LOADING_STATUS(state, loading){
      Vue.set(
        state.imageLoadingStatus,
        loading.trainer,
        loading.status
      );
    },
    SET_TRAINER_VIDEO_LOADING_STATUS(state, progress){
      state.videoTrainerLoadingStatus = progress
    },
    SET_TRAINER_IMAGE_LOADING_STATUS(state, progress){
      state.imageTrainerLoadingStatus = progress
    },
    SET_UNIQUE_VIDEO_LOADING_STATUS(state, progress){
      state.uniqueVideoLoadingStatus = progress
    },
    SET_UNIQUE_IMAGE_LOADING_STATUS(state, progress){
      state.uniqueImageLoadingStatus = progress
    },
    CLEAN_IMAGE_LOADING_STATUS(state){
      state.imageLoadingStatus = {
        imageAlex:0,
        imageJustin:0,
        imageShauna:0,
        imageVeon:0
      }
    },
    CLEAN_VIDEO_LOADING_STATUS(state){
      state.videoLoadingStatus = {
        videoAlex:0,
        videoJustin:0,
        videoShauna:0,
        videoVeon:0
      }
    }
  },
  actions: {
    async UpdateTwoHandsExercises(){
      return new Promise(async (resolve, reject) => {
        try {
          const querySnapshot = await db.collection('excercisesNew').get()
          let exercises = []
          for (let i = 0; i < querySnapshot.docs.length; i++) {
            let exercise = querySnapshot.docs[i].data();
            exercise.twoHands = true
            await db.collection('excercisesNew').doc(querySnapshot.docs[i].id).set(exercise)
            console.log('documents updated:', i+1);
            exercises.push(exercise)
          }
          console.log(exercises);
          resolve(exercises)
        } catch (error) {
          reject(error)
        }
      })
    },
    async UpdateGrandFatheredExercises(){
      return new Promise(async (resolve, reject) => {
        try {
          const querySnapshot = await db.collection('e').get()
          let exercises = []
          for (let i = 0; i < querySnapshot.docs.length; i++) {
            let exercise = querySnapshot.docs[i].data();
            await db.collection('excercisesNew').doc(querySnapshot.docs[i].id).update({ isGrandFathered: false })
            console.log('documents updated:', i+1);
            exercises.push(exercise)
          }
        } catch (error) {
          console.log(error);
          reject(error)
        }
      })
    },
    GetBatchExercises({ commit }, options){
        // console.log('asdads');

        let ref = db.collection('excercises')
        // single query to get startAt snapshot
        ref.orderBy('name.ENG', 'asc')
        // .startAt(start).endBefore(end)
        .limit(options.itemsPerPage)
        .onSnapshot((exercises) => {
          console.log('exercises', exercises);
          exercises.forEach((exercise) => {
            // exercises = listExercises.filter(x => x.id !== exercise.id)
             listExercises.push(exercise.data())
            })
            console.log(listExercises);
        })
    },
    QueryPassExercisesToNewCollection({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
            const querySnapshot = await db.collection("excercises").get()
            const exercises = []
            let i = 0
            querySnapshot.forEach(async(doc) =>  {
              let exercise = doc.data();
              exercise.id = doc.id;
              // exercises.push(exercise)
              if(!exercise.trainersList){
                exercise.isReactNativeExercise = true
                // console.log(`${exercise.name['ENG']}::::${exercise.id}`)
                await db.collection("excercisesNew").doc(exercise.id).set(exercise)
                i++
                console.log(i);
              }

            })
            resolve(exercises)
        } catch(error) {
            reject(error)
        }
      })
    },
    UpdateVideosAndImagesExercises({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
            const querySnapshot = await db.collection("excercisesNew").where("enabled", "==", true).get()
            const exercisesNewList = []
            let index = 0

            for (let i = 0; i < querySnapshot.docs.length; i++) {
              const doc = querySnapshot.docs[i];
              let exerciseNew = doc.data();
              exerciseNew.id = doc.id;
              exercisesNewList.push(exerciseNew)
              index++
              exerciseNew.trainersList = exerciseNew.trainersList ? exerciseNew.trainersList : []
              if(exerciseNew.imageAlex){
                exerciseNew.trainersList.push({
                  exImage: {
                    file:"jpeg",
                    link: exerciseNew.imageAlexLink,
                    ref: exerciseNew.imageAlex,
                    size: exerciseNew.imageAlexSize
                  },
                  exVideo: {
                    file:"mp4",
                    link: exerciseNew.videoAlexLink,
                    ref: exerciseNew.videoAlex,
                    size: exerciseNew.videoAlexSize
                  },
                  id:"3l53JJQVant4pmthfxtW",
                  firstName:"Alex",
                  lastName:"MaxPro"
                })
              }   
              if(exerciseNew.imageJustin){
                exerciseNew.trainersList.push({
                  exImage: {
                    file:"jpeg",
                    link: exerciseNew.imageJustinLink,
                    ref: exerciseNew.imageJustin,
                    size: exerciseNew.imageJustinSize
                  },
                  exVideo: {
                    file:"mp4",
                    link: exerciseNew.videoJustinLink,
                    ref: exerciseNew.videoJustin,
                    size: exerciseNew.videoJustinSize
                  },
                  id:"KIxekMxUeDW4oFTq9QAx",
                  firstName:"Justin",
                  lastName:"MaxPro"
                })
              }   
              if(exerciseNew.imageShauna){
                exerciseNew.trainersList.push({
                  exImage: {
                    file:"jpeg",
                    link: exerciseNew.imageShaunaLink,
                    ref: exerciseNew.imageShauna,
                    size: exerciseNew.imageShaunaSize
                  },
                  exVideo: {
                    file:"mp4",
                    link: exerciseNew.videoShaunaLink,
                    ref: exerciseNew.videoShauna,
                    size: exerciseNew.videoShaunaSize
                  },
                  id: "bZeUIPsD46aQQEyiLrzC",
                  firstName:"Shauna",
                  lastName:"MaxPro"
                })
              }   
              if(exerciseNew.imageVeon){
                exerciseNew.trainersList.push({
                  exImage: {
                    file:"jpeg",
                    link: exerciseNew.imageVeonLink,
                    ref: exerciseNew.imageVeon,
                    size: exerciseNew.imageVeonSize
                  },
                  exVideo: {
                    file:"mp4",
                    link: exerciseNew.videoVeonLink,
                    ref: exerciseNew.videoVeon,
                    size: exerciseNew.videoVeonSize
                  },
                  id: "ETsQb742IZL5okmlUMuv",
                  firstName:"Veon",
                  lastName:"MaxPro"
                })
              }
              await db.collection("excercisesNew").doc(doc.id).update({ trainersList: exerciseNew.trainersList })
              console.log(index + ' Documents updated')
            }
            // commit('SET_CONCAT_EXERCISES', exercisesNewList)
            console.log(exercisesNewList);
            resolve(exercisesNewList)
        } catch(error) {
            reject(error)
        }
      })
    },
    GetExercises({ commit }) {
      return new Promise((resolve, reject) => {

        db.collection("excercises").get()
        .then((querySnapshot)=>{
          let exercises = [];
          let i = 0
          querySnapshot.forEach((doc) => {
            let exercise = doc.data();
            exercise.id = doc.id;
            exercise.tableId = exercise.isReactNativeExercise ? `excercises-NewApp-${i}-${doc.id}`: `excercises-OldApp-${i}-${doc.id}`
            exercise.collectionName = 'excercises'
            exercises.push(exercise);
            i++
          });
          commit("SET_EXERCISES", exercises);
          resolve(exercises);
        }).catch((err) => {
          reject(err)
        })
      });
    },
    GetExercisesNew({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
            const querySnapshot = await db.collection("excercisesNew").get()
            const exercisesNewList = []
            let i = 0
            querySnapshot.forEach((doc) =>  {
              let exerciseNew = doc.data();
              exerciseNew.id = doc.id;
              exerciseNew.tableId = exerciseNew.isReactNativeExercise ? `excercisesNew-NewApp-${i}-${doc.id}`: `excercisesNew-OldApp-${i}-${doc.id}`
              exerciseNew.collectionName = 'excercisesNew'
              exercisesNewList.push(exerciseNew)
              i++
            })
            commit('SET_CONCAT_EXERCISES', exercisesNewList)
            resolve(exercisesNewList)
        } catch(error) {
            reject(error)
        }
      })
    },
    GetExercisesListPaginatedFirstPage({ commit, getters }, itemsPerPage) {
      return new Promise(async (resolve, reject) => {
        if(getters.getExercises.length > 0){
          commit("GO_UNSUBSCRIBE_EXERCISES_MODULE", "exercise");
        }
        let getExercisesQuery = db.collection("excercises").orderBy('name.ENG').limit(itemsPerPage).onSnapshot((querySnapshot) => {
            let exercises = [];
            querySnapshot.forEach((doc) => {
              let exercise = doc.data()
              exercise.id = doc.id;
              exercises.push(exercise);
            });
            commit("SET_EXERCISES", exercises);
            resolve(exercises);
          },
          (error) => {
            reject(error);
          }
        );
        commit("SET_UNSUBSCRIBE_EXERCISES_MODULE", {
          type: "exercise",
          query: getExercisesQuery,
        })
      });
    },
    GetExercisesNextPage({ commit, getters }, itemsPerPage){
      return new Promise(async (resolve, reject) => {
        commit("GO_UNSUBSCRIBE_EXERCISES_MODULE", "exercise");
        let getExercisesQuery = db.collection("excercises").orderBy('name.ENG').startAfter(getters.getExercises[getters.getExercises.length-1].name['ENG']).limit(itemsPerPage).onSnapshot((querySnapshot) => {
            let exercises = [];
            querySnapshot.forEach((doc) => {
              let exercise = doc.data()
              exercise.id = doc.id;
              exercises.push(exercise);
            });
            if(exercises.length > 0){
              commit("SET_EXERCISES", exercises);
            }
            resolve(exercises);
          },
          (error) => {
            reject(error);
          }
        );
        commit("SET_UNSUBSCRIBE_EXERCISES_MODULE", {
          type: "exercise",
          query: getExercisesQuery,
        })
      });
    },
    GetExercisesPrevPage({ commit, getters }, itemsPerPage){
      return new Promise(async (resolve, reject) => {
        commit("GO_UNSUBSCRIBE_EXERCISES_MODULE", "exercise");
        let getExercisesQuery =db.collection("excercises").orderBy('name.ENG').endBefore(getters.getExercises[0].name['ENG']).limitToLast(itemsPerPage).onSnapshot((querySnapshot) => {
            let exercises = [];
            querySnapshot.forEach((doc) => {
              let exercise = doc.data()
              exercise.id = doc.id;
              exercises.push(exercise);
            });
            commit("SET_EXERCISES", exercises);
            resolve(exercises);
          },
          (error) => {
            reject(error);
          }
        );
        commit("SET_UNSUBSCRIBE_EXERCISES_MODULE", {
          type: "exercise",
          query: getExercisesQuery,
        })
      });
    },
    GetExercise({ dispatch }, exerciseToGet){
      return new Promise((resolve, reject) => {
        const collectionName = exerciseToGet.isReactNativeExercise ? 'excercisesNew' : 'excercises'
        const id = exerciseToGet.id
        db.collection(collectionName)
          .doc(id).get().then(async(doc) => {
          let exercise = doc.data()
          exercise.storageData = {}
          if(exercise.subscription && exercise.subscription != ""){
            let subscription = await exercise.subscription.get()
            exercise.subscription = subscription.data()
          }
          let imagesAndVideos = [
            "imageAlex",
            "videoAlex",
            "imageJustin",
            "videoJustin",
            "imageShauna",
            "videoShauna",
            "imageVeon",
            "videoVeon",
          ];
           await imagesAndVideos.forEach((trainer) => {
             if (exercise[trainer] !== null) {
              dispatch("GetDownloadUrlRef", exercise[trainer]).then(async (url)=>{ Vue.set(exercise.storageData, trainer, url) })
             }else{
              exercise.storageData[trainer] = null
             }  
           });
          resolve(exercise)  
        }).catch((err) => {
          reject(err);
        })
      });
    },
    GoUnsubscribeExercisesModule({ commit }, type) {
      commit("GO_UNSUBSCRIBE_EXERCISES_MODULE", type);
    },
    async GetDownloadUrlRef({}, link) {
      return await new Promise(async(resolve, reject) => {
        // console.log('linkkkk download url', link);
        if (link === null) {
          resolve(undefined);
        } else {
          await storage.refFromURL(link).getDownloadURL()
            .then((downloadUrl) => {
              resolve(downloadUrl);
          });
        }
      });
    },
    DeleteExercise({}, exercise){
      return new Promise(async (resolve, reject) => {
        // this query does not delete storage file because exist two different collections with references to storage files and can be generate errors by deleted files
        const collectionName = exercise.isReactNativeExercise ? 'excercisesNew' : 'excercises'
        db.collection(collectionName)
          .doc(exercise.id)
          .delete()
          .then((res) => {
              resolve(exercise.id);
          })
          .catch((err) => {
              reject(err);
          });

      })

    },
    DeleteExerciseFileStorage({}, fileReference){
      return new Promise(async (resolve, reject) => {
        let gsReference = storage.refFromURL(fileReference)
        await gsReference.delete()
        .then(()=>{
          resolve(true)
        }).catch((err) => {
          reject(err)
        })
      })

    },
    UploadVideoExercise({ commit }, videoExercise){
      return new Promise((resolve, reject) => {
        let linkReference = `gs://maxfit-app.appspot.com/exercises-videos-notitle/${videoExercise.name}`
        let gsReference = storage.refFromURL(linkReference)

        let uploadTask = gsReference.putString(videoExercise.vid, "data_url")
        uploadTask.on('state_changed', (listener) => {
          let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
          commit('SET_EXERCISE_VIDEO_LOADING_STATUS', {trainer:videoExercise.trainer, status:progress})
        }, async (error) =>{
          console.log('error', error);
           reject(error)
        },async() => {
          // console.log('linkReference', linkReference);
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve({ link: downloadURL, ref: linkReference });
          })
  
        });
      })
    },
    UploadUniqueVideo({ commit }, video){
      return new Promise((resolve, reject) => {
          let random = uuidv4();
          let storageRef = uniqueExercisesStorageVideos.child(random);

          console.log('storageRef', storageRef.name);
          console.log('video', video);
          let metadata = {
              contentType: 'video/mp4'
          }
          let uploadTask = storageRef.putString(video.vid, "data_url", metadata)
          uploadTask.on('state_changed', (listener) => {
              let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
              commit('SET_UNIQUE_VIDEO_LOADING_STATUS', progress);
            }, (error) => {
              console.log('error', error);
              reject(error)
            }, () => {
              commit('SET_UNIQUE_VIDEO_LOADING_STATUS', 0);
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  resolve({ link: downloadURL, ref: storageRef.name });
              })
          })
      });
    },
    UploadUniqueImage({ commit }, image){
      return new Promise((resolve, reject) => {
          let random = uuidv4();
          let storageRef = uniqueExercisesStorageImages.child(random);

          console.log('storageRef', storageRef.name);

          console.log('image', image)

          let uploadTask = storageRef.putString(image.img, "data_url")
          uploadTask.on('state_changed', (listener) => {
              let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
              commit('SET_UNIQUE_IMAGE_LOADING_STATUS', progress);
            }, (error) => {
              console.log('error', error);
              reject(error)
            }, () => {
              commit('SET_UNIQUE_IMAGE_LOADING_STATUS', 0);
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  resolve({ link: downloadURL, ref: storageRef.name });
              })
          })
      });
    },
    UploadTrainerVideo({ commit }, videoTrainer){
      return new Promise((resolve, reject) => {
          let random = uuidv4();
          let storageRef = reactAppExercisesStorageVideos.child(random);

          console.log('storageRef', storageRef.name);
          console.log('videoTrainer', videoTrainer);
          let metadata = {
              contentType: 'video/mp4'
          }
          let uploadTask = storageRef.putString(videoTrainer.video, "data_url", metadata)
          uploadTask.on('state_changed', (listener) => {
              let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
              commit('SET_TRAINER_VIDEO_LOADING_STATUS', progress);
            }, (error) => {
              console.log('error', error);
              reject(error)
            }, () => {
              commit('SET_TRAINER_VIDEO_LOADING_STATUS', 0);
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  resolve({ link: downloadURL, ref: storageRef.name });
              })
          })
      });
    },
    UploadTrainerImage({ commit }, imageTrainer){
      return new Promise((resolve, reject) => {
          let random = uuidv4();
          let storageRef = reactAppExercisesStorageImages.child(random);

          console.log('storageRef', storageRef.name);

          console.log('imageTrainer', imageTrainer)

          let uploadTask = storageRef.putString(imageTrainer.img, "data_url")
          uploadTask.on('state_changed', (listener) => {
              let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
              commit('SET_TRAINER_IMAGE_LOADING_STATUS', progress);
            }, (error) => {
              console.log('error', error);
              reject(error)
            }, () => {
              commit('SET_TRAINER_IMAGE_LOADING_STATUS', 0);
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  resolve({ link: downloadURL, ref: storageRef.name });
              })
          })
      });
    },
    UploadImageExercise({ commit }, imageExercise){
      return new Promise((resolve, reject) => {
        let linkReference = `gs://maxfit-app.appspot.com/exercises-images-notitle/${imageExercise.name}`
        let gsReference = storage.refFromURL(linkReference)

        let uploadTask = gsReference.putString(imageExercise.img, "data_url")
        uploadTask.on('state_changed', (listener) => {
          let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
          commit('SET_EXERCISE_IMAGE_LOADING_STATUS', {trainer:imageExercise.trainer, status:progress})
        }, async (error) =>{
          console.log('error', error);
          reject(error)
        },async() => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve({ link: downloadURL, ref: linkReference });
          })
          // console.log('linkReference', linkReference);
        });
      })

    },
    async CreateExerciseNew({}, exercise){
      return new Promise(async(resolve, reject) => {
      if(exercise.subscription != ""){
        exercise.subscription = db.collection("subscriptionsList").doc(exercise.subscription.id)
      }
      const ref = db.collection("excercisesNew").doc();
      const newExercise = await ref.get();
      exercise.id = ref.id;
      exercise.createdAt = new Date()
      exercise.updatedAt = new Date()
      await db.collection("excercisesNew")
        .doc(newExercise.id)
        .set(exercise)
        .then((res) => {
          resolve(newExercise.id);
        })
        .catch((err) => {
          reject(err);
        });
      })
  },
    UpdateExercise({}, exercise) {
      return new Promise(async (resolve, reject) => {
        if(exercise.storageData){
          delete exercise.storageData
        }
        if(exercise.subscription && exercise.subscription != ""){
          exercise.subscription = db.collection("subscriptionsList").doc(exercise.subscription.id)
        }
        if(exercise.createdAt) {
          delete exercise.createdAt
        }
        exercise.updatedAt = new Date()
        const collectionName = exercise.isReactNativeExercise ? 'excercisesNew' : 'excercises'

        await db.collection(collectionName)
          .doc(exercise.id)
          .update(exercise)
          .then((res) => {
            resolve(exercise.id);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DisableExercise({}, exercise){
      return new Promise(async(resolve, reject) => {
          try {
              await db.collection(exercise.collectionName).doc(exercise.id).update({
                enabled: !exercise.enabled
              })
              resolve(exercise.id);
          } catch (error) {
              reject(error);
          }
      });

    },
    CleanImageLoader({ commit }){
      commit("CLEAN_IMAGE_LOADING_STATUS");
    },
    CleanVideoLoader({ commit }){
      commit("CLEAN_VIDEO_LOADING_STATUS");
    }
  },
  getters: {
    getExercises(state) {
      return state.exercises;
    },
    getUploadVideoLoading(state) {
      return state.videoLoadingStatus;
    },
    getUploadImageLoading(state) {
      return state.imageLoadingStatus;
    },
    getVideoTrainerLoadingStatus(state) {
      return state.videoTrainerLoadingStatus;
    },
    getImageTrainerLoadingStatus(state) {
      return state.imageTrainerLoadingStatus;
    },
    getUploadUniqueVideoLoading(state) {
      return state.uniqueVideoLoadingStatus;
    },
    getUploadUniqueImageLoading(state) {
      return state.uniqueImageLoadingStatus;
    },
  },
};
