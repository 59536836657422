<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="4">
        <p class="text-h6 text--secondary font-weight-bold">On Demand</p>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-btn
          text
          color="secondary"
          @click="createOnDemand()"
        >
        <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
         New On Demand
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          label="Search for a On demand"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>

      <!-- <v-col cols="12">
        <v-btn color="primary" @click="goRunQuerie()">
            Run query
        </v-btn>
      </v-col> -->
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getOnDemandList"
          :sort-desc="true"
          sort-by="counterReproductions"
          item-key="id"
          fixed-header
          class="elevation-4"
        >
          <!-- <template v-slot:[`item.id`]="{ item }">
            {{ getOnDemandCounterReproductor[item.id] ? getOnDemandCounterReproductor[item.id]: 0 }}
          </template> -->
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:[`item.listPrincipalVideoSections`]="{ item }">
            {{ item.listPrincipalVideoSections.length }}
          </template>
          <template v-slot:[`item.selectedAccessories`]="{ item }">
            {{ item.selectedAccessories.length }}
          </template>
          <template v-slot:[`item.withSmartMaxpro`]="{ item }">
            <h4 v-if="item.withSmartMaxpro" class="primary--text">Yes</h4>
            <template v-else>
              <h4 class="secondary--text">No</h4>
            </template>
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-switch
                color="primary"
                class="center-switch"
                :input-value="item.enabled"
                readonly
                flat
              ></v-switch>

                <!-- @click.stop="disableProTrainer(item)" -->
          </template>
          <template v-slot:[`item.action`]="{ item }">
           <v-btn icon color="primary" @click="changeRouteOnDemand(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              class="mr-3"
              @click="openDialogConfirm(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <!-- <template v-slot:footer="{}">
            <v-row justify="end">
              <v-col cols="1">
                <v-select
                  class="mr-auto"
                  v-model="itemsPerPage"
                  :items="[5,10,15,20,30]"
                >
              </v-select>
              </v-col>
              <v-col cols="2" class="text-right mr-3" align-self="center">
              <v-btn color="primary" x-small class="mx-1" :disabled="pageCounter === 1" @click="goPrevPageOnDemand()">
                <v-icon size="15">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <p class="d-inline">{{ pageCounter }}</p>
              <v-btn color="primary" x-small class="mx-1" :disabled="getOnDemandList.length < itemsPerPage || getOnDemandList.length === 0" @click="goNextPageOnDemand()">
                <v-icon size="15">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
              </v-col>
            </v-row>
          </template> -->
      
        </v-data-table>
      </v-col>
    </v-row>
      <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
      <v-card class="text-center">
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
        <v-icon class="text-center ma-2" size="120" color="primary"
          >mdi-alert</v-icon
        >
        <v-card-text class="pa-4 text-center"
          >This action is irreversible and will delete all data about the On Demand. Including the video, sections and information</v-card-text
        >
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeDialogConfirm()"
          >No</v-btn
        >

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="openDialogReAuth('deleteOnDemand', dialog.item)"
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
      </v-dialog>
       <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
      <modal-reauth :dialogReAuth="dialogReAuth" @isSuccessReAuth="isSuccessReAuth"></modal-reauth>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from '@/components/admin/modal-reauth.vue';
export default {
  name: "OnDemand",
  components: { modalReauth },
  data: () => ({
     dialogReAuth:{
      show: false,
      action: '',
      item:{}
    },
    dialog:{
      show:false,
      item:{},
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    headers: [
      // {
      //   text: "",
      //   align: "center",
      //   value: "viewMore",
      //   sortable: false,
      // },
      { text: "Order Number", align: "center", value: "orderNumber", sortable: true },
      {
        text: "Name",
        align: "center",
        value: "name",
        sortable: true,
      },
      {
        text: "Reproductions",
        align: "center",
        value: "counterReproductions",
        sortable: true,
      },
      {
        text: "Sections",
        align: "center",
        value: "listPrincipalVideoSections",
        sortable: true,
      },
      {
        text: "Accessories",
        align: "center",
        value: "selectedAccessories",
        sortable: true,
      },
      {
        text: "Need Smart Maxpro",
        align: "center",
        value: "withSmartMaxpro",
        sortable: true,
      },
      {
        text: "Type Workout",
        align: "center",
        value: "selectedChallengeWorkout.name",
        sortable: true,
      },
      {
        text: "Difficulty",
        align: "center",
        value: "selectedDifficulty",
        sortable: true,
      },
      {
        text: "Duration",
        align: "center",
        value: "totalDuration",
        sortable: true,
      },
      {
        text: "Enabled",
        align: "center",
        value: "enabled",
        sortable: true,
      },
      {
        text: "Action",
        align: "center",
        value: "action",
        sortable: false,
      }
    ],
    itemsPerPage:10,
    pageCounter:1,
    search:"",
    loading:false,
  }),
   created() {
    let vm = this;
        vm.loading = true;
        vm.$store
          .dispatch("GetOnDemandCounterReproductor")
          .then((resCounter) => {
            vm.getOnDemand()
          })
          .catch((errCounter) => {
            console.error(errCounter);
            vm.loading = false;
          });

  },
  methods: {
    goRunQuerie(){
      const vm = this
      vm.$store.dispatch('RunQueryUpdateAllUsers')
      .then((res)=>{
        console.log(res);
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    getOnDemand(){
      let vm = this
      vm.$store.dispatch('GetOnDemandList')
      .then((res)=>{
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
      
    },
    getFirstPageOnDemand(){
      let vm = this
      vm.$store.dispatch('GetOnDemandPaginatedFirstPage', vm.itemsPerPage)
      .then((res)=>{
        vm.pageCounter = 1
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    goNextPageOnDemand(){
      let vm = this
      vm.$store.dispatch('GetOnDemandNextPage', vm.itemsPerPage)
      .then((res)=>{
        if(res.length > 0){
          vm.pageCounter = vm.pageCounter + 1
        }
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    goPrevPageOnDemand(){
      let vm = this
      vm.$store.dispatch('GetOnDemandPrevPage', vm.itemsPerPage)
      .then((res)=>{
        vm.pageCounter = vm.pageCounter - 1
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    isSuccessReAuth(dialogReAuthResponse){
      const vm = this 
        switch (dialogReAuthResponse.action) {
          case 'deleteOnDemand':
            vm.deleteOnDemand(dialogReAuthResponse.item)
            break;
          default:
            break;
        }
    },
    openDialogReAuth(action, item){
      let vm = this;
      vm.dialogReAuth.show = true
      vm.dialogReAuth.action = action
      vm.dialogReAuth.item = item
      vm.closeDialogConfirm();
    },
    changeRouteOnDemand(item) {
      let vm = this;
      vm.$router.push({ name: "On Demand Edit Set", params: item })
      .catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      })
    },
    createOnDemand() {
      let vm = this;
      vm.$router.push({ name: "On Demand Create"})
      .catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      })
    },
    disableOnDemand(onDemand){
      const vm = this
      let data = {
        id: onDemand.id,
        enabled: !onDemand.enabled
      }
      vm.$store
      .dispatch("UpdateOnDemand", data)
      .then((res) => {
        // vm.loading = false;
      })
      .catch((err) => {
        console.error(err);
         let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error disabling OnDemand",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
      });
    },
    deleteOnDemand(onDemand){
      const vm = this
      vm.$emit("showOverlay", true);
      vm.$store
      .dispatch("DeleteOnDemand", onDemand)
      .then((res) => {
        vm.$store.dispatch("GenerateLog", {
          id: onDemand.id,
          title: onDemand.name,
          type: "deletedOnDemand",
        })
        vm.$emit("showOverlay", false);
        // vm.loading = false;
      })
      .catch((err) => {
        vm.$emit("showOverlay", false);
        console.error(err);
         let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error disabling On Demand",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
      });

    },
    openDialogConfirm(item){
      let vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      let vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
  },
  destroyed() {
    let vm = this;
    // vm.$store.dispatch("GoUnsuscribeGetOnDemandList");
    // vm.$store.dispatch("GoUnsuscribeGetOnDemandCounterReproductor");
  },
  computed: {
    ...mapGetters(["getOnDemandList"]),
  },
  // watch:{
  //   itemsPerPage:{
  //     handler(newValue){
  //       this.getFirstPage()
  //     }
  //   }
  // }
};
</script>
<style scoped>
  .center-switch > .v-input__control > .v-input__slot {
    justify-content: center !important;
  }

  .v-data-table /deep/ .sticky-header {
    position: sticky;
    top: var(--toolbarHeight);
  }

  .v-data-table /deep/ .v-data-table__wrapper {
    overflow: unset;
  }
</style>