import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";

const db = firebase.firestore();

const storageImages = firebase.storage().ref("preSetsImages/");

export default {
    state: {
        preSetsList: [],
        preSetStatusImage: 0,
        preSetsFilters: []
        // pwoCounterReproductor: {},
    },
    mutations: {
        SET_VALUE_LOADING_IMAGE_PRE_SET(state, value) {
            state.preSetStatusImage = value
        },
        SET_PRE_SET_LIST(state, preSetsList) {
            state.preSetsList = preSetsList;
        },
        SET_PRE_SET_FILTER_LIST(state, preSetsFilters) {
            state.preSetsFilters = preSetsFilters
        },
        ADD_ANY_VALUE_PRE_SET_FILTER_LIST(state, data) {
            let indexPrincipal = state.preSetsFilters.findIndex(filter => filter.type === data.doc)
            state.preSetsFilters[indexPrincipal].listFilters.push({ name: data.name, children: data.children })
        },
        DELETE_ANY_VALUE_PRE_SET_FILTER_LIST(state, data) {
            let indexPrincipal = state.preSetsFilters.findIndex(filter => filter.type === data.doc)
            let index = state.preSetsFilters[indexPrincipal].listFilters.indexOf(data.item.name)
            state.preSetsFilters[indexPrincipal].listFilters.splice(index, 1)
        },
        UPDATE_WITH_NEW_VALUES_PRE_SET_FILTER_LIST_CHILDREN(state, data) {
            let indexPrincipal = state.preSetsFilters.findIndex(filter => filter.type === data.doc)
            state.preSetsFilters[indexPrincipal].listFilters = data.updatedArray
        },
        UPDATE_FILTER_LIST_PRE_SET(state, data) {
            state.preSetsFilters.push({
                type: data.doc,
                listFilters: data.listFilters,
                name: data.name
            })
        },
        DELETE_VALUE_ROOT_FILTER_PRE_SET(state, data) {
            let indexPrincipal = state.preSetsFilters.findIndex(filter => filter.type === data.doc)
            state.preSetsFilters.splice(indexPrincipal, 1)
        }
    },
    actions: {
        GetPreSets({ commit }, notBindVuex) {
            return new Promise(async(resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("preSetsList").get()
                    const preSetsList = []
                    querySnapshot.forEach((doc) =>  {
                        let preSet = doc.data()
                        preSet.typeWorkout = 'preSet'
                        preSetsList.push(preSet)
                    })
                    if(!notBindVuex){
                     commit('SET_PRE_SET_LIST', preSetsList)
                    }
                    resolve(preSetsList)
                } catch(error) {
                    reject(error)
                }
            });
        },
        BackupPreSets({ commit }){
            return new Promise(async(resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("preSetsList").get()
                    const preSetsList = []
                    for (let i = 0; i < querySnapshot.docs.length; i++) {
                        let preSet = querySnapshot.docs[i].data();
                        preSetsList.push(preSet)
                        await db.collection("preSetsList-BACKUP-24-01-22").doc(querySnapshot.docs[i].id).set(preSet)
                        console.log('updated documents: ', i+1)
                    }
                    console.log(preSetsList);
                } catch (error) {
                    console.error(error);
                    reject(error)
                }

            })

        },
        UpdateTwoHands({ commit }) {
            return new Promise(async(resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("preSetsList").get()
                    const preSetsList = []
                    for (let i = 0; i < querySnapshot.docs.length; i++) {
                        let preSet = querySnapshot.docs[i].data();
                        if(preSet.typeBy === 'circuit' ){
                            for (let j = 0; j < preSet.circuits.length; j++) {
                                let circuit =  preSet.circuits[j]
                                for (let k = 0; k < circuit.exercises.length; k++) {
                                    let exercise = circuit.exercises[k]
                                    exercise.twoHands = true
                                    exercise.autoInit = false
                                }
                            }
                        }else if(preSet.typeBy === 'station'){
                            for (let x = 0; x < preSet.station.exercises.length; x++) {
                                let exercise = preSet.station.exercises[x]
                                exercise.twoHands = true
                                exercise.autoInit = false
                            }
                        }
                        await db.collection("preSetsList").doc(preSet.id).set(preSet)
                        console.log('documents updated:', i+1)
                        preSetsList.push(preSet);
                    }
                    

                    console.log(preSetsList)
                    resolve(preSetsList)
                } catch(error) {
                    reject(error)
                }
            });
        },
        GetPreSet({ }, preSet){
            return new Promise(async (resolve, reject) => {
                try {
                    let resPreSet = await db.collection("preSetsList").doc(preSet.id).get()
                    let gettedPreSet = resPreSet.data()
                    if(gettedPreSet.subscription && gettedPreSet.subscription != ""){
                        let subscription = await gettedPreSet.subscription.get()
                        gettedPreSet.subscription = subscription.data()
                    }
                    gettedPreSet.endDay = gettedPreSet.endDay ? gettedPreSet.endDay.toDate() : ''
                    resolve(gettedPreSet)
                } catch(error) {
                    reject(error)
                }
            })
        },
        UploadImagePreSet({ commit }, preSet) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(preSet.image.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_IMAGE_PRE_SET', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        CreatePreSet({}, preSet) {
            return new Promise(async(resolve, reject) => {
                try {
                    delete preSet.image.img
                    if(preSet.subscription != ""){
                     preSet.subscription = db.collection("subscriptionsList").doc(preSet.subscription.id)
                    }
                    const ref = db.collection("preSetsList").doc();
                    const newPreSet = await ref.get();
                    preSet.id = ref.id;
                    preSet.createdAt = new Date()
                    preSet.updatedAt = new Date()
                    await db.collection("preSetsList").doc(newPreSet.id).set(preSet)
                    resolve(newPreSet.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        CopyPreSet({}, id) {
            return new Promise(async(resolve, reject) => {
                try {
                    const preSet = await (await db.collection("preSetsList").doc(id).get()).data()

                    const ref = db.collection("preSetsList").doc();
                    const newPreSet = await ref.get();
                    preSet.id = ref.id;
                    preSet.enable = false;
                    preSet.dynamicLink = '';
                    preSet.name['ENG'] = preSet.name['ENG'] + " Copy";
                    await db.collection("preSetsList").doc(newPreSet.id).set(preSet)
                    resolve(preSet);
                } catch (error) {
                    reject(err);
                }
            })
        },
        DeletePreSet({}, preSet) {
            return new Promise(async(resolve, reject) => {
                try {
                    // let storageRefImage = storageImages.child(preSet.image.ref);
                    // await storageRefImage.delete()
                    await db.collection("preSetsList").doc(preSet.id).delete()
                    resolve(preSet.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
        UpdatePreSet({}, preSet) {
            return new Promise(async(resolve, reject) => {
                try {
                    if (preSet.image.img) {
                        delete preSet.image.img
                    }
                    if (preSet.counterReproductions.img) {
                        delete preSet.counterReproductions
                    }
                    if (preSet.createdAt) {
                        delete preSet.createdAt
                    }
                    preSet.updatedAt = new Date()
                    if(preSet.subscription && preSet.subscription != ""){
                        preSet.subscription = db.collection("subscriptionsList").doc(preSet.subscription.id)
                    }
                    await db.collection("preSetsList").doc(preSet.id).update(preSet)
                    resolve(preSet.id);
                } catch (error) {
                    reject(err);
                }
            });
        },
        UpdatePresetValue({}, obj){
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("preSetsList").doc(obj.id).update(obj.data);
                    resolve(obj.id);
                } catch (error) {
                    console.log(error);
                    
                    reject(error);
                }
            });
        },
        //Filters
        GetFiltersListPreSets({ commit }) {
            return new Promise(async(resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("preSetsFilters").get()
                    const preSetsFilters = [];
                    querySnapshot.forEach((doc) => {
                        let filterPreSets = doc.data();
                        filterPreSets.type = doc.id
                        preSetsFilters.push(filterPreSets);
                    });
                    commit("SET_PRE_SET_FILTER_LIST", preSetsFilters);
                    resolve(preSetsFilters); 
                } catch (error) {
                    reject(error)
                }
            });
        },
        AddNewTypeToListOfFiltersPreSets({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                try {
                    const res = await db.collection("preSetsFilters")
                    .doc(data.doc)
                    .update({
                        listFilters: firebase.firestore.FieldValue.arrayUnion({ name: data.name, children: data.children })
                    })
                    commit("ADD_ANY_VALUE_PRE_SET_FILTER_LIST", data);
                    resolve(res);
                } catch (error) {
                    reject(error)
                }
            })
        },
        DeleteTypeToListOfFiltersPreSets({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                try {
                   const res = await db.collection("preSetsFilters")
                        .doc(data.doc)
                        .update({
                            listFilters: firebase.firestore.FieldValue.arrayRemove(data.item)
                        })
                        commit('DELETE_ANY_VALUE_PRE_SET_FILTER_LIST', data)
                        resolve(res);
                } catch (error) {
                        reject(error);
                }
            })
        },
        AddOrDeleteChildrenToListOfFiltersPreSets({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                try {
                    const res = await db.collection("preSetsFilters").doc(data.doc)
                    .update({
                        listFilters: data.updatedArray
                    })
                    commit("UPDATE_WITH_NEW_VALUES_PRE_SET_FILTER_LIST_CHILDREN", data);
                    resolve(res);
                } catch (error) {
                    reject(err);
                }
            })
        },
        CreateNewFilterPreSets({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                try {
                    const res = await db.collection("preSetsFilters").doc(data.doc)
                    .set({
                        name: data.name,
                        listFilters: data.listFilters,
                    })
                    commit("UPDATE_FILTER_LIST_PRE_SET", data);
                    resolve(res);
                } catch (error) {
                    reject(err);
                }
            })
        },
        DeleteFilterPreSets({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                try {
                    const res = await db.collection("preSetsFilters").doc(data.doc).delete()
                    commit("DELETE_VALUE_ROOT_FILTER_PRE_SET", data);
                    resolve(res);
                } catch (error) {
                    reject(err);
                }
            })
        },

    },
    getters: {
        getPreSets(state) {
            return state.preSetsList;
        },
        getLoadingImagePreSet(state) {
            return state.pwoStatusImage;
        },
        getPreSetsFilters(state) {
            return state.preSetsFilters
        },
    }
}