import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { v4 as uuidv4 } from "uuid";
import { requestEndPoint } from '@/store/Api'

Vue.use(VueAxios, Axios);

const db = firebase.firestore();

const storage = firebase.storage().ref("proTrainerVideos/");
const storageImages = firebase.storage().ref("proTrainerPreviewImages/");

export default {
    state: {
        loadingStatus: 0,
        statusImage: 0,
        statusSocialSharingImage:0,
        proTrainersList: [],
        proTrainersCounterReproductor: {},
        unsubscribeProTrainersList: null,
        unsubscribeProTrainersCounterReproductor: null,
        dateRange: {
            startDate: new Date(new Date().setHours(0, 0, 0, 0)),
            endDate: new Date(new Date().setHours(23, 59, 59, 999))
        },
        dateRangeChart: {
            startDate: new Date(new Date().setHours(0, 0, 0, 0)),
            endDate: new Date(new Date().setHours(23, 59, 59, 999))
        },
        proTrainerFilters: [],
        proTrainerLeaderboard:[],
        proTrainerTypeOfExercise: [],
        proTrainerTypeOfBody: [],
    },
    mutations: {
        SET_VALUE_LOADING_STATUS(state, value) {
            state.loadingStatus = value
        },
        SET_VALUE_LOADING_IMAGE(state, value) {
            state.statusImage = value
        },
        SET_VALUE_LOADING_SOCIAL_SHARING_IMAGE(state, value) {
            state.statusSocialSharingImage = value
        },
        SET_PRO_TRAINERS_LIST(state, proTrainersList) {
            state.proTrainersList = proTrainersList;
        },
        SET_PRO_COUNTER_REPRODUCTOR(state, counterReproductor) {
            state.proTrainersCounterReproductor = counterReproductor;
        },
        SET_UNSUBSCRIBE_PRO_TRAINERS_LIST(state, unsubscribe) {
            state.unsubscribeProTrainersList = unsubscribe;
        },
        GO_UNSUBSCRIBE_PRO_TRAINERS_LIST(state) {
            state.unsubscribeProTrainersList();
        },
        SET_UNSUBSCRIBE_PRO_TRAINERS_COUNTER_REPRODUCTOR(state, unsubscribe) {
            state.unsubscribeProTrainersCounterReproductor = unsubscribe;
        },
        GO_UNSUBSCRIBE_PRO_TRAINERS_COUNTER_REPRODUCTOR(state) {
            state.unsubscribeProTrainersCounterReproductor();
        },
        SET_DATE_RANGE(state, dateRange) {
            state.dateRange = dateRange
        },
        SET_DATE_RANGE_CHART(state, dateRangeChart) {
            state.dateRangeChart = dateRangeChart
        },
        SET_PRO_TRAINERS_FILTER_LIST(state, proTrainerFilters) {
            state.proTrainerFilters = proTrainerFilters
        },
        ADD_ANY_VALUE_PRO_TRAINERS_FILTER_LIST(state, data) {
            let indexPrincipal = state.proTrainerFilters.findIndex(filter => filter.type === data.doc)
                // state.proTrainerFilters[indexPrincipal][data.array].unshift(data.name)
            state.proTrainerFilters[indexPrincipal].listFilters.push({ name: data.name, children: data.children })
        },
        DELETE_ANY_VALUE_PRO_TRAINERS_FILTER_LIST(state, data) {
            let indexPrincipal = state.proTrainerFilters.findIndex(filter => filter.type === data.doc)
            let index = state.proTrainerFilters[indexPrincipal].listFilters.indexOf(data.item.name)
                // state.proTrainerFilters[indexPrincipal][data.array].splice(index, 1)
            state.proTrainerFilters[indexPrincipal].listFilters.splice(index, 1)
        },
        UPDATE_WITH_NEW_VALUES_PRO_TRAINERS_FILTER_LIST_CHILDREN(state, data) {
            let indexPrincipal = state.proTrainerFilters.findIndex(filter => filter.type === data.doc)
            state.proTrainerFilters[indexPrincipal].listFilters = data.updatedArray
        },
        UPDATE_FILTER_LIST_PRO_TRAINER(state, data) {
            state.proTrainerFilters.push({
                type: data.doc,
                listFilters: data.listFilters,
                name: data.name
            })
        },
        DELETE_VALUE_ROOT_FILTER_PRO_TRAINER(state, data) {
            let indexPrincipal = state.proTrainerFilters.findIndex(filter => filter.type === data.doc)
            state.proTrainerFilters.splice(indexPrincipal, 1)
        },
        SET_ACTUAL_LEADERBOARD(state, leaderboard){
            state.proTrainerLeaderboard = leaderboard
        },
        SET_PRO_TRAINER_TYPE_OF_EXERCISE_LIST(state, proTrainerTypeOfExercise){
            state.proTrainerTypeOfExercise = proTrainerTypeOfExercise
        },
        SET_PRO_TRAINER_TYPE_OF_BODY_LIST(state, proTrainerTypeOfBody){
            state.proTrainerTypeOfBody = proTrainerTypeOfBody
        }
    },
    actions: {
        QueryForCreateLeaderboardsCollection({}){
            return new Promise(async(resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("proTrainersList").get()
                    let proTrainersList = [];
                    let i = 0
                    querySnapshot.forEach(async(doc) => {
                        let proTrainer = doc.data();
                        proTrainersList.push(proTrainer);
                        await  db.collection("leaderboards").doc(doc.id).set({
                            workout: db.collection("proTrainersList").doc(doc.id),
                            workoutName: proTrainer.name
                        })
                        i++
                        console.log('workout updated:', i)
                    });
                    console.log(proTrainersList);
                    resolve(proTrainersList);                    
                } catch (error) {
                    
                }
            })

        },
        GetProTrain({ }, proTrain){
            return new Promise(async (resolve, reject) => {
                try {
                    let resProTrain = await db.collection("proTrainersList").doc(proTrain.id).get()
                    let gettedProTrain = resProTrain.data()
                    if(gettedProTrain.subscription && gettedProTrain.subscription != ""){
                        let subscription = await gettedProTrain.subscription.get()
                        gettedProTrain.subscription = subscription.data()
                    }
                    gettedProTrain.endDay = gettedProTrain.endDay ? gettedProTrain.endDay.toDate() : ''
                    resolve(gettedProTrain)
                } catch(error) {
                    reject(error)
                }
            })
        },
        UploadVideoProTrainer({ commit }, proTrainer) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storage.child(random);
                let bytes = new Blob([new Uint8Array(proTrainer.principalVideo.blob)]);
                console.log('storageRef', storageRef.name);
                let metadata = {
                    contentType: 'video/mp4'
                }
                let uploadTask = storageRef.put(bytes, metadata)
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        UploadImagePreviewProTrainer({ commit }, proTrainer) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(proTrainer.selectedImagePreview.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_IMAGE', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        UploadSocialSharingImageProTrainer({ commit }, proTrainer) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(proTrainer.socialSharingImage.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_SOCIAL_SHARING_IMAGE', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        CreateSetProTrainer({}, proTrainer) {
            return new Promise(async(resolve, reject) => {
                delete proTrainer.principalVideo.video
                delete proTrainer.selectedImagePreview.img
                if(proTrainer.socialSharingImage.img){
                    delete proTrainer.socialSharingImage.img
                }
                if(proTrainer.subscription && proTrainer.subscription != ""){
                    proTrainer.subscription = db.collection("subscriptionsList").doc(proTrainer.subscription.id)
                }
                const ref = db.collection("proTrainersList").doc();
                const newProTrainer = await ref.get();
                proTrainer.id = ref.id;
                proTrainer.createdAt = new Date()
                proTrainer.updatedAt = new Date()
                await db.collection("proTrainersList")
                    .doc(newProTrainer.id)
                    .set(proTrainer)
                    .then(async(res) => {
                        await db.collection("countersAnalytics")
                            .doc("workoutReproductions")
                            .update({
                                [newProTrainer.id]: 0
                            })
                            .then(async(resCounter) => {
                                console.log('newProTrainer.id', newProTrainer.id);
                                await  db.collection("leaderboards").doc(newProTrainer.id).set({
                                    workout: db.collection("proTrainersList").doc(newProTrainer.id),
                                    workoutName: proTrainer.name
                                })
                                resolve(newProTrainer.id);
                            })
                            .catch((errCounter) => {
                                reject(errCounter);
                            });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },
        async UpdateSize({ dispatch }){
            return new Promise(async(resolve, reject) => {
                //915195
                //1161521 1.1MB
                // const calcule = await dispatch('GetSizeFile', 1161521)
                // console.log(calcule);
                const querySnapshot = await db.collection("proTrainersList").get()
                let i = 0
                querySnapshot.forEach(async (doc) => {
                    const id = doc.id
                    let proTrainer = doc.data();
                    let referenceImg = storageImages.child(proTrainer.selectedImagePreview.ref)
                    let referenceVideo = storage.child(proTrainer.principalVideo.ref)
                    let referenceSocial = storageImages.child(proTrainer.socialSharingImage.ref)

                    let metadataImg = await referenceImg.getMetadata()
                    let metadataVideo = await referenceVideo.getMetadata()
                    let metadataSocial = await referenceSocial.getMetadata()

                    let sizeImg = await dispatch('GetSizeFile', metadataImg.size)
                    let sizeVideo = await dispatch('GetSizeFile', metadataVideo.size)
                    let sizeSocial = await dispatch('GetSizeFile', metadataSocial.size)

                    await db.collection("proTrainersList").doc(id).update({
                        'principalVideo.size': sizeVideo,
                        'principalVideo.sizeUnit': 'KB',

                        'selectedImagePreview.size': sizeImg,
                        'selectedImagePreview.sizeUnit': 'KB',

                        'socialSharingImage.size': sizeSocial,
                        'socialSharingImage.sizeUnit': 'KB',
                    })
                    i++
                    console.log(i);
                })
            })

        },
        GetSizeFile({}, bytes){
            return new Promise(async(resolve, reject) => {
                const thresh = 1024;

                if (Math.abs(bytes) < thresh) {
                    return bytes + ' B';
                }
                    //si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']; // original
                    // const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] all convertions
                const units = ['KB'] 
                let u = -1;
                const r = 10**2;

                do {
                    bytes /= thresh;
                    ++u;
                } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

                resolve(parseFloat(bytes.toFixed(2)))
            //    resolve(bytes.toFixed(2) + ' ' + units[u])

            })

        },
        GetProTrainersList({ commit, getters }) {
            return new Promise(async(resolve, reject) => {
               await db.collection("proTrainersList").get()
                    .then((querySnapshot) => {
                        let proTrainersList = [];
                        querySnapshot.forEach((doc) => {
                            let proTrainer = doc.data();
                            proTrainer.typeWorkout = 'proTrainer'
                            proTrainer.counterReproductions = getters.getProTrainersCounterReproductor[proTrainer.id] ? getters.getProTrainersCounterReproductor[proTrainer.id] : 0
                            proTrainersList.push(proTrainer);
                        });
                        commit("SET_PRO_TRAINERS_LIST", proTrainersList);
                        resolve(proTrainersList);
                    }).catch((err) => {
                        reject(err)
                    })
            });
        },
        GetCoachedClasses({ commit }){ //Get classes without 'counterReproductions'
            return new Promise(async(resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("proTrainersList").get()
                    const coachedClassesList = []
                    querySnapshot.forEach((doc) =>  {
                        let coachedClass = doc.data()
                        coachedClassesList.push(coachedClass)
                    })
                     commit('SET_PRO_TRAINERS_LIST', coachedClassesList)
                    resolve(coachedClassesList)
                } catch(error) {
                    reject(error)
                }
            });
        },
        GetProTrainersCounterReproductor({ commit }) {
            return new Promise(async(resolve, reject) => {
                db.collection("countersAnalytics").doc('workoutReproductions').get()
                    .then((querySnapshot) => {
                        let counterReproductor = querySnapshot.data()
                        commit("SET_PRO_COUNTER_REPRODUCTOR", counterReproductor);
                        resolve(counterReproductor);
                    }).catch((err) => {
                        reject(err)
                    })
            });
        },
        GetProTrainersTakenInRangeDate({ commit, getters }, dateRange) {
            return new Promise(async(resolve, reject) => {
                db.collection("timestampCoachedClasses").where("timestamp", ">=", new Date(dateRange.startDate)).where("timestamp", "<=", new Date(dateRange.endDate)).get()
                    .then((querySnapshot) => {
                        let proTrainersList = getters.getProTrainersList.map((proTrainer) => {
                            proTrainer.takedWorkouts = []
                            querySnapshot.forEach((workoutSaved) => {
                                let takedWorkout = workoutSaved.data()
                                if (takedWorkout.id === proTrainer.id) {
                                    proTrainer.takedWorkouts.push(takedWorkout)
                                }
                            })
                            proTrainer.takedWorkoutsCounter = proTrainer.takedWorkouts.length
                            return proTrainer
                        })

                        commit("SET_PRO_TRAINERS_LIST", proTrainersList)
                        resolve(proTrainersList);
                    }).catch((err) => {
                        reject(err)
                    })
            })
        },
        GetProTrainersPaginatedFirstPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                if (getters.getProTrainersList.length > 0) {
                    commit("GO_UNSUBSCRIBE_PRO_TRAINERS_LIST");
                }
                let unsubscribe = db.collection("proTrainersList").orderBy('name').limit(itemsPerPage).onSnapshot((querySnapshot) => {
                        let proTrainersList = [];
                        querySnapshot.forEach((doc) => {
                            let proTrainer = doc.data()
                            proTrainersList.push(proTrainer);
                        });
                        commit("SET_PRO_TRAINERS_LIST", proTrainersList);
                        resolve(proTrainersList);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_PRO_TRAINERS_LIST", unsubscribe);
            });
        },
        GetProTrainersNextPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                commit("GO_UNSUBSCRIBE_PRO_TRAINERS_LIST");
                let unsubscribe = db.collection("proTrainersList").orderBy('name').startAfter(getters.getProTrainersList[getters.getProTrainersList.length - 1].name).limit(itemsPerPage).onSnapshot((querySnapshot) => {
                        let proTrainersList = [];
                        querySnapshot.forEach((doc) => {
                            let proTrainer = doc.data()
                            proTrainersList.push(proTrainer);
                        });
                        console.log(proTrainersList);
                        if (proTrainersList.length > 0) {
                            commit("SET_PRO_TRAINERS_LIST", proTrainersList);
                        }
                        resolve(proTrainersList);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_PRO_TRAINERS_LIST", unsubscribe);
            });
        },
        GetProTrainersPrevPage({ commit, getters }, itemsPerPage) {
            return new Promise(async(resolve, reject) => {
                commit("GO_UNSUBSCRIBE_PRO_TRAINERS_LIST");
                let unsubscribe = db.collection("proTrainersList").orderBy('name').endBefore(getters.getProTrainersList[0].name).limitToLast(itemsPerPage).onSnapshot((querySnapshot) => {
                        let proTrainersList = [];
                        querySnapshot.forEach((doc) => {
                            let proTrainer = doc.data()
                            proTrainersList.push(proTrainer);
                        });
                        commit("SET_PRO_TRAINERS_LIST", proTrainersList);
                        resolve(proTrainersList);
                    },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_PRO_TRAINERS_LIST", unsubscribe);
            });
        },
        GoUnsuscribeGetProTrainersList({ commit }) {
            commit("GO_UNSUBSCRIBE_PRO_TRAINERS_LIST");
        },
        GoUnsuscribeGetProTrainersCounterReproductor({ commit }) {
            commit("GO_UNSUBSCRIBE_PRO_TRAINERS_COUNTER_REPRODUCTOR");
        },
        DeleteProTrainer({}, proTrainer) {
            return new Promise(async(resolve, reject) => {
                let storageRef = storage.child(proTrainer.principalVideo.ref);
                let storageRefImage = storageImages.child(proTrainer.selectedImagePreview.ref);

                await storageRefImage.delete()
                    .then(async() => {
                        await storageRef.delete()
                            .then(async() => {
                                await db.collection("proTrainersList")
                                    .doc(proTrainer.id)
                                    .delete()
                                    .then((res) => {
                                        db.collection("countersAnalytics").doc('workoutReproductions').update({
                                            [proTrainer.id]: firebase.firestore.FieldValue.delete()
                                        })
                                        resolve(proTrainer.id);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            }).catch((errorImg) => {
                                console.log('errorImg deleting storage', errorImg);
                                // Uh-oh, an error occurred!
                            });
                        // File deleted successfully
                    }).catch((error) => {
                        console.log('error deleting storage', error);
                        // Uh-oh, an error occurred!
                    });
            });
        },
        UpdateProTrainer({}, proTrainer) {
            return new Promise(async(resolve, reject) => {
                if(proTrainer.createdAt) {
                    delete proTrainer.createdAt
                }
                proTrainer.updatedAt = new Date()
                if (proTrainer.principalVideo.video) {
                    delete proTrainer.principalVideo.video
                }
                if (proTrainer.selectedImagePreview.img) {
                    delete proTrainer.selectedImagePreview.img
                }
                if (proTrainer.socialSharingImage.img) {
                    delete proTrainer.socialSharingImage.img
                }
                if(proTrainer.takedWorkouts){
                    delete proTrainer.takedWorkouts
                }
                if(proTrainer.takedWorkoutsCounter >= 0){
                    delete proTrainer.takedWorkoutsCounter
                }
                if(proTrainer.counterReproductions >= 0){
                    delete proTrainer.counterReproductions
                }
                if(proTrainer.subscription && proTrainer.subscription != ""){
                    proTrainer.subscription = db.collection("subscriptionsList").doc(proTrainer.subscription.id)
                }
                await db.collection("proTrainersList")
                    .doc(proTrainer.id)
                    .update(proTrainer)
                    .then((res) => {
                        resolve(proTrainer.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        GetFiltersListsProTrainer({ commit }) {
            return new Promise(async(resolve, reject) => {
                db.collection("proTrainersFilters").get()
                    .then((querySnapshot) => {
                        let proTrainerFilters = [];
                        querySnapshot.forEach((doc) => {
                            let filterProtrain = doc.data();
                            filterProtrain.type = doc.id
                            proTrainerFilters.push(filterProtrain);
                        });
                        commit("SET_PRO_TRAINERS_FILTER_LIST", proTrainerFilters);
                        resolve(proTrainerFilters);
                    }).catch((err) => {
                        reject(err)
                    })
            });
        },
        AddNewTypeToListOfFiltersProTrain({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                db.collection("proTrainersFilters")
                    .doc(data.doc)
                    .update({
                        // [data.array]: firebase.firestore.FieldValue.arrayUnion(data.name),
                        listFilters: firebase.firestore.FieldValue.arrayUnion({ name: data.name, children: data.children })
                    })
                    .then((res) => {
                        commit("ADD_ANY_VALUE_PRO_TRAINERS_FILTER_LIST", data);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })

        },
        DeleteTypeToListOfFiltersProTrain({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                db.collection("proTrainersFilters")
                    .doc(data.doc)
                    .update({
                        // [data.array]: firebase.firestore.FieldValue.arrayRemove(data.name),
                        listFilters: firebase.firestore.FieldValue.arrayRemove(data.item)
                    })
                    .then((res) => {
                        commit('DELETE_ANY_VALUE_PRO_TRAINERS_FILTER_LIST', data)
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },
        AddOrDeleteChildrenToListOfFiltersProTrain({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                db.collection("proTrainersFilters")
                    .doc(data.doc)
                    .update({
                        listFilters: data.updatedArray
                    })
                    .then((res) => {
                        commit("UPDATE_WITH_NEW_VALUES_PRO_TRAINERS_FILTER_LIST_CHILDREN", data);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });

            })
        },
        CreateNewFilterProTrainer({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                db.collection("proTrainersFilters")
                    .doc(data.doc)
                    .set({
                        name: data.name,
                        listFilters: data.listFilters,
                    })
                    .then((res) => {
                        commit("UPDATE_FILTER_LIST_PRO_TRAINER", data);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });

            })
        },
        DeleteFilterProTrainer({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                db.collection("proTrainersFilters")
                    .doc(data.doc)
                    .delete()
                    .then((res) => {
                        commit("DELETE_VALUE_ROOT_FILTER_PRO_TRAINER", data);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });

            })
        },
        async GetLeaderBoardProTrain({ commit }, proTrain){
            return new Promise(async(resolve, reject) => {
                try {
                    let gettedLeaderboard = await db.collection("leaderboards").doc(proTrain.id).collection('leaders').orderBy('score', 'desc').get()
                    let leaderboard = []
                    for (let i = 0; i < gettedLeaderboard.docs.length; i++) {
                        let leader = gettedLeaderboard.docs[i].data()
                        let user = await db.collection("users").doc(leader.user).get()
                        leader.userInfo = user.data()
                        leaderboard.push(leader)
                    }  
                    commit("SET_ACTUAL_LEADERBOARD", leaderboard);
                    resolve(leaderboard)
                } catch(error) {
                    reject(error)
                }
            })
        },
        ClearLeaderBoardProTrain({ commit }) {
         commit("SET_ACTUAL_LEADERBOARD", []);
        },
        ResetLeadeboardProTrain({ commit }, deleteData) {
            return new Promise(async (resolve, reject) => {
                try {
                 const res = await Vue.axios.post(requestEndPoint.coachedClassesResetLeaderboards, deleteData, { headers: null })
                 resolve(res);
                } catch (error) {
                  console.error(error);
                }
            });
        },
        UpdateClassValue({}, obj){
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("proTrainersList").doc(obj.id).update(obj.data);
                    resolve(obj.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        ResetMassiveAllLeadeboardsProTrain({ commit, getters }) {
            return new Promise(async (resolve, reject) => {
                try {
                 const res = await Vue.axios.post(requestEndPoint.resetAllLeadersCoachedClasses, { uid: getters.getAdmin.uid }, { headers: null })
                 resolve(res);
                } catch (error) {
                  console.error(error);
                }
            });
        },
         //Type of Exercise
        GetProTrainerTypeOfExerciseList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("proTrainerTypeOfExerciseList").get()
                    const proTrainerTypeOfExerciseList = []
                    querySnapshot.forEach((doc) =>  {
                        proTrainerTypeOfExerciseList.push(doc.data())
                    })
                    commit('SET_PRO_TRAINER_TYPE_OF_EXERCISE_LIST', proTrainerTypeOfExerciseList)
                    resolve(proTrainerTypeOfExerciseList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateProTrainerTypeOfExercise({}, proTrainerTypeExercise) {
            return new Promise(async(resolve, reject) => {
                try {
                    const ref = db.collection("proTrainerTypeOfExerciseList").doc();
                    const newProTrainerTypeOfExercise = await ref.get();
                    proTrainerTypeExercise.id = ref.id;
                    await db.collection("proTrainerTypeOfExerciseList").doc(newProTrainerTypeOfExercise.id).set(proTrainerTypeExercise)
                    resolve(newProTrainerTypeOfExercise.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        UpdateProTrainerTypeOfExercise({}, type) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("proTrainerTypeOfExerciseList").doc(type.id).update(type)
                    resolve(type.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteProTrainerTypeOfExercise({}, type) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("proTrainerTypeOfExerciseList").doc(type.id).delete()
                    resolve(type.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
        //Type of Body
        GetProTrainerTypeOfBodyList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("proTrainerTypeOfBodyList").get()
                    const proTrainerTypeOfBodyList = []
                    querySnapshot.forEach((doc) =>  {
                        proTrainerTypeOfBodyList.push(doc.data())
                    })
                    commit('SET_PRO_TRAINER_TYPE_OF_BODY_LIST', proTrainerTypeOfBodyList)
                    resolve(proTrainerTypeOfBodyList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateProTrainerTypeOfBody({}, proTrainerTypeBody) {
            return new Promise(async(resolve, reject) => {
                try {
                    const ref = db.collection("proTrainerTypeOfBodyList").doc();
                    const newProTrainerTypeOfBody = await ref.get();
                    proTrainerTypeBody.id = ref.id;
                    await db.collection("proTrainerTypeOfBodyList").doc(newProTrainerTypeOfBody.id).set(proTrainerTypeBody)
                    resolve(newProTrainerTypeOfBody.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        UpdateProTrainerTypeOfBody({}, type) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("proTrainerTypeOfBodyList").doc(type.id).update(type)
                    resolve(type.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteProTrainerTypeOfBody({}, type) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("proTrainerTypeOfBodyList").doc(type.id).delete()
                    resolve(type.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    getters: {
        getLoadingStatus(state) {
            return state.loadingStatus;
        },
        getLoadingImage(state) {
            return state.statusImage;
        },
        getProTrainersList(state) {
            return state.proTrainersList;
        },
        getProTrainersCounterReproductor(state) {
            return state.proTrainersCounterReproductor;
        },
        getProTrainDateRanges(state) {
            return state.dateRange
        },
        getProTrainDateRangesChart(state) {
            return state.dateRangeChart
        },
        getProTrainerFilters(state) {
            return state.proTrainerFilters
        },
        getLoadingSocialSharingImage(state){
            return state.statusSocialSharingImage
        },
        getProTrainerLeaderboard(state){
            return state.proTrainerLeaderboard
        },
        getProTrainerTypeOfExerciseList(state){
            return state.proTrainerTypeOfExercise

        },
        getProTrainerTypeOfBodyList(state){
            return state.proTrainerTypeOfBody

        }
    },
};