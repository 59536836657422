<template>
<v-dialog
      v-model="dialogReAuth.show"
      max-width="400"
      persistent
    >
      <v-card class="pa-6">
        <v-card-text>
          To perform this action you must enter your password
        </v-card-text>
        <v-form ref="passwordReAuth" v-model="validPasswordReAuth">
            <h3 class="primary--text text-center">{{ getAdmin.email }}</h3>
            <v-text-field
                :append-icon="showEye ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showEye ? 'text' : 'password'"
                name="password"
                label="Password"
                :messages="message"
                counter
                v-model="password"
                :rules="passwordRules"
                @click:append="showEye = !showEye"
                @keydown.enter.prevent="goReAuthenticate()"
            >
            <template v-slot:message="{ message }">
                <p class="error--text" v-html="message"></p>
            </template>
            </v-text-field>
            <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="secondary"
                small
                text
                @click="closeDialog()"
            >
                Cancel
            </v-btn>

            <v-btn
                color="primary"
                small
                :loading="loading"
                :disabled="!validPasswordReAuth || loading"
                @click="goReAuthenticate()"
            >
                Confirm
            </v-btn>
            </v-card-actions>
        </v-form>

      </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name:"modal-reauth",
    props:{
        dialogReAuth:Object
    },
    data: () => ({
        loading:false,
        controllerPassword:false, 
        validPasswordReAuth:false,
        errorMessage:'',
        showEye: false,
        password: '',
        passwordRules: [
            v => !!v || "Password is required",
            v => v.length > 5 || "The password must have at least 6 digits"
        ]
    }),
    methods:{
        goReAuthenticate(){
            let vm = this
            vm.loading = true
            if(vm.controllerPassword){vm.controllerPassword = false}
             vm.$store
                .dispatch("ReAuthenticateAdmin", vm.password)
                .then(res => {
                    vm.$emit('isSuccessReAuth', vm.dialogReAuth)
                    vm.closeDialog();
                }).catch(error => {
                    vm.loading = false
                    vm.errorMessage = error.message
                    vm.controllerPassword = true
                })
        },
        closeDialog(){
            let vm = this
            vm.password = ''
            vm.errorMessage = ''
            vm.loading = false
            vm.$refs.passwordReAuth.resetValidation()
            vm.dialogReAuth.show = false
        }
    },
    computed: {
    ...mapGetters(["getAdmin"]),
    message(){
        if(this.controllerPassword){
            return this.errorMessage
        }
    }
    },
}
</script>