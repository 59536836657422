<template>
    <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff"
    >
    <v-form ref="formProTrainer" v-model="validProgram">
    <v-row justify="center">
        <v-col cols="12" sm="12">
            <v-btn color="primary" icon size="20" class="text-center" @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <h3 class="secondary--text d-inline mr-2">Create Challenge</h3>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto text-left">
            <p class="secondary--text pb-1 text-left d-inline">Charge Video Presentation</p>
             <v-card
                color="#efefef"
                class="rounded-lg elevation-0 d-flex mb-5 justify-center align-center mt-5"
                min-width="250"
                min-height="250"
                max-height="250px"
                style="position: relative"
                @click="$refs.presentationVideo.$refs.input.click()"
                >
                <video
                    v-if="program.presentationVideo"
                    ref="presentationVideoPreview"
                    width="100%"
                    height="250px"
                    max-height="250px"
                    class="mx-auto"
                    :src="program.presentationVideo ? program.presentationVideo.video : ''"
                ></video>

                <v-icon
                    v-if="!isLoadingVideo"
                    large
                    color="primary"
                    style="
                    position: absolute;
                    transform: translate(50%, -50%);
                    top: 50%;
                    right: 50%;
                    "
                >
                    mdi-upload
                </v-icon>
                <v-progress-circular
                    v-if="isLoadingVideo"
                    indeterminate
                    color="primary"
                    style="
                    position: absolute;
                    transform: translate(50%, -50%);
                    top: 50%;
                    right: 50%;
                    "
                ></v-progress-circular>
            </v-card>
            <p class="secondary--text mt-5 text-left d-inline">Image Preview</p>
            <v-card
                color="#efefef"
                class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
                min-width="250"
                min-height="250"
                @click="$refs.imagePreview.$refs.input.click()"
                >
                <v-img :src="program.selectedImagePreview.img ? program.selectedImagePreview.img : ''" cover width="250" class="rounded-lg" height="250">
                </v-img>
                    <v-icon large color="primary" style="position: absolute; transform: translate(50%,-50%); top: 50%; right: 50%;"> mdi-camera </v-icon>
            </v-card>
            <p class="secondary--text mt-5 text-left">End Day</p>
              <v-datetime-picker 
              v-model="program.endDay"
              :text-field-props=" { backgroundColor: '#efefef', solo: true, flat: true }"
              :date-picker-props="{ color: 'primary' }"
              :time-picker-props="{ color: 'primary' }"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template slot="actions" slot-scope="{ parent }">
                    <v-btn color="secondary" text @click.native="parent.clearHandler">Cancel</v-btn>
                    <v-btn color="primary" @click="parent.okHandler">Save</v-btn>
                  </template>
             </v-datetime-picker>   
            <p class="secondary--text pb-1 text-center">Disable on end day?</p>
            <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="program.disabledOnEndDay"
                :input-value="program.disabledOnEndDay"
                flat
            ></v-switch>
            <p class="secondary--text pb-1 text-center">Testing</p>
            <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="program.isTesting"
                :input-value="program.isTesting"
                flat
            ></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto">
        <p class="secondary--text pb-1">Name</p>
            <v-text-field
                v-model="program.name"
                :rules="programRules.name"
                solo
                flat
                counter
                background-color="#efefef"
            ></v-text-field>
            <p class="secondary--text pb-1">Description</p> 
            <v-textarea v-model="program.description" :rules="programRules.description" counter solo flat no-resize height="355" background-color="#efefef">
            </v-textarea>
             <div class="mx-auto text-center" style="width:150px">
                <p class="secondary--text text-center pb-1">Order number</p>
                <v-text-field
                    v-model.number="program.orderNumber"
                    :rules="programRules.orderNumber"
                    @keypress="numberValid($event)"
                    maxlength="4"
                    class="centered-input"
                    solo
                    flat
                    background-color="#efefef"
                ></v-text-field>
            </div>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="mx-auto">
          <v-row justify="center">
           <v-col cols="3">
            <p class="secondary--text pb-1">Weeks</p>
            <v-text-field
                v-model.number="program.workoutProgram.quantityWeeks"
                @keypress="numberValid($event)"
                type="number"
                maxlength="3"
                solo
                flat
                background-color="#efefef"
            ></v-text-field>
           </v-col>
           <v-col cols="12" class="pt-0">
            <p class="secondary--text pb-1 text-center">Enabled</p>
                <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="program.enabled"
                :input-value="program.enabled"
                flat
                ></v-switch>
            <p class="secondary--text pb-1 text-center">Free</p>
                <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="program.isFree"
                :input-value="program.isFree"
                flat
                ></v-switch>
            <p class="secondary--text pb-1 text-center">GrandFathered</p>
                <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="program.isGrandFathered"
                :input-value="program.isGrandFathered"
                flat
                ></v-switch>
           </v-col>
           <v-col cols="12" class="pt-0">
            <p class="secondary--text pb-1 text-center">Color</p>
                <v-select
                    class="select-color-program mx-auto"
                    v-model="program.color"
                    :items="colorsList"
                    solo
                    flat
                    item-value="color"
                    item-text="color"
                    background-color="#efefef"
                    style="width:150px"
                >   

                    <template v-slot:selection="selected">
                        <div class="w-100 text-center">
                            <v-avatar size="30" class="text-center" :color="selected.item.color" />
                        </div>
                    </template>
                    <template v-slot:item="data">
                        <div class="w-100 text-center">
                            <v-avatar size="30" class="text-center" :color="data.item.color" />
                        </div>
                    </template>
                </v-select>
                <p class="secondary--text pb-1">Subscription</p>
                 <v-select
                  v-model="program.subscription"
                  :items="getSubscriptionsList"
                  item-text="name.ENG"
                  return-object
                  solo
                  flat
                  background-color="#efefef"
                ></v-select>

           </v-col>
          </v-row>
      </v-col>
    </v-row>
    <v-row>
    <v-col cols="10">
        <p class="secondary--text mb-0">Workouts for each week ({{ program.workoutProgram.quantityWeeks }})</p>
    </v-col>
    <v-col cols="2">
        <v-btn color="primary" class="float-right mb-2" :disabled="!validProgram || validateDaysOfWeekChoosed" @click="uploadImage()">
            Create
        </v-btn>
    </v-col>
    </v-row>
    <hr class="mb-6" />
    <v-row justify="start" class="mb-4">
      <v-col cols="12" md="12">
          <v-row>
               <v-expansion-panels accordion>
                    <v-expansion-panel v-for="(week, i) in program.workoutProgram.weeks" :key="i">
                        <v-expansion-panel-header>{{ i+1 }} week</v-expansion-panel-header>
                        <v-expansion-panel-content>
                             <v-row justify="center">
                                 <v-col cols="12" class="py-0 d-flex justify-center">
                                     <div style="width:400px" class="text-center">
                                        <p class="secondary--text mb-0">Days</p>
                                        <v-text-field
                                            style="width:40%"
                                            v-model.number="program.workoutProgram.weeks[i].quantityDays"
                                            @keypress="numberValid($event)"
                                            @input="renderDaysInputs(i)"
                                            :rules="[v => !!v || 'Days is required', v => (v && v <= 7) || 'A week only has 7 days']"
                                            class="centered-input mx-auto"
                                            maxlength="1"
                                            solo
                                            flat
                                            background-color="#efefef"
                                        ></v-text-field>
                                     </div>
                                 </v-col>
                                  <v-col cols="12" class="pt-0" v-for="(workouts, j) in program.workoutProgram.weeks[i].days" :key="j">
                                    <div class="d-flex">
                                        <p class="mr-3 mt-3 mb-0">{{ j + 1 }} day</p>
                                        <v-autocomplete
                                            v-model="program.workoutProgram.weeks[i].days[j].workoutsForTheDay"
                                            :items="workoutList"
                                            @input="validateRestValue($event, i, j)"
                                            return-object
                                            class="program-days-selector"
                                            item-text="name"
                                            item-value="id"
                                            color="primary"
                                            item-color="primary"
                                            solo
                                            flat
                                            chips
                                            multiple
                                            small-chips
                                            deletable-chips
                                            background-color="#efefef"
                                        >
                                        <template v-slot:item="data">
                                            <template v-if="data.item.typeWorkout === 'REST'">
                                                <v-list-item-content class="success--text font-weight-bold" style="margin-left:56px;" v-text="data.item.name"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                            <v-list-item-avatar>
                                                <img v-if="data.item.typeWorkout === 'proTrainer'" :src="data.item.selectedImagePreview.link">
                                                <img v-if="data.item.typeWorkout === 'preSet'" :src="data.item.image.link">
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title> {{ validateNames(data) }}</v-list-item-title>
                                            </v-list-item-content>
                                            </template>
                                        </template>
                                        <template v-slot:selection="data">
                                           <v-chip
                                                v-if="data"
                                                :color="data.item.typeWorkout === 'proTrainer' ? 'primary' : data.item.typeWorkout === 'preSet' ? 'secondary' : data.item.typeWorkout === 'REST' ? 'success' : ''"
                                                class="ma-2"
                                                close
                                                @click:close="deleteChip(data.index, i, j)"
                                            >
                                            {{ validateNames(data) }}
                                            </v-chip>

                                        </template>
                                        </v-autocomplete>
                                    </div>
                                </v-col>
                             </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
          </v-row>
      </v-col>
    </v-row>
    </v-form>
    <v-dialog
      v-model="dialogUploadVideo"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading video, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingStatusVideoProgram"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUploadImage"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading image, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingStatusPreviewImageProgram"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
    <v-file-input
      ref="presentationVideo"
      class="d-none"
      color="white"
      accept="video/mp4"
      dark
      outlined
      :show-size="1000"
      @change="getPresentationVideo"
    />
     <v-file-input
      ref="imagePreview"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg"
      dark
      outlined
      :show-size="1000"
      @change="getPreviewImage"
    />
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import DatetimePicker from "@/components/admin/DatetimePicker.vue";

export default {
    name:"ProgramCreate",
    components:{ 'v-datetime-picker': DatetimePicker },
    data: () => ({
      validProgram: false,
      isLoadingVideo:false,
      program:{
          name:'',
          description:'',
          workoutProgram:{
              quantityWeeks:1,
              weeks:[
                  {
                      quantityDays:1,
                      days:[
                          {
                              workoutsForTheDay:[]
                          }
                      ]
                  }
              ],
          },
          presentationVideo: null,
          selectedImagePreview:"",
          orderNumber:'',
          enabled:true,
          isFree:false,
          color:'',
          subscription:'',
          endDay:'',
          disabledOnEndDay: false,
          isTesting: false,
          isGrandFathered:false
      },
      programRules:{
        name: [v => !!v || 'Name is required',  v => (v && v.length >= 4) || 'Name must be greater than 4'],
        description: [(v) => !!v || "Description section is required"],
        interval: [(v) => !!v || "Description section is required"],
      },
      snackbar: {
        snackbar: false,
        timeout: 2000,
        text: null,
        color: "",
      },
      listIntervals:['Days', 'Weeks'],
      proTrainerList:[],
      preSetsList:[],
      workoutList:[],
      dialogUploadVideo:false,
      dialogUploadImage:false,
      colorsList:[
          {
              color: '#F23827'
          },
          {
              color: '#FFCE00'
          },
          {
              color: '#020126'
          },
          {
              color: '#8800FF'
          },
          {
              color: '#71C6FF'
          },
      ],
      listTypeWorkout:[
          {
              name:'Coached Class',
              value:'proTrainer'
          },
          {
              name:'Pre-Set',
              value:'preSet'
          },
      ]
        
    }),
    async created(){
         const vm = this
        try {
            vm.$emit("showOverlay", true);
            vm.workoutList.unshift({
                name:"REST",
                typeWorkout:"REST",
                id:"REST"
            })
            await vm.getProTrainers()
            await vm.getPreSets()
            await vm.getSubscriptionsToSet()
            vm.$emit("showOverlay", false);
        } catch (error) {
            vm.$emit("showOverlay", false);
            console.log(error);
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Error getting data for Program",
                color: "red",
            };
            vm.$emit("showSnackBar", emitData); 
            
        }
    },
    methods:{
         async getProTrainers(){
            const vm = this
            try {
                const proTrainers = await vm.$store.dispatch('GetProTrainersList')
                vm.proTrainerList = proTrainers
                vm.workoutList= vm.workoutList.concat(proTrainers)
            } catch (error) {
                console.error(err)
              vm.$emit("showOverlay", false);
            }
        },
         async getPreSets(){
             const vm = this
            try {
                const preSets = await vm.$store.dispatch('GetPreSets', true)
                for (let i = 0; i < preSets.length; i++) {
                    preSets[i].name = preSets[i].name['ENG']
                }
                vm.preSetsList = preSets
                vm.workoutList = vm.workoutList.concat(preSets)
            } catch (error) {
              console.error(err)
            }
        },
        async getSubscriptionsToSet(){
            const vm = this
            try {
                vm.$emit("showOverlay", true);
                await vm.$store.dispatch('GetSubscriptionsList')
                vm.$emit("showOverlay", false);
            } catch (error) {
                console.error(error)
            }
        },
        getPresentationVideo(file){
        const vm = this;
        if (file) {
            if (file.type === 'video/mp4') {
            const fr = new FileReader();
            fr.onloadstart = () => {
                vm.isLoadingVideo = true;
                if (vm.program.presentationVideo) {
                    vm.program.presentationVideo = null;
                }
            };
            fr.onload = (el) => {
                let videoBlob = new Blob([new Uint8Array(el.target.result)], { type: file.type });
                let url = window.URL.createObjectURL(videoBlob);
                vm.program.presentationVideo = {
                    video: url,
                    blob:el.target.result,
                    filetype: file.type.split("/")[1],
                    link:""
                };
                vm.isLoadingVideo = false;
            };
            fr.onerror = () => {
                let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Error charging video",
                color: "red",
                };
                vm.$emit("showSnackBar", emitData);
            };
            fr.readAsArrayBuffer(file);
            }else{
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Only .mp4 files are accepted",
                color: "red",
                };
            vm.$emit("showSnackBar", emitData); 
            }
        }

        },
        getPreviewImage(file){
             const vm = this;
            if(file){
                if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                const fr = new FileReader();
                fr.onload = (el) => {
                    vm.program.selectedImagePreview = {
                        img: el.target.result,
                        filetype: file.type.split("/")[1],
                    }
                };
                fr.readAsDataURL(file);
                }else{
                let emitData = {
                    snackbar: true,
                    btn: "",
                    y: "bottom",
                    x: null,
                    mode: "",
                    timeout: 2000,
                    text: "Only .png and .jpeg files are accepted",
                    color: "red",
                    };
                vm.$emit("showSnackBar", emitData);
                }
            }

        },
        numberValid(e){
            e = (e) || window.event
            const charCode = (e.which) ? e.which : e.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault()
            } else {
                return true
            }
        },
        changedTypeWorkout(typeWorkout, weekIndex, dayIndex){
            const vm = this
            console.log(typeWorkout);
            console.log('weekIndex', weekIndex);
            console.log('dayIndex', dayIndex);

        },
        validateNames(data){
            return data.item.typeWorkout === 'proTrainer' ? `${data.item.name} (Coached Class)` : data.item.typeWorkout === 'preSet' ? `${data.item.name} (Pre-Set)` : data.item.typeWorkout === 'REST' ? `${data.item.name}` :''
        },
        deleteChip(index, indexWeek, indexDay){
            const vm = this
            vm.program.workoutProgram.weeks[indexWeek].days[indexDay].workoutsForTheDay.splice(index, 1)
        },
        renderDaysInputs(index){
            const vm = this
            const actualQuantity = vm.program.workoutProgram.weeks[index].quantityDays
            if(actualQuantity != '' && actualQuantity <= 7){
                if(vm.program.workoutProgram.weeks[index].days.length <= 0){
                    for (let i = 0; i < actualQuantity; i++) {
                        vm.program.workoutProgram.weeks[index].days.push({  workoutsForTheDay:[]  })     
                    }
                }else{
                    const calculateIndexes = actualQuantity - vm.program.workoutProgram.weeks[index].days.length
                    if(calculateIndexes > 0){
                        for (let i = 0; i < calculateIndexes; i++) {
                            vm.program.workoutProgram.weeks[index].days.push({ workoutsForTheDay:[] })            
                        }
                    }else{
                        for (let i = 0; i < Math.abs(calculateIndexes); i++) {
                            vm.program.workoutProgram.weeks[index].days.pop()           
                        } 
                    }
                }
            }
        },
        validateRestValue(workoutsSelected, i, j){
            const vm = this
            const position = workoutsSelected.length-1
            let workout = workoutsSelected[position]
            vm.$set(vm.program.workoutProgram.weeks[i].days[j].workoutsForTheDay, position, { id: workout.id, type: workout.typeWorkout })
        },
        uploadImage(){
            const vm = this
            if(vm.program.selectedImagePreview.img){
                vm.dialogUploadImage = true
                vm.$store
                .dispatch("UploadImagePreviewProgram", vm.program)
                .then((storage) => {
                    vm.program.selectedImagePreview.link = storage.link
                    vm.program.selectedImagePreview.ref = storage.ref
                    vm.dialogUploadImage = false
                    vm.uploadVideo();
                
                }).catch((err) => {

                vm.dialogUploadImage = false

                let emitData = {
                    snackbar: true,
                    btn: "",
                    y: "bottom",
                    x: null,
                    mode: "",
                    timeout: 2000,
                    text: "Error uploading image",
                    color: "red",
                };
                vm.$emit("showSnackBar", emitData);
                })

            }else{
                vm.uploadVideo()
            }
            
        },
        uploadVideo(){
            const vm = this
            if(vm.program.presentationVideo && vm.program.presentationVideo.video){
                vm.dialogUploadVideo = true
                vm.$store
                    .dispatch("UploadVideoProgram", vm.program)
                    .then((storage) => {
                        delete vm.program.presentationVideo.blob
                        vm.program.presentationVideo.link = storage.link
                        vm.program.presentationVideo.ref = storage.ref
                        vm.createProgram();
                    
                    }).catch((err) => {
    
                        vm.dialogUploadVideo = false
        
                        let emitData = {
                            snackbar: true,
                            btn: "",
                            y: "bottom",
                            x: null,
                            mode: "",
                            timeout: 2000,
                            text: "Error uploading video",
                            color: "red",
                        };
                        vm.$emit("showSnackBar", emitData);
                    })

            }else{
                vm.createProgram()
            }

        },
        createProgram(){
            const vm = this
            vm.$store.dispatch('CreateProgram', vm.program)
            .then((id) => {
                const payload = {
                    routeName: 'ProgramDetail',
                    title: vm.program.name,
                    id: id,
                    img: vm.program.selectedImagePreview.link
                }
                vm.$store.dispatch('GenerateDynamicLink', payload)
                    .then(async (dynamicLinks) => {
                        vm.program.dynamicLink = dynamicLinks.shortLink
                        await vm.$store.dispatch('UpdateProgram', vm.program)
                        console.log('vm.program', vm.program)
                        vm.$store.dispatch("GenerateLog", {
                            id: id,
                            title: vm.program.name,
                            type: "createdProgram",
                        })
                        vm.dialogUploadVideo = false

                        let emitData = {
                            snackbar: true,
                            btn: "",
                            y: "bottom",
                            x: null,
                            mode: "",
                            timeout: 2000,
                            text: "Program Created successfully",
                            color: "success",
                        };
                        vm.$router.push("/admin/programs")
                        .catch((err) => {
                            vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                            vm.snackbar.color = "red darken-2";
                            vm.snackbar.snackbar = true;
                        })
                        vm.$emit("showSnackBar", emitData);
                    }).catch((errDynamic) => {
                        console.error(errDynamic)
                    })
            }).catch((err)=>{
                vm.dialogUploadVideo = false

                let emitData = {
                    snackbar: true,
                    btn: "",
                    y: "bottom",
                    x: null,
                    mode: "",
                    timeout: 2000,
                    text: "Error creating Program",
                    color: "red",
                };
                vm.$emit("showSnackBar", emitData);

            })

        },
    },
    computed:{
        validateDaysOfWeekChoosed (){
            return this.program.workoutProgram.weeks.some(week => week.quantityDays > 7 || week.quantityDays < 1)
        },
       ...mapGetters(["getLoadingStatusVideoProgram", "getLoadingStatusPreviewImageProgram", "getSubscriptionsList"]),
    },
    watch:{
        'program.workoutProgram.quantityWeeks':function (newQuantity) {
            if(this.program.workoutProgram.weeks.length <= 0){
                for (let i = 0; i < newQuantity; i++) {
                    const week = { quantityDays:1, days:[ { workoutsForTheDay:[] } ] }
                    this.program.workoutProgram.weeks.push(week)     
                }
            }else{
                const calculateIndexes = newQuantity - this.program.workoutProgram.weeks.length
                if(calculateIndexes > 0){
                    const week = { quantityDays:1, days:[ { workoutsForTheDay:[] } ] }
                    for (let i = 0; i < calculateIndexes; i++) {
                        this.program.workoutProgram.weeks.push(week)            
                    }

                }else{
                    for (let i = 0; i < Math.abs(calculateIndexes); i++) {
                        this.program.workoutProgram.weeks.pop()           
                    } 
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
   .program-days-selector ::v-deep .theme--light.v-chip:not(.v-chip--active), .program-days-selector ::v-deep .v-chip.v-size--small{
       background: #ff7726;
        color: #fff;
   }
</style>