import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { requestEndPoint } from '@/store/Api'
import { v4 as uuidv4 } from "uuid";

Vue.use(VueAxios, Axios);

const db = firebase.firestore();

const storageImages = firebase.storage().ref("backgroundAppImages/");
const splashStorage = firebase.storage().ref('customLoadingScreen');
const headerStorage = firebase.storage().ref('customHomeHeaderImages');

Vue.use(VueAxios, Axios);

export default {
    state: {
        backgroundAppImagesList: [],
        loadingStatusBackgroundAppImage: 0,
        featuredSplashes: [],
        splashProgress: 0,
        headerProgress: 0
    },
    mutations: {
        SET_VALUE_LOADING_STATUS_BACKGROUND_APP_IMAGE(state, progress) {
            state.loadingStatusBackgroundAppImage = progress
        },
        SET_BACKGROUND_APP_IMAGES_LIST(state, backgroundAppImagesList) {
            state.backgroundAppImagesList = backgroundAppImagesList
        },
        SET_FEATURED_SPLASH(state, newValue) {
            state.featuredSplashes = newValue;
        },
        SET_SPLASH_UPLOAD_PROGRESS(state, progress) {
            state.splashProgress = progress;
        },
        SET_HEADER_UPLOAD_PROGRESS(state, progress) {
            state.headerProgress = progress;
        }
    },
    actions: {
        GetBackgroundAppImagesList({ commit }) {
            return new Promise((resolve, reject) => {
                db.collection("backgroundAppImagesList").get()
                    .then((querySnapshot) => {
                        var backgroundAppImagesList = [];
                        querySnapshot.forEach((doc) => {
                            let backgroundAppImage = doc.data();
                            backgroundAppImage.id = doc.id;
                            backgroundAppImagesList.push(backgroundAppImage);
                        });
                        commit("SET_BACKGROUND_APP_IMAGES_LIST", backgroundAppImagesList);
                        resolve(backgroundAppImagesList);
                    }).catch((err) => {
                        reject(err)
                    })
            });
        },
        UploadBackgroundAppImage({ commit }, backgroundAppImage) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                let uploadTask = storageRef.putString(backgroundAppImage.image.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_BACKGROUND_APP_IMAGE', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        CreateBackgroundAppImage({ }, backgroundAppImage) {
            return new Promise(async (resolve, reject) => {
                delete backgroundAppImage.image.img
                const ref = db.collection("backgroundAppImagesList").doc();
                const newBackgroundAppImage = await ref.get();
                backgroundAppImage.id = ref.id;
                backgroundAppImage.createdAt = new Date()
                backgroundAppImage.updatedAt = new Date()
                await db.collection("backgroundAppImagesList")
                    .doc(newBackgroundAppImage.id)
                    .set(backgroundAppImage)
                    .then(async (res) => {
                        resolve(newBackgroundAppImage.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },
        CreateCustomSplash({ }, data) {
            return new Promise(async (resolve, reject) => {
                const result = await db.collection('customLoadingScreen').add(data);
                resolve(result.id);
            })
        },
        UpdateBackgroundAppImage({ }, backgroundAppImage) {
            return new Promise(async (resolve, reject) => {
                if (backgroundAppImage.image.img) {
                    delete backgroundAppImage.image.img
                }
                if (backgroundAppImage.createdAt) {
                    delete backgroundAppImage.createdAt
                }
                backgroundAppImage.updatedAt = new Date()
                await db.collection("backgroundAppImagesList")
                    .doc(backgroundAppImage.id)
                    .update(backgroundAppImage)
                    .then((res) => {
                        resolve(backgroundAppImage.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async DeleteBackgroundAppImage({ }, backgroundAppImage) {
            return new Promise(async (resolve, reject) => {
                let storageRefImage = storageImages.child(backgroundAppImage.image.ref);
                await storageRefImage.delete()
                    .then(async () => {
                        await db.collection("backgroundAppImagesList")
                            .doc(backgroundAppImage.id)
                            .delete()
                            .then((res) => {
                                resolve(backgroundAppImage.id);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    }).catch((errorImg) => {
                        console.log('errorImg deleting storage', errorImg);
                        // Uh-oh, an error occurred!
                    });

            });
        },
        async DeleteImageByRef({ }, data) {
            const ref = data.ref;
            const type = data.type;
            return new Promise(async (resolve, reject) => {
                try {
                    if (type === 'splash') {
                        await splashStorage.child(ref).delete();
                    } else {
                        await headerStorage.child(ref).delete();
                    }
                    resolve('done');
                } catch (error) {
                    reject(error);
                }
            });
        },
        async DeleteSplash({ }, splash) {
            return new Promise(async (resolve, reject) => {
                try {
                    try {
                        await splashStorage.child(splash.splashRef).delete();
                    } catch (error) {
                        console.log(error);
                    }

                    try {
                        await headerStorage.child(splash.headerRef).delete();
                    } catch (error) {
                        console.log(error);
                    }

                    await db.collection('customLoadingScreen').doc(splash.id).delete();
                    resolve(splash.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        async GetFeaturedSplashScreens({ commit }) {
            try {
                const result = await db.collection("customLoadingScreen").get();
                const resultData = result.docs.map(r => ({
                    ...r.data(),
                    id: r.id,
                    startDate: r.data().startDate.toDate(),
                    endDate: r.data().endDate.toDate()
                }));
                commit("SET_FEATURED_SPLASH", resultData);
            } catch (error) {
                console.error(error);
            }
        },
        async UploadSplashImage({ commit }, image) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = splashStorage.child(random);
                let uploadTask = storageRef.putString(image.img, "data_url");

                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_SPLASH_UPLOAD_PROGRESS', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        async UploadHeaderImage({ commit }, image) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = headerStorage.child(random);
                let uploadTask = storageRef.putString(image.img, "data_url");

                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_HEADER_UPLOAD_PROGRESS', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        UpdateSplash({ }, splash) {
            return new Promise(async (resolve, reject) => {
                try {
                    await db.collection("customLoadingScreen").doc(splash.id).update(splash);
                    resolve(splash.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    getters: {
        getAppBackgrounds(state) {
            return state.backgroundAppImagesList;
        },
        getLoadingStatusBackgroundAppImage(state) {
            return state.loadingStatusBackgroundAppImage;
        },
        getFeaturedSplashes(state) {
            return state.featuredSplashes;
        },
        getLoadingSplashImages(state) {
            const value = (state.splashProgress + state.headerProgress) / 2;
            console.log(value);
            return value;
        }
    }
}