<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="4">
        <p class="text-h6 text--secondary font-weight-bold">Subscriptions Management</p>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-btn text color="secondary" @click="createSubscription()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New Subscription
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          label="Search for a Subscription"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getSubscriptionsList"
          item-key="id"
          fixed-header
          class="elevation-4"
        >
         <template v-slot:[`item.enabled`]="{ item }">
            <v-switch
              color="primary"
              class="center-switch"
              :input-value="item.enabled"
              readonly
              flat
            ></v-switch>
          </template>
         <template v-slot:[`item.duration`]="{ item }">
            {{ item.duration.quantity }} {{ item.duration.interval }}
          </template>
         <template v-slot:[`item.price`]="{ item }">
            {{ item.price.USD }}$
          </template>
          <template v-slot:[`item.action`]="{ item }">

            <div class="dropdown">
              <span>...</span>
              <div class="dropdown-content">
                <v-btn
                  class="optionButton"
                  color="transparent"
                  @click="changeRouteSubscription(item)"
                >
                  <v-icon class="mr-2">mdi-pencil</v-icon>Edit
                </v-btn>
                <v-btn
                  class="optionButton"
                  color="transparent"
                  @click="openDialogConfirm(item)"
                >
                  <v-icon class="mr-2">mdi-delete</v-icon>Delete
                </v-btn>
              </div>
            </div>
            <!-- <v-btn icon color="primary" @click="changeRouteSubscription(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              class="mr-3"
              :disabled="item.blocked"
              @click="openDialogConfirm(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
      <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
        <v-card class="text-center">
            <v-icon class="text-center ma-2" size="120" color="primary"
            >mdi-alert</v-icon>
            <v-card-text class="pa-4 text-center"
            >This action is irreversible and will delete all data about this
            Subscription. Including the icon, data, references with user and information</v-card-text>
            <v-card-text class="text-center">Are you sure?</v-card-text>

            <v-btn
            color="third"
            tile
            width="150"
            class="pa-0 text--secondary"
            @click="closeDialogConfirm()"
            >No</v-btn
            >

            <v-btn
            color="primary"
            tile
            width="150"
            class="pa-0"
            @click="openDialogReAuth('deleteSubscription', dialog.item)"
            >Yes</v-btn
            >
            <!-- </v-card-actions> -->
        </v-card>
    </v-dialog>
    <modal-reauth
      :dialogReAuth="dialogReAuth"
      @isSuccessReAuth="isSuccessReAuth"
    ></modal-reauth>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
export default {
  name: "SubscriptionsManageList",
  components: { modalReauth },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: '', align:'center', value:'icon', sortable:false },
      { text: 'Order Number', align:'center', value:'orderNumber', sortable:true },
      { text: 'Name', align:'center', value:'name.ENG', sortable:true },
      { text: 'Duration', align:'center', value:'duration', sortable:true },
      { text: 'Price', align:'center', value:'price', sortable:true },
      { text: 'Enabled', align:'center', value:'enabled', sortable:true },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
    dialogReAuth: {
      show: false,
      action: "",
      item: {},
    },
    dialog: {
      show: false,
      item: {},
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
  }),
  created(){
      let vm = this
      vm.getAllSubscriptions()
  },
  methods: {
    async getAllSubscriptions(){
     const vm = this 
      try {
          vm.loading = true
          await vm.$store.dispatch("GetSubscriptionsList")
          vm.loading = false
      } catch (error) {
          vm.loading = false
          console.log(error);
      }
    },
    openDialogConfirm(item) {
      const vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      const vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.closeDialogConfirm();
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "deleteSubscription":
          vm.deleteSubscription(dialogReAuthResponse.item);
          break;
        default:
          break;
      }
    },
   async deleteSubscription(subscription){
      const vm = this;
      try {
        vm.$emit("showOverlay", true);
        await vm.$store.dispatch("DeleteSubscription", subscription)
        await vm.$store.dispatch("GenerateLog", {
                id: subscription.id,
                name: subscription.name.ENG,
                type: "deletedSubscription",
              });
        vm.getAllSubscriptions()
        vm.$emit("showOverlay", false);
        
      } catch (error) {
        vm.$emit("showOverlay", false);
          console.error(error);
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error deleting Subscription",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
      } 
    },
    changeRouteSubscription(subscription){
        let vm = this;
        vm.$router.push({ name: "Subscriptions Manage Edit", params: subscription })
        .catch((err) => {
            vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
            vm.snackbar.color = "red darken-2";
            vm.snackbar.snackbar = true;
        })
    },
    createSubscription() {
      let vm = this;
      vm.$router.push({ name: "Subscriptions Manage Create" }).catch((err) => {
        vm.snackbar.text ="Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
  },
  computed:{
      ...mapGetters(["getSubscriptionsList"])
  }
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown span {
  padding: 4px 10px;
  font-size: 24px;
  font-weight: bold;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* padding: 12px 16px; */
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
}

.dropdown:hover span {
  background-color: #e4e4e4;
}

.optionButton {
  font-size: 14px !important;
  justify-content: start;
  text-align: left;
}
</style>