<template>
  <v-container fluid>
    <v-row justify="space-between" align="center" class="px-10">
      <!-- <v-col cols="12" sm="12" md="12"> -->
      <v-col cols="12" sm="12" class="pa-0">
        <v-btn color="primary" icon size="20" @click="goBack()">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <div
          v-if="user.photoURL"
          class="rounded-circle avatar-profile-image mx-auto"
          :style="`background-image:url(${user.photoURL})`"
        ></div>
        <template v-else>
          <div class="text-center">
            <img class="rounded-circle avatar-profile-image" :src="require('@/assets/default-avatar.png')">
          </div>
        </template>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <h3 class="secondary--text"><span class="font-weight-light">Name:</span> {{ user.firstName }} {{ user.lastName }}</h3>
        <h3 class="secondary--text"><span class="font-weight-light">Nickname:</span> {{ user.nickname }}</h3>
        <h3 class="secondary--text"><span class="font-weight-light">Email:</span> {{ user.email }}</h3>
        <h3 class="secondary--text"><span class="font-weight-light">Creation Date:</span> {{ getParsedDate(user.creationTime ? user.creationTime.toDate() : '') }}</h3>
        <h3 class="secondary--text"><span class="font-weight-light">Last login:</span> {{ user.lastLogin }}</h3>
        <h3 class="secondary--text"><span class="font-weight-light">Privilege:</span> {{ verifyPrivilege() }}</h3>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="text-center">
        <v-btn small color="secondary" class="mr-2" @click="openModal('restore', user.email, user)">Restore Password</v-btn>
      <!-- </v-col>
      <v-col cols="12" sm="12" md="1" class="text-center"> -->
        <v-btn small color="secondary" class="mr-2" @click="openModal('validate', user.email, user)">Validate Email</v-btn>
      <!-- </v-col>
      <v-col cols="12" sm="12" md="1" class="text-center"> -->
       <v-btn small color="secondary" width="150" @click="changeRouteSendNotification()">Send Notification</v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <!-- <h3 class="text-center font-weight-light secondary--text">Role</h3> -->
        <v-btn v-if="getAdmin.adminLevel === 3" small color="primary" class="mr-2" @click="openDialogManageAllowedViews()">Manage allowed views</v-btn>
        <template v-else>
          <h3 class="text-center font-weight-light secondary--text">Role</h3>
          <h4 class="text-center primary--text pa-5" v-if="user">{{ levelProps.find(r => r.value === user.adminLevel).level }}</h4> 
        </template>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <h3 class="text-left font-weight-light secondary--text">Enabled</h3>
        <v-switch
          class="mx-auto"
          color="primary"
          :input-value="!user.disabled"
          :disabled="user.adminLevel >= 1"
           @click="openModal('disable', user.email, user)"
          readonly
          flat
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <h3 class="text-left font-weight-light secondary--text">Coach</h3>
        <v-switch
          v-model="user.isCoach"
          class="mx-auto"
          color="primary"
          :disabled="loadingCoach"
          :loading="loadingCoach"
          @change="changeCoachStatus()"
          flat
        ></v-switch>
      </v-col>
      <!-- </v-col> -->
    </v-row>

    <!-- TEMPORALLY COMMENT FOR FIX STADISTICS PROBLEMS-->
    <!-- <v-row justify="start" align="center">
      <v-col cols="12" sm="12" md="3">
        <v-card class="rounded-xl my-2 mx-auto d-flex flex-column justify-center" elevation="5" width="270" height="135">
          <v-card-title class="font-weight-bold secondary--text"
            >{{ user.savesUser && user.savesUser.freeStyleSaves ? user.savesUser.freeStyleSaves.length : 0 }} Freestyle sessions</v-card-title
          >
          <v-card-subtitle class="pb-0"
            >{{ user.savesUser && user.savesUser.freeStyleSaves ? getTimeWorkouts(user.savesUser.freeStyleSaves) : '0 seconds' }} in Freestyle sessions</v-card-subtitle
          >
          <v-card-text v-if="user.savesUser && user.savesUser.freeStyleSaves && user.savesUser.freeStyleSaves.length > 0" class="font-weight-bold primary--text"
            >From {{ rangeDates(user.savesUser.freeStyleSaves).minDate }} to {{ rangeDates(user.savesUser.freeStyleSaves).maxDate }}
          </v-card-text>
          <template v-else>
            <v-card-text class="font-weight-bold primary--text"
              >Has not completed any yet
            </v-card-text>
          </template>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card class="rounded-xl my-2 mx-auto d-flex flex-column justify-center" elevation="5" width="270" height="135">
          <v-card-title class="font-weight-bold secondary--text"
            >{{ user.savesUser && user.savesUser.buildYourOwnSaves ? user.savesUser.buildYourOwnSaves.length : 0 }} Build Your Own</v-card-title
          >
          <v-card-subtitle class="pb-0"
            >{{ user.savesUser && user.savesUser.buildYourOwnSaves ? getTimeWorkouts(user.savesUser.buildYourOwnSaves) : '0 seconds' }} / {{ user.savesUser && user.savesUser.buildYourOwnSaves ? getCalsWorkouts(user.savesUser.buildYourOwnSaves) : 0 }} Cal / {{ user.savesUser && user.savesUser.buildYourOwnSaves ? getExercisesMadedWorkouts(user.savesUser.buildYourOwnSaves) : 0 }} Exercises</v-card-subtitle
          >
          <v-card-text v-if="user.savesUser && user.savesUser.buildYourOwnSaves && user.savesUser.buildYourOwnSaves.length > 0" class="font-weight-bold primary--text"
            >From {{ rangeDates(user.savesUser.buildYourOwnSaves).minDate }} to {{ rangeDates(user.savesUser.buildYourOwnSaves).maxDate }}
          </v-card-text>
          <template v-else>
            <v-card-text class="font-weight-bold primary--text"
              >Has not completed any yet
            </v-card-text>
          </template>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card class="rounded-xl my-2 mx-auto d-flex flex-column justify-center" elevation="5" width="270" height="135">
          <v-card-title class="font-weight-bold secondary--text"
            >{{ user.savesUser && user.savesUser.proWorkOutSaves ? user.savesUser.proWorkOutSaves.length : 0 }} ProWorkouts</v-card-title
          >
          <v-card-subtitle class="pb-0"
            >{{ user.savesUser && user.savesUser.proWorkOutSaves ? getTimeWorkouts(user.savesUser.proWorkOutSaves) : '0 seconds' }} / {{ user.savesUser && user.savesUser.proWorkOutSaves ? getCalsWorkouts(user.savesUser.proWorkOutSaves) : 0 }} Cal / {{ user.savesUser && user.savesUser.proWorkOutSaves ? getExercisesMadedWorkouts(user.savesUser.proWorkOutSaves) : 0 }} Exercises</v-card-subtitle
          >
          <v-card-text v-if="user.savesUser && user.savesUser.proWorkOutSaves && user.savesUser.proWorkOutSaves.length > 0" class="font-weight-bold primary--text"
            >From {{ rangeDates(user.savesUser.proWorkOutSaves).minDate }} to {{ rangeDates(user.savesUser.proWorkOutSaves).maxDate }}
          </v-card-text>
          <template v-else>
            <v-card-text class="font-weight-bold primary--text"
              >Has not completed any yet
            </v-card-text>
          </template>
        </v-card>
      </v-col>
        <v-col cols="12" sm="12" md="3">
        <v-card class="rounded-xl my-2 mx-auto d-flex flex-column justify-center" elevation="5" width="270" height="135">
          <v-card-title class="font-weight-bold secondary--text"
            >{{ user.savesUser && user.savesUser.proTrainingSaves ? user.savesUser.proTrainingSaves.length : 0 }} ProTrain sessions</v-card-title
          >
          <v-card-subtitle class="pb-0"
            >{{ user.savesUser && user.savesUser.proTrainingSaves ? getTimeWorkoutsProTrainers(user.savesUser.proTrainingSaves) : '0 seconds' }} in ProTrain sessions</v-card-subtitle
          >
          <v-card-text v-if="user.savesUser && user.savesUser.proTrainingSaves && user.savesUser.proTrainingSaves.length > 0" class="font-weight-bold primary--text"
            >From {{ rangeDates(user.savesUser.proTrainingSaves).minDate }} to {{ rangeDates(user.savesUser.proTrainingSaves).maxDate }}
          </v-card-text>
          <template v-else>
            <v-card-text class="font-weight-bold primary--text"
              >Has not completed any yet
            </v-card-text>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11">
        <h3 class="secondary--text my-5">Builds your own(sessions)</h3>
        <v-data-table
          v-if="user"
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="user.savesUser.sessions"
          :items-per-page="5"
          item-key="name"
          fixed-header
          class="elevation-4"
        >
        <template v-slot:[`item.exercises`]="{ item }">
            {{ item.exercises.length }}
        </template>
        </v-data-table>
      </v-col>
    </v-row> -->
    <!-- TEMPORALLY COMMENT FOR FIX STADISTICS PROBLEMS-->
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11">
        <h3 class="secondary--text my-5">Achievements Unlocked</h3>
        <v-data-table
          v-if="user"
          :loading="loading"
          :headers="headersAchievementsEarned"
          :items="achievementsGetted"
          :items-per-page="5"
          fixed-header
          class="elevation-4"
        >
          <template v-slot:[`item.image`]="{ item }">
            <img :src="item.ref.icon.link" width="30" height="30">
          </template>
         <template v-slot:[`item.ref`]="{ item }">
              {{ item.ref.name }}
          </template>
        <template v-slot:[`item.createdAt`]="{ item }">
              {{ timestampToStringDate(item.createdAt) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11">
        <v-row justify="start" align="center">
            <v-col cols="12" sm="6" md="2">
            <h3 class="secondary--text my-5">Subscriptions History</h3>
            </v-col>
            <v-col cols="12" sm="6" md="6">
            <v-btn 
              v-if="getAdmin.adminLevel >= 1" 
              small color="primary"
              @click="openDialogAddSubscription()"
            >
            <v-icon>mdi-plus</v-icon>
              Add Manually Subscription
            </v-btn>
            </v-col>
        </v-row>
        <v-data-table
          v-if="user"
          :loading="loading"
          :headers="headersSubscriptions"
          :items="subscriptionsGetted"
          :items-per-page="5"
          item-key="id"
          fixed-header
          class="elevation-4"
        >
          <template v-slot:[`item.duration.quantity`]="{ item }">
              {{ item.duration.quantity }}  {{ item.duration.interval }}
          </template>
          <template v-slot:[`item.duration.startDate`]="{ item }">
              {{ timestampToStringDate(item.duration.startDate) }}
          </template>
          <template v-slot:[`item.duration.endDate`]="{ item }">
              {{ timestampToStringDate(item.duration.endDate) }}
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
              <h1 v-if="user.activeSubscription.ref.id === item.id" :class="user.activeSubscription.isPremium ? 'success--text' : 'red--text'">•</h1>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11">
        <h3 class="secondary--text my-5">MAXPRO Devices</h3>
        <v-data-table
          v-if="user"
          :search="search"
          :loading="loading"
          :headers="headersMaxProDevices"
          :items="maxProDevices"
          :items-per-page="5"
          item-key="maxproMac"
          fixed-header
          class="elevation-4"
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="11" md="11">
        <h3 class="secondary--text my-5">Mobile Devices</h3>
        <v-data-table
          v-if="user"
          :search="search"
          :loading="loading"
          :headers="headersDevices"
          :items="user.devicesInfo"
          :items-per-page="5"
          item-key="uuid"
          fixed-header
          class="elevation-4"
        >
        <template v-slot:[`item.lastUsed`]="{ item }" v-if="user.devicesInfo && user.devicesInfo.length > 0">
          <span v-if="user.devicesInfo.map((dev) =>  dev.uuid).indexOf(item.uuid) === user.devicesInfo.length-1" class="primary--text text-h4">•</span>
        </template>
        </v-data-table>
      </v-col>
    </v-row>
        <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
      <v-card>
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->

        <v-card-text
          class="pa-8 text-center"
          v-if="dialog.type === 'restore'"
        >Sure you want to reset {{ dialog.email }} password?</v-card-text>
        <v-card-text
          class="pa-8 text-center"
          v-if="dialog.type === 'validate'"
        >Are you sure you want to validate the email of {{ dialog.email }}?</v-card-text>
        <v-card-text
          class="pa-8 text-center"
          v-if="dialog.type === 'disable'"
        >Are you sure you want to {{ dialog.item.disabled ? 'enable': 'disable' }} the account of {{ dialog.email }}?</v-card-text>

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn color="third" tile width="150" class="pa-0 text--secondary" @click="closeModal()">No</v-btn>

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="openDialogReAuth('goActionMail', {}, { type: dialog.type, email: dialog.email, item: dialog.item })"
        >Yes</v-btn>
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRole.show" width="300" class="rounded-xl" persistent>
      <v-card>
        <v-card-text
          class="pa-8 text-center"
        >Are you sure you want change the role in MaxPro of the user: {{ user.email }}?</v-card-text>

        <v-btn color="third" tile width="150" class="pa-0 text--secondary" @click="closeModalRole()">No</v-btn>

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="openDialogReAuth('updateRoleUser', dialogRole.item, { type: '', email: user.email, item: dialogRole.item })"
        >Yes</v-btn>
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
     <v-dialog v-model="dialogManageAllowedViews.show" width="800" class="rounded-xl">
      <v-card class="pa-5" v-if="user.adminLevel !== ''">
        <v-card-actions class="pa-0">
          <v-btn icon color="red" @click="closeDialogManageAllowedViews()">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-row justify="start">
          <v-col cols="12" sm="12">
             <v-select
              v-if="getAdmin.adminLevel === 3"
              :items="levelProps"
              item-text="level"
              ref="selectRole"
              :value="user.adminLevel"
              dense
              solo
              class="mx-auto secondary--text"
              style="width: 80%"
              @change="changeRange"
          ></v-select>
          </v-col>
          <v-col cols="12" sm="4" v-for="(route, index) in getRoutesNames()" :key="index">
            <v-checkbox
              v-model="allowedRoutes"
              :label="route"
              :value="route"
          ></v-checkbox>
          </v-col>
        </v-row>
        <div class="text-right">
          <v-btn
            color="primary"
            small
            @click="updateViewsAllowed()"
          >
            Save
          </v-btn>
        </div>
      </v-card>
      <template v-else>
        <v-card class="pa-5">
          <v-card-actions class="pa-0">
          <v-btn icon color="red" @click="closeDialogManageAllowedViews()">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
           <h3 class="text-center mb-4 font-weight-light secondary--text">This user does not have access to the Dashboard, you can change it here</h3>
            <v-select
              v-if="getAdmin.adminLevel === 3"
              :items="levelProps"
              item-text="level"
              ref="selectRole"
              :value="user.adminLevel"
              dense
              solo
              class="mx-auto secondary--text"
              style="width: 80%"
              @change="changeRange"
          ></v-select>
        </v-card>
      </template>
     </v-dialog>
     <v-dialog v-model="dialogAddSubscription.show" width="800" class="rounded-xl">
      <v-card class="pa-5">
        <v-card-actions class="pa-0">
          <v-btn icon color="red" @click="closeDialogAddSubscription()">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <h3 class="secondary--text mt-2">Add manually Subscription to {{ user.firstName }} {{ user.lastName }}</h3>
            </v-col>
            <!-- <v-col cols="4">
              <p class="secondary--text pb-1">Currency</p>
              <v-select
                v-model="dialogAddSubscription.subscription.billing.currency"
                :items="currienciesList"
                item-text="name"
                item-value="value"
                hide-details
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <p class="secondary--text pb-1">Pay Methods</p>
              <v-select
                v-model="dialogAddSubscription.subscription.billing.payMethod"
                :items="payMethodsList"
                item-text="name"
                item-value="value"
                hide-details
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <p class="secondary--text pb-1">Status Pay</p>
              <v-select
                v-model="dialogAddSubscription.subscription.billing.statusPay"
                :items="statusPaysList"
                item-text="name"
                item-value="value"
                hide-details
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col> -->
             <v-col cols="6">
               <p class="secondary--text pb-1">Subscription</p>
                <v-select
                  v-model="dialogAddSubscription.selectedSubscription"
                  :items="availableSubscriptions"
                  item-text="name.ENG"
                  item-value="value"
                  hide-details
                  return-object
                  solo
                  flat
                  background-color="#efefef"
                ></v-select>
             </v-col>
            <v-col cols="12" v-if="dialogAddSubscription.selectedSubscription">
              <h3 class="secondary--text mt-2 d-inline">{{ dialogAddSubscription.selectedSubscription.name.ENG }} </h3>
              <h3 class="primary--text mt-2 d-inline">({{ dialogAddSubscription.selectedSubscription.duration.quantity  }} {{ dialogAddSubscription.selectedSubscription.duration.interval }})</h3>
              <p class="secondary--text pb-2">{{ dialogAddSubscription.selectedSubscription.description.ENG  }}</p>
              <div>
                <h3 class="secondary--text mt-2 d-inline">Start Date: </h3>
                <p class="secondary--text pb-0 d-inline">{{ getFullDate() }}</p>
              </div>
              <h3 class="secondary--text mt-2 d-inline">End Date: </h3>
              <p class="secondary--text pb-0 d-inline">{{ getFullDate(setFinishDaySubscription(dialogAddSubscription.selectedSubscription.duration.quantity, dialogAddSubscription.selectedSubscription.duration.interval))  }}</p>
            </v-col>
            <v-col cols="12" v-if="dialogAddSubscription.selectedSubscription">
              <v-btn color="primary" :loading="dialogAddSubscription.loading" :disabled="dialogAddSubscription.loading" class="float-right" @click="openDialogReAuth('addSubscriptionUser', {})">
                Add subscription
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
     </v-dialog>
     <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>

     <modal-reauth :dialogReAuth="dialogReAuth" @isSuccessReAuth="isSuccessReAuth"></modal-reauth>
  </v-container>
</template>
<script>
import moment from "moment";
import firebase from "firebase";
import Paths from '@/router/paths/index';
import { mapGetters } from "vuex";
import modalReauth from '@/components/admin/modal-reauth.vue';
export default {
  name: "User",
  components: { modalReauth },
  data: () => ({
    user: {
      savesUser:{
        sessions:[]
      },
      metadata:{
        creationTime:''
      },
      adminLevel:"",
      devicesInfo:[]
    },
    maxProDevices: [],
    subscriptionsGetted:[],
    achievementsGetted:[],
    allowedRoutes:[],
    availableSubscriptions:[],
    loadingCoach:false,
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    dialogRole:{
      show:false,
      item:''
    },
    dialog: {
      show: false,
      type: "",
      email: "",
      item: "",
    },
     dialogReAuth:{
      show: false,
      action: '',
      item:{},
      goActionProps:{}
    },
    dialogManageAllowedViews:{
      show: false
    },
    dialogAddSubscription:{
      show: false,
      loading:false,
      subscription:{
        billing:{
          currency:"USD",
          offer:{
            inOffer:false,
            percentage:0,
            priceInOffer:0
          },
          payMethod: "ADDED-MANUALLY",
          price:{
            USD:0
          },
          statusPay:"SUCCESS"
        },
        duration:{},
        isCoupon:true
      },
      selectedSubscription:null
    },
    levelProps: [
      { level: "User", value: "" },
      { level: "Customer Service", value: 1 },
      { level: "Admin", value: 3 },
    ],
    search: "",
    headers: [
      {
        text: "Name",
        align: "center",
        value: "name",
        sortable: true,
      },
      {
        text: "Exercises",
        align: "center",
        value: "exercises",
        sortable: true,
      },
      {
        text: "Circuit Reps",
        align: "center",
        value: "circuitReps",
        sortable: true,
      }
    ],
    headersDevices:[
      { text: 'Last Used', align:'center', value:'lastUsed', sortable:false },
      { text: 'App version', align:'center', value:'appVersion', sortable:true },
      { text: 'Device Name', align:'center', value:'name', sortable:true },
      { text: 'OS', align:'center', value:'operatingSystem', sortable:true },
      { text: 'OS version', align:'center', value:'osVersion', sortable:true },
      { text: 'Manufacturer', align:'center', value:'manufacturer', sortable:true },
      { text: 'Model', align:'center', value:'model', sortable:true },
    ],
    headersMaxProDevices:[
      { text: 'MAC', align:'center', value:'maxproMac', sortable:false },
      { text: 'Left Used', align:'center', value:'counterL', sortable:true },
      { text: 'Right Used', align:'center', value:'counterR', sortable:true },
    ],
    headersAchievementsEarned:[
      { text: '', align:'center', value:'image', sortable:false },
      { text: 'Achievement', align:'center', value:'ref', sortable:true },
      { text: 'Unlock Date', align:'center', value:'createdAt', sortable:true },
    ],
    headersSubscriptions:[
      { text: 'Name', align:'center', value:'typeSubscription.name.ENG', sortable:true },
      { text: 'Duration', align:'center', value:'duration.quantity', sortable:true },
      { text: 'Start Date', align:'center', value:'duration.startDate', sortable:true },
      { text: 'End Date', align:'center', value:'duration.endDate', sortable:true },
      { text: 'Pay Method', align:'center', value:'billing.payMethod', sortable:true },
      { text: 'Status Pay', align:'center', value:'billing.statusPay', sortable:true },
      { text: 'Active', align:'center', value:'isActive', sortable:true },
    ],
    currienciesList:[
      {
        name:"USD",
        value:"USD"
      },
    ],
    payMethodsList:[
      {
        name:"FREE",
        value:"FREE"
      },
      {
        name:"APPLE-PAY",
        value:"APPLE-PAY"
      },
      {
        name:"CREDIT-CARD",
        value:"CREDIT-CARD"
      },
      {
        name:"COUPON",
        value:"COUPON"
      },
      {
        name:"IN-APP-PURCHASE",
        value:"IN-APP-PURCHASE"
      },
    ],
    statusPaysList:[
      {
        name:"PENDING",
        value:"PENDING"
      },
      {
        name:"REJECTED",
        value:"REJECTED"
      },
      {
        name:"SUCCESS",
        value:"SUCCESS"
      },
    ],
    loading: false,
    //
  }),
  async created() {
    const vm = this;
    try {
      vm.$emit("showOverlay", true);
      await vm.getUser()
      await vm.getUserMaxProDevices()
      await vm.getThisUserSubscriptions()
      await vm.getUserAchievements()
      vm.$emit("showOverlay", false);
    } catch (error) {
      console.log(error)
      vm.$emit("showOverlay", false);
    }
  },
  methods:{
    async getUser(){
        let vm = this
        try {
          const user = await vm.$store.dispatch("GetUserWithSubCollections", vm.$route.params.uid)
          vm.user = user
          vm.$set(vm.user, 'isCoach', user.isCoach ? true : false)
          vm.user.adminLevel = vm.user.adminLevel ? parseInt(vm.user.adminLevel, 10) : "";
        } catch (error) {
          console.log(error)
        }
    },
    async getUserMaxProDevices(){
      const vm = this 
      try {
        const maxProDevices = await vm.$store.dispatch("GetUserMaxProDevices", vm.$route.params.uid)
          vm.maxProDevices = maxProDevices
      } catch (error) {
        console.log(error);
      }

    },
    async getThisUserSubscriptions(){
      const vm = this 
       try {
          const subscriptionsGetted = await vm.$store.dispatch("GetAllSubscriptionsUser", vm.$route.params.uid)
          //itarin@sahuarolabs.com
          console.log('subscriptionsGetted', subscriptionsGetted);
          vm.subscriptionsGetted = subscriptionsGetted
      } catch (error) {
          console.log(error);
      }
    },
    async getUserAchievements(){
      const vm = this 
       try {
          const achievementsGetted = await vm.$store.dispatch("GetUserAchievements", vm.$route.params.uid)
          vm.achievementsGetted = achievementsGetted
      } catch (error) {
          console.log(error);
      }
    },
    isSuccessReAuth(dialogReAuthResponse){
      const vm = this 
        switch (dialogReAuthResponse.action) {
          case 'goActionMail':
            console.log('dialogReAuthResponse', dialogReAuthResponse);
            vm.goActionMail(dialogReAuthResponse.goActionProps.type, dialogReAuthResponse.goActionProps.email, dialogReAuthResponse.goActionProps.item)
            break;
          case 'updateRoleUser':
            vm.updateRoleUser(dialogReAuthResponse.item)
            break;
          case 'addSubscriptionUser':
            vm.addSubscriptionUser()
            break;
          default:
            break;
        }
    },
    openDialogReAuth(action, item, goActionProps){
      let vm = this;
      console.log('goActionProps', goActionProps);
      vm.dialogReAuth.show = true
      vm.dialogReAuth.action = action
      vm.dialogReAuth.item = item
      vm.dialogReAuth.goActionProps = goActionProps
      vm.closeModal();
    },
    openModal(type, email, item) {
      let vm = this;
      vm.dialog.show = true;
      vm.dialog.type = type;
      vm.dialog.email = email;
      vm.dialog.item = item;
    },
    closeModal() {
      let vm = this;
      vm.dialog.show = false;
      vm.dialog.type = "";
      vm.dialog.email = "";
      vm.dialog.item = "";

      vm.dialogRole.show = false
      vm.dialogRole.item = ''
    },
    goActionMail(type, email, item) {
      let vm = this;
      vm.$emit("showOverlay", true);
      if (type == "validate") {
        let verificationData = {
          email: email,
          lang: item.lang,
          displayName: `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`
        }
        vm.closeModal();
        vm.$store
          .dispatch("SendVerificationEmail", verificationData)
          .then((res) => {
            vm.$store.dispatch("GenerateLog", { user:verificationData.email, type:'validationEmail' })
            vm.snackbar.text = "Mail sended!";
            vm.snackbar.color = "green";
            vm.snackbar.snackbar = true;
            vm.$emit("showOverlay", false);
            
          })
          .catch((err) => {
            vm.snackbar.text = "Error sending mail!";
            vm.snackbar.color = "red";
            console.error(err);
            vm.$emit("showOverlay", false);
            vm.snackbar.snackbar = true;
            
          });
      } else if (type == "restore") {
        let resetPasswordData = {
          email: email,
          lang: item.lang,
          displayName: `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`
        }
        vm.closeModal();
        vm.$store
          .dispatch("SendPasswordResetEmail", resetPasswordData)
          .then((res) => {
            vm.$store.dispatch("GenerateLog", { user:resetPasswordData.email, type:'restorePassword' })
            vm.snackbar.text = "Mail sended!";
            vm.snackbar.color = "green";
            vm.snackbar.snackbar = true;
 
            vm.$emit("showOverlay", false);
          })
          .catch((err) => {
            vm.snackbar.text = "Error sending mail!";
            vm.snackbar.color = "red";
            vm.snackbar.snackbar = true;
            vm.$emit("showOverlay", false);

          });
      } else if (type === "disable") {
        // let index = vm.users.indexOf(item);
        vm.disableUser(item);
      }
    },
    openDialogManageAllowedViews(){
      let vm = this
      if(vm.user.adminLevel != '' && vm.user.allowedRoutes){
        vm.allowedRoutes = vm.user.allowedRoutes
      }else{
        vm.user.allowedRoutes = []
      }
      vm.dialogManageAllowedViews.show = true
    },
    closeDialogManageAllowedViews(){
      let vm = this
      vm.dialogManageAllowedViews.show = false
    },
    updateViewsAllowed(){
      let vm = this;
      vm.closeDialogManageAllowedViews()
      vm.$emit("showOverlay", true);
      vm.$store
          .dispatch("UpdateViewsAllowed", {
            uid: vm.user.uid,
            allowedRoutes: vm.allowedRoutes,
          })
          .then((res) => {
            vm.user.allowedRoutes = res
            vm.snackbar.text = `Allowed routes updated successfully`;
            vm.snackbar.color = "success"
            vm.$emit("showOverlay", false);
            vm.snackbar.snackbar = true;
          })
          .catch((err) => {
            vm.snackbar.text = `Error disabling user`;
            vm.snackbar.color = "red darken-2"
            vm.$emit("showOverlay", false);
            vm.snackbar.snackbar = true;
            
          })
    },
    async openDialogAddSubscription(){
      const vm = this
      await vm.getAllSubscriptions()
      vm.dialogAddSubscription.show = true
    },
    closeDialogAddSubscription(){
      const vm = this
      vm.dialogAddSubscription = {
        show: false,
        loading:false,
        subscription:{
          billing:{
            currency:"USD",
            offer:{
              inOffer:false,
              percentage:0,
              priceInOffer:0
            },
            payMethod: "ADDED-MANUALLY",
            price:{
              USD:0
            },
            statusPay:"SUCCESS"
          },
          duration:{},
          isCoupon:true
        },
        selectedSubscription:null
      }
    },
    async getAllSubscriptions(){
     const vm = this 
      try {
          const subscriptions = await vm.$store.dispatch("GetSubscriptionsList");
          console.log('subs from db: ',subscriptions);
          vm.availableSubscriptions = subscriptions
      } catch (error) {
          console.log(error);
      }
    },
    setFinishDaySubscription(quantity, interval){
      const changedDate = moment().add(quantity, interval)
      return changedDate.toDate()
    },
     async addSubscriptionUser(){
         const vm = this;
          try {
            vm.dialogAddSubscription.loading = true
            let dialog = vm.dialogAddSubscription
            let payload = {
               billing:{
                currency: dialog.subscription.billing.currency,
                offer: dialog.selectedSubscription.offer,
                payMethod: dialog.subscription.billing.payMethod,
                price: dialog.selectedSubscription.price,
                statusPay: dialog.subscription.billing.statusPay
              },
              duration:{
                startDate: new Date(),
                endDate: vm.setFinishDaySubscription(dialog.selectedSubscription.duration.quantity, dialog.selectedSubscription.duration.interval),
                quantity: dialog.selectedSubscription.duration.quantity,
                interval: dialog.selectedSubscription.duration.interval
              },
              isCoupon:true,
              typeSubscription: dialog.selectedSubscription.id,
              user: vm.user.uid
            }

            const id = await vm.$store.dispatch('AddSubscriptionToUser', payload)
            await vm.$store.dispatch("GenerateLog", {
                id: id,
                name: dialog.selectedSubscription.name['ENG'],
                user: vm.user.uid,
                type: "addedManuallySubscriptionToUser",
            })
            vm.user.activeSubscription = { ref: firebase.firestore().collection('subscriptionsGettedByUsers').doc(id), isPremium: dialog.selectedSubscription.id !== 'Yn8R3ywqutpbFmyPoAbt' }
            await vm.getThisUserSubscriptions();
            vm.dialogAddSubscription.loading = false
            vm.closeDialogAddSubscription()
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Subscription added to user successfully",
                color: "success",
            };
            vm.$emit("showSnackBar", emitData);

          } catch (error) {
            vm.dialogAddSubscription.loading = false
            console.log(error);
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Error adding Subscription to user",
                color: "red",
            };
            vm.$emit("showSnackBar", emitData);
          }
    },
    goBack(){
      let vm = this;
      vm.$store.dispatch("ResetValues")
      vm.$router.go(-1)
    },
    getRoutesNames(){
      const routesNames = Paths.map(route => route.name)
      return routesNames
    },
    disableUser(user) {
      let vm = this;
      vm.closeModal();

      if (!user.adminLevel) {
        vm.$store
          .dispatch("DisableUser", {
            uid: user.uid,
            valueDisabled: !user.disabled,
          })
          .then((res) => {
            vm.user.disabled = res.disabled
         
            vm.$store.dispatch("GenerateLog", { user:vm.user.email, type:vm.user.disabled ? 'disabled' : 'enabled' })

            vm.snackbar.text = `User ${
              res.disabled ? "disabled" : "enabled"
            } successfully`;
            vm.snackbar.color = "green";
            vm.$emit("showOverlay", false);
            vm.snackbar.snackbar = true;
          })
          .catch((err) => {
            console.error(err)
            vm.snackbar.text = `Error disabling user`;
            vm.snackbar.color = "red darken-2"
            vm.$emit("showOverlay", false);
            vm.snackbar.snackbar = true;
          });
      } else {
        vm.$emit("showOverlay", false);
        vm.snackbar.text = `You can't disable the admin`;
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      }
    },
     getParsedDate(date){
      if(date){
        return moment(date).format("MM/DD/YYYY")
      }
    },
     getFullDate(date){
      if(date){
        return moment(date).format('MMMM Do YYYY, h:mm:ss a')
      }else{
        return moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')
      }
    },
    timestampToStringDate(timestamp){
      const dateParsed = new Date(timestamp.seconds*1000)
      return moment(dateParsed).format('MMMM Do YYYY, h:mm:ss a')
    },
    changeRouteSendNotification(){
      let vm = this
      vm.$router.push({ name: 'Push Notification Send', params: vm.user })
      .catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      })
    },
    changeRange(value){
      let vm = this
      vm.dialogRole.show = true
      vm.dialogRole.item = value
      // vm.openModal('role', vm.user.email, value)
    },
    closeModalRole(){
      let vm = this
      // vm.user.adminLevel = vm.dialogRole.beforeValue
      vm.$refs.selectRole.lazyValue = vm.user.adminLevel
      vm.$refs.selectRole.value = vm.user.adminLevel
      vm.dialogRole.show = false
      vm.dialogRole.item = ''

    },
    updateRoleUser(changedValue){
      let vm = this
       vm.$store
          .dispatch("ChangeRoleUser", {
            uid: vm.user.uid,
            adminLevel: changedValue,
            allowedRoutes: vm.user.allowedRoutes
          }).then((res) => {
            console.log(res);
            let role = vm.levelProps.find(r => r.value === changedValue)
     
            vm.$store.dispatch("GenerateLog", { user:vm.user.email, type:'updateUserRole', role:role.level })
            vm.user.adminLevel = changedValue
            vm.snackbar.text = "User level changed successfuly!";
            vm.snackbar.color = "green";
            vm.snackbar.snackbar = true;

          }).catch((err)=>{
            vm.snackbar.text = "Error changing level";
            vm.snackbar.color = "red";
            vm.snackbar.snackbar = true;
            console.log(err);
            vm.$refs.selectRole.lazyValue = vm.user.adminLevel
            vm.$refs.selectRole.value = vm.user.adminLevel
          })

    },
    getTimeWorkouts(workOuts){
      if(workOuts){
        let totalTime = 0
        for(let i = 0; i < workOuts.length ; i++){
          totalTime = totalTime + (workOuts[i].timeWorkout.minutes * 60)
            totalTime = totalTime + workOuts[i].timeWorkout.seconds
        }
        let parserTime = moment.duration(totalTime, 'seconds')
        if (totalTime < 60) {
          return `${parserTime.get('seconds')} seconds`
        } else if(totalTime >= 60 && totalTime < 3600) {
          return `${parserTime.get('minutes')} minutes ${parserTime.get('seconds')} seconds`
        } else if (totalTime >= 3600) {
          return `${parserTime.get('hours')} hours ${parserTime.get('minutes')} minutes ${parserTime.get('seconds')} seconds`
        }
      }
    },
    getCalsWorkouts(workOuts){
      let totalCals = 0
      for(let i = 0; i < workOuts.length ; i++){
        totalCals = totalCals + workOuts[i].cals
      }
      return totalCals.toFixed(2)
    },
    getExercisesMadedWorkouts(workOuts){
      let totalExercises = 0
      for(let i = 0; i < workOuts.length ; i++){
        totalExercises = totalExercises + workOuts[i].exercisesMaded
      }
      return totalExercises
    },
    rangeDates(workOuts){
      const dates = []
      for(let i = 0; i < workOuts.length ; i++){
        dates.push(workOuts[i].createdAt._seconds*1000)
      }
      const minDate = moment(new Date(Math.min(...dates))).format("DD/MM/YYYY");
      const maxDate = moment(new Date(Math.max(...dates))).format("DD/MM/YYYY");

      return { minDate, maxDate }
    },
    getTimeWorkoutsProTrainers(){
      let vm = this
      let workOuts = vm.user.savesUser.proTrainingSaves
      if(workOuts){
        let totalTime = 0
        for(let i = 0; i < workOuts.length ; i++){
          totalTime = totalTime + moment.duration(workOuts[i].totalTime).asSeconds() 
        }
        let parserTime = moment.duration(totalTime, 'seconds')
        if (totalTime < 60) {
          return `${parserTime.get('seconds')} seconds`
        } else if(totalTime >= 60 && totalTime < 3600) {
          return `${parserTime.get('minutes')} minutes ${parserTime.get('seconds')} seconds`
        } else if (totalTime >= 3600) {
          return `${parserTime.get('hours')} hours ${parserTime.get('minutes')} minutes ${parserTime.get('seconds')} seconds`
        }
      }
    },
    verifyPrivilege(){
      const vm  = this
      // return 'asda'
      return vm.user.activeSubscription && vm.user.activeSubscription.isPremium ? 'Premium' : vm.user.creationTime && vm.user.creationTime.toDate() <= new Date('2021-12-07') ? 'GrandFathered' : 'Free'
    },
    async changeCoachStatus(){
      const vm  = this
      try {
        vm.loadingCoach = true
        const id = await vm.$store.dispatch("ChangeStateCoach", { uid: vm.user.uid, isCoach: vm.user.isCoach })
        vm.$store.dispatch("GenerateLog", {id, email:vm.user.email, type:'updateCoachStatus', isCoach:vm.user.isCoach })
        vm.snackbar.text = "Coach status changed successfuly";
        vm.snackbar.color = "green";
        vm.snackbar.snackbar = true;
        vm.loadingCoach = false      
      } catch (error) {
        vm.loadingCoach = false
        vm.snackbar.text = "An error occurred when changing the coach's status";
        vm.snackbar.color = "red";
        vm.snackbar.snackbar = true;
        console.error(error)
      }
    }
  },
  computed:{
     ...mapGetters(["getAdmin"])
  },
};
</script>

<style lang="scss">
.avatar-profile-image {
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center center;
  object-fit: contain;
}
.v-data-table ::v-deep .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table ::v-deep .v-data-table__wrapper {
  overflow: unset;
}

</style>