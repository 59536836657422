import Vue from 'vue';
import VueRouter from 'vue-router';
import Paths from '@/router/paths/index';
import BgMaxPro from "@/assets/Bg.png";
import store from "@/store"

Vue.use(VueRouter);

const route = (path, meta, name, component, children) => {
  return {
    path: path,
    name: name,
    component: component,
    meta: meta,
    children: children
  }
}


const router = new VueRouter({
  routes: Paths.map(path => route(path.path, path.meta, path.name, path.component, path.children))
    .concat([
      { path: '*', redirect: '/' }
    ]),
  mode: 'history'
});

router.afterEach(to => {
  if (to.meta && to.meta.forCustomerService || to.meta && to.meta.forAdmin) {
    document.body.style.background = `#efefef`;
  } else {
    document.body.style.background = `url(${BgMaxPro})`;
  }
});

router.beforeEach((to, from, next) => {
  // ...
  next();
})


export default router;