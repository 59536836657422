import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { v4 as uuidv4 } from "uuid";
import { requestEndPoint } from '@/store/Api'

Vue.use(VueAxios, Axios);

const db = firebase.firestore();

const storage = firebase.storage().ref("pushNotificationImages/");

export default {
  state: {
    pushNotifications: [],
    unsubscribePushNotifications: null,
    pushNotificationsByGroupList: [],

  },
  mutations: {
    SET_PUSH_NOTIFICATIONS(state, pushNotifications) {
      state.pushNotifications = pushNotifications;
    },
    SET_UNSUBSCRIBE_PUSH_NOTIFICATIONS(state, unsubscribe) {
      state.unsubscribePushNotifications = unsubscribe;
    },
    GO_UNSUBSCRIBE_PUSH_NOTIFICATIONS(state) {
      state.unsubscribePushNotifications();
    },
    SET_PUSH_NOTIFICATIONS_BY_GROUP(state, pushNotificationsByGroupList) {
      state.pushNotificationsByGroupList = pushNotificationsByGroupList;
    },
  },
  actions: {
    GetNotifications({ commit }) {
      return new Promise(async (resolve, reject) => {
        db.collection("pushNotificationsList").get()
          .then((querySnapshot) => {
            let notificationsList = [];
            querySnapshot.forEach((doc) => {
              let notification = doc.data();
              notificationsList.push(notification);
            });
            commit("SET_PUSH_NOTIFICATIONS", notificationsList);
            resolve(notificationsList);;
          }).catch((err) => {
            reject(err)
          })
      });
    },
    GetPushNotificationsPaginatedFirstPage({ commit, getters }, itemsPerPage) {
      return new Promise(async (resolve, reject) => {

        if (getters.getPushNotifications.length > 0) {
          commit("GO_UNSUBSCRIBE_PUSH_NOTIFICATIONS");
        }
        let unsubscribe = db.collection("pushNotificationsList").orderBy('title').limit(itemsPerPage).onSnapshot((querySnapshot) => {
          let notificationsList = [];
          querySnapshot.forEach((doc) => {
            let notification = doc.data()
            notificationsList.push(notification);
          });
          commit("SET_PUSH_NOTIFICATIONS", notificationsList);
          resolve(notificationsList);
        },
          (error) => {
            reject(error);
          }
        );
        commit("SET_UNSUBSCRIBE_PUSH_NOTIFICATIONS", unsubscribe);
      });
    },
    GetPushNotificationsNextPage({ commit, getters }, itemsPerPage) {
      return new Promise(async (resolve, reject) => {
        console.log('getters.getPushNotifications', getters.getPushNotifications);
        commit("GO_UNSUBSCRIBE_PUSH_NOTIFICATIONS");
        let unsubscribe = db.collection("pushNotificationsList").orderBy('title').startAfter(getters.getPushNotifications[getters.getPushNotifications.length - 1].title).limit(itemsPerPage).onSnapshot((querySnapshot) => {
          let notificationsList = [];
          querySnapshot.forEach((doc) => {
            let notification = doc.data()
            notificationsList.push(notification);
          });
          console.log(querySnapshot);
          if (notificationsList.length > 0) {
            commit("SET_PUSH_NOTIFICATIONS", notificationsList);
          }
          resolve(notificationsList);
        },
          (error) => {
            reject(error);
          }
        );
        commit("SET_UNSUBSCRIBE_PUSH_NOTIFICATIONS", unsubscribe);
      });
    },
    GetPushNotificationsPrevPage({ commit, getters }, itemsPerPage) {
      return new Promise(async (resolve, reject) => {
        commit("GO_UNSUBSCRIBE_PUSH_NOTIFICATIONS");
        let unsubscribe = db.collection("pushNotificationsList").orderBy('title').endBefore(getters.getPushNotifications[0].title).limitToLast(itemsPerPage).onSnapshot((querySnapshot) => {
          let notificationsList = [];
          querySnapshot.forEach((doc) => {
            let notification = doc.data()
            notificationsList.push(notification);
          });
          commit("SET_PUSH_NOTIFICATIONS", notificationsList);
          resolve(notificationsList);
        },
          (error) => {
            reject(error);
          }
        );
        commit("SET_UNSUBSCRIBE_PUSH_NOTIFICATIONS", unsubscribe);
      });
    },
    GoUnsuscribeGetPushNotifications({ commit }) {
      commit("GO_UNSUBSCRIBE_PUSH_NOTIFICATIONS");
    },
    UploadImage({ }, imageBase) {
      return new Promise((resolve, reject) => {
        let random = uuidv4();
        let storageRef = storage.child(random);
        storageRef
          .putString(imageBase, "data_url")
          .then((snapshot) => {
            snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => {
                resolve(downloadURL);
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    CreateNotification({ }, notification) {
      return new Promise(async (resolve, reject) => {
        const ref = db.collection("pushNotificationsList").doc();
        const newPushDocId = await ref.get();
        notification.id = ref.id;
        notification.createdAt = new Date()
        notification.updatedAt = new Date()
        await db
          .collection("pushNotificationsList")
          .doc(newPushDocId.id)
          .set(notification)
          .then((res) => {
            resolve(newPushDocId.id);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    UpdateNotification({ }, notification) {
      return new Promise(async (resolve, reject) => {
        if (notification.createdAt) {
          delete notification.createdAt
        }
        notification.updatedAt = new Date()
        await db.collection("pushNotificationsList")
          .doc(notification.id)
          .update(notification)
          .then((res) => {
            resolve(notification.id);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DeleteNotification({ }, notification) {
      return new Promise(async (resolve, reject) => {
        await db.collection("pushNotificationsList")
          .doc(notification.id)
          .delete()
          .then((res) => {
            resolve(notification.id);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SendNotification({ }, notification) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(requestEndPoint.sendMassiveNotification, notification)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      })
    },
    SendUserNotification({ }, notification) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(requestEndPoint.sendUserNotification, notification)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      })

    },
    GetPushNotificationsByGroup({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const querySnapshot = await db.collection("pushNotificationsByGroupList").get()
          const notificationsPushByGroupList = []
          querySnapshot.forEach(async (doc) => {
            let notificationPushByGroup = doc.data()
            notificationsPushByGroupList.push(notificationPushByGroup)
          })
          commit('SET_PUSH_NOTIFICATIONS_BY_GROUP', notificationsPushByGroupList)
          resolve(notificationsPushByGroupList)
        } catch (error) {
          reject(error)
        }
      })
    },
    SendUserNotificationByGroup({ }, notification) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post(requestEndPoint.sendNotificationToTopic, notification, { headers: null })
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }


        // Vue.axios
        //   .post(requestEndPoint.sendNotificationToTopic, testVar)
        //   .then((response) => {
        //     console.log('responsee', response);
        //     resolve(response.data);
        //   })
        //   .catch((error) => {
        //     reject(error);
        //   });
      })

    },
  },
  getters: {
    getPushNotifications(state) {
      return state.pushNotifications;
    },
    getPushNotificationsByGroupList(state) {
      return state.pushNotificationsByGroupList;
    },
  },
};
