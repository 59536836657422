<template>
  <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff"
  >
    <v-form ref="formAchievement" v-model="validAchievement">
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-btn
            color="primary"
            icon
            size="20"
            class="text-center"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h3 class="secondary--text d-inline mr-2">Create Achievement</h3>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto text-left">
            <p class="secondary--text mt-5 text-left d-inline">Icon Preview</p>
            <v-card
            color="#efefef"
            class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
            min-width="250"
            min-height="250"
            @click="$refs.achievementIcon.$refs.input.click()"
            >
            <v-img :src="achievement.icon.img ? achievement.icon.img : ''" cover width="250" class="rounded-lg" height="250">
            </v-img>
            
                <v-icon large color="primary" style="position: absolute; transform: translate(50%,-50%); top: 50%; right: 50%;"> mdi-camera </v-icon>

            </v-card>
            <p class="secondary--text mt-3 pb-1 text-center">Enabled</p>
            <v-switch
            color="primary"
            class="d-flex justify-center center-switch"
            v-model="achievement.enabled"
            :input-value="achievement.enabled"
            flat
            ></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto">
        <p class="secondary--text pb-1">Name</p>
        <v-text-field
        v-model="achievement.name"
        :rules="achievementRules.name"
        solo
        flat
        background-color="#efefef"
        ></v-text-field>
        <p class="secondary--text pb-1">Description</p>
        <v-textarea v-model="achievement.description" :rules="achievementRules.description" maxlength="250" counter="250" solo flat no-resize height="355" background-color="#efefef">
        </v-textarea>
        <div class="mx-auto text-center" style="width:150px">
        <p class="secondary--text text-center pb-1">Order number</p>
        <v-text-field
            v-model.number="achievement.orderNumber"
            :rules="achievementRules.orderNumber"
            @keypress="numberValid($event)"
            maxlength="4"
            class="centered-input"
            solo
            flat
            background-color="#efefef"
        ></v-text-field>
        </div>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto">
            <p class="secondary--text pb-1">Type</p>
            <v-select
              v-model="achievement.goalsReferences.type"
              :items="getTypesAchievementsList"
              item-text="name.ENG"
              item-value="id"
              return-object
              label="Type of achievement"
              solo
              flat
              background-color="#efefef"
              @change="resetValues()"
            ></v-select>
            <template v-if="achievement.goalsReferences.type.id === 'ji3rwS40sLtZiWsUKtnp'">
              <p class="secondary--text pb-1">Challenge</p>
               <v-select
                v-model="achievement.goalsReferences.specificWorkout.ref"
                :items="getProgramsList"
                item-text="name"
                item-value="id"
                return-object
                label="Select Challenge"
                solo
                flat
                background-color="#efefef"
                @change="changeValuesSpecificWorkout('programsList')"
              ></v-select>
            </template>
            <template v-if="achievement.goalsReferences.type.id === 'of3si2pJ4xRip0ASOdqm'">
              <p class="secondary--text pb-1">Pre-Set</p>
               <v-select
                v-model="achievement.goalsReferences.specificWorkout.ref"
                :items="getPreSets"
                item-text="name.ENG"
                item-value="id"
                return-object
                label="Select Pre-Set"
                solo
                flat
                background-color="#efefef"
                @change="changeValuesSpecificWorkout('preSetsList')"
              ></v-select>
            </template>
            <template v-if="achievement.goalsReferences.type.id === 'Is4YeT0dJQ52o46oN0B4'">
              <p class="secondary--text pb-1">Coached Class</p>
               <v-select
                v-model="achievement.goalsReferences.specificWorkout.ref"
                :items="getProTrainersList"
                item-text="name"
                item-value="id"
                return-object
                label="Select Coached Class"
                solo
                flat
                background-color="#efefef"
                @change="changeValuesSpecificWorkout('proTrainersList')"
              ></v-select>
            </template>
            <!-- <template v-if="achievement.type === 'inARow'"> -->
              <p class="secondary--text pb-1">Category</p>
              <v-select
                v-model="achievement.goalsReferences.category"
                :items="getCategoriesAchievementsList"
                item-text="name.ENG"
                item-value="id"
                return-object
                label="Category of achievement"
                solo
                flat
                background-color="#efefef"
              ></v-select>
              <div class="d-flex">
                <div class="mr-2">
                  <p class="secondary--text pb-1">Quantity</p>
                 <v-text-field
                    v-model.number="achievement.goalsReferences.quantity"
                    @keypress="numberValid($event)"
                    maxlength="5"
                    class="centered-input"
                    solo
                    flat
                    background-color="#efefef"
                ></v-text-field>
                </div>
                <div class="">
                  <p class="secondary--text pb-1">Interval</p>
                  <v-select
                    v-model="achievement.goalsReferences.interval"
                    :items="getIntervalsAchievementsList"
                    item-text="name.ENG"
                    item-value="id"
                    return-object
                    solo
                    flat
                    background-color="#efefef"
                  ></v-select>
                </div>
              </div>
            <!-- </template>
            <template v-if="achievement.type === 'personalRecordAcumulation'"> -->
              <!-- <p class="secondary--text pb-1">Category</p>
              <v-select
                v-model="achievement.category"
                :items="categoriesOfAchievements"
                item-text="name"
                item-value="value"
                label="Category of achievement"
                solo
                flat
                background-color="#efefef"
              ></v-select> -->
               <!-- <div class="d-flex">
                <div class="mr-2">
                  <p class="secondary--text pb-1">Quantity</p>
                 <v-text-field
                    v-model.number="achievement.paramsToComplete.quantityOfAcumulation"
                    @keypress="numberValid($event)"
                    maxlength="4"
                    class="centered-input"
                    solo
                    flat
                    background-color="#efefef"
                ></v-text-field>
                </div>
                <div class="">
                  <p class="secondary--text pb-1">Personal Record</p>
                  <v-select
                    v-model="achievement.paramsToComplete.typePersonalRecordAcumulation"
                    :items="typeOfPersonalRecordAcumulation"
                    item-text="name"
                    item-value="value"
                    solo
                    flat
                    background-color="#efefef"
                  ></v-select>
                </div>
              </div> -->
            <!-- </template> -->
            <!-- <template v-if="achievement.type === 'socialSharing'"> -->
              <!-- <div class="mx-auto text-center" style="width:150px">
                <p class="secondary--text text-center pb-1">Quantity Shared</p>
                  <v-text-field
                      v-model.number="achievement.paramsToComplete.quantitySocialShared"
                      @keypress="numberValid($event)"
                      maxlength="4"
                      class="centered-input"
                      solo
                      flat
                      background-color="#efefef"
                  ></v-text-field>
              </div> -->
            <!-- </template> -->
        </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" class="float-right" :disabled="!achievement.icon.img || !validAchievement" @click="uploadAchievementIcon()">Finish</v-btn>
         </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="dialogUploadImage"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading icon, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingStatusAchievementIcon"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>

     <v-file-input
      ref="achievementIcon"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg"
      dark
      outlined
      :show-size="1000"
      @change="getAchievementIcon"
    />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
export default {
  name:"AchievementsCreate",
  components: { modalReauth },
  data: () => ({
      validAchievement:false,
      achievement:{
          name:'',
          description:'',
          enabled:true,
          icon:{
              img:null,
              link:'',
              ref:'',
              size:'',
              type:''
          },
          orderNumber:'',
          type:'',
          category:'',
          paramsToComplete:{},
          goalsReferences:{
            type:"",
            category:"",
            interval:"",
            quantity:"",
            specificWorkout:{
              isReferenced:false,
              collection: '',
              ref: ''
            }
          }
      },
      achievementRules:{
        name: [v => !!v || 'Name is required',  v => (v && v.length >= 4) || 'Name must be greater than 4'],
        description: [(v) => !!v || "Description section is required"],
     },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
      dialogUploadImage:false,
      typesOfAchievements:[
        {
          name:"In a Row",
          value:"inARow"
        },
        {
          name:"Personal Record Acumulation",
          value:"personalRecordAcumulation"
        },
        {
          name:"Social Sharing",
          value:"socialSharing"
        },
      ],
      categoriesOfAchievements: [
        {
          name:"Coaches Classes",
          value:"coachesClasses",
        },
        {
          name:"Excercises",
          value:"excercises",
        },
        {
          name:"buildYourOwn",
          value:"excercises",
        },
        {
          name:"Professional Workout",
          value:"PWO",
        },
        {
          name:"Login",
          value:"login",
        },
        {
          name:"Other",
          value:"other",
        },
      ],
      intervalAchievements: [
        {
          name:"Minutes",
          value:"minutes",
        },
        {
          name:"Hours",
          value:"hours",
        },
        {
          name:"Days",
          value:"days",
        },
        {
          name:"Weeks",
          value:"weeks",
        },
        {
          name:"Months",
          value:"months",
        },
        {
          name:"Years",
          value:"Years",
        },
      ],
      typeOfPersonalRecordAcumulation:[
        {
          name:"Acumulation",
          value:"acumulation",
        },
        {
          name:"Workout Completed",
          value:"workoutCompleted",
        },
      ]
  }),
  created(){
    const vm = this
    vm.getAllGoalsReferences();
  },
  methods:{
    async getAllGoalsReferences(){
      const vm = this
      try {
        vm.$emit("showOverlay", true);
        await vm.getTypesAchievements()
        await vm.getCategoriesAchievements()
        await vm.getIntervalsAchievements()
        // Workouts
        await vm.getAllPrograms()
        await vm.getAllPreSets()
        await vm.getAllCoachedClasses()
        vm.$emit("showOverlay", false);
      } catch (error) {
        console.log(error)
        vm.$emit("showOverlay", false);
      }
    },
    async getTypesAchievements(){
       const vm = this
        try {
            await vm.$store.dispatch("GetTypesAchievementsList")
        } catch (error) {
            console.log(error);
        }
    },
    async getCategoriesAchievements(){
       const vm = this
        try {
            await vm.$store.dispatch("GetCategoriesAchievementsList")
        } catch (error) {
            console.log(error);
        }
    },
    async getIntervalsAchievements(){
       const vm = this
        try {
            await vm.$store.dispatch("GetIntervalsAchievementsList")
        } catch (error) {
            console.log(error);
        }
    },
    async getAllPrograms(){
       const vm = this
        try {
            await vm.$store.dispatch("GetProgramsList")
        } catch (error) {
            console.log(error);
        }
    },
    async getAllPreSets(){
       const vm = this
        try {
            await vm.$store.dispatch("GetPreSets")
        } catch (error) {
            console.log(error);
        }
    },
    async getAllCoachedClasses(){
       const vm = this
        try {
            await vm.$store.dispatch("GetCoachedClasses")
        } catch (error) {
            console.log(error);
        }
    },
    resetValues(){
        const vm = this
        vm.$set(vm.achievement, 'paramsToComplete', {})
        vm.$set(vm.achievement, 'category', '')
        vm.$set(vm.achievement.goalsReferences, 'specificWorkout', {
              isReferenced:false,
              collection: '',
              ref: ''
        })
    },
    changeValuesSpecificWorkout(collection){
      const vm = this
       vm.$set(vm.achievement.goalsReferences.specificWorkout, 'isReferenced', true)
       vm.$set(vm.achievement.goalsReferences.specificWorkout, 'collection', collection)
    },
    numberValid(e){
        e = (e) || window.event
        const charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault()
        } else {
            return true
        }
    },
    getFileSize(bytes, dp = 1) {
      const thresh = 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
        //si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']; // original
      const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return bytes.toFixed(dp) + ' ' + units[u];
    },
    getAchievementIcon(file){
        let vm = this
        if(file){
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          const fr = new FileReader();
          fr.onload = (el) => {
            vm.achievement.icon = {
              img: el.target.result,
              file: file.type.split("/")[1],
              size:vm.getFileSize(file.size, 2),
              link:""
            }
          };
          fr.readAsDataURL(file);
        }else{
           let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .png and .jpeg files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }
      }
    },
    uploadAchievementIcon(){
      let vm = this;
      vm.dialogUploadImage = true
       vm.$store
        .dispatch("UploadImageAchievement", vm.achievement)
        .then((storage) => {
          vm.achievement.icon.link = storage.link
          vm.achievement.icon.ref = storage.ref
          vm.dialogUploadImage = false
          vm.createAchievement();
          
        }).catch((err) => {
            console.log(err);
          vm.dialogUploadImage = false

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error uploading icon",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        })
    },
    createAchievement(){
        let vm = this;
        vm.$store.dispatch('CreateAchievement', vm.achievement)
            .then((id) => {
            vm.$store.dispatch("GenerateLog", {
                id: id,
                name: vm.achievement.name,
                type: "createdAchievement",
            })
            vm.dialogUploadVideo = false

            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Achievement Created successfully",
                color: "success",
            };
            vm.$router.push("/admin/achievements")
            .catch((err) => {
                vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                vm.snackbar.color = "red darken-2";
                vm.snackbar.snackbar = true;
            })
            vm.$emit("showSnackBar", emitData);
            
            }).catch((err)=>{
            vm.dialogUploadVideo = false

            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Error creating achievement",
                color: "red",
            };
            vm.$emit("showSnackBar", emitData);

            })
    }
  },
  computed:{
      ...mapGetters(["getLoadingStatusAchievementIcon", "getTypesAchievementsList", "getCategoriesAchievementsList", "getIntervalsAchievementsList", "getProgramsList", "getPreSets", "getProTrainersList"])
  }
};
</script>